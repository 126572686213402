const fi = Object.freeze({
  hello: 'Hei',
  home: 'Kotisivu',
  receiptPosting: 'Vastaanotto',
  products: 'Tuotteet',
  addProduct: 'Lisää tuote',
  addNewProduct: 'Lisää uusi tuote',
  auctions: 'Huutokaupat',
  welcome: 'Tervetuloa',
  reception: 'Vastaanotto',
  addReception: 'Aloita vastaanotto',
  seller: 'Asiakas',
  dateOfDropOff: 'Jätetty pvm',
  estimatedWorth: 'Arvioitu arvo',
  associatedWith: 'Liittyy',
  changelog: 'Muutosloki',
  save: 'Tallenna',
  goBack: 'Takaisin',
  UseOldProduct: 'Käytä olemassaolevan tuotteen tiedot',
  comment: 'Sisäinen kommentti',
  description: 'Kuvaus',
  english: 'Englanti',
  finnish: 'Suomi',
  swedish: 'Ruotsi',
  addToAuction: 'Lisää huutokauppaan',
  technicalInformation: 'Tarkemmat tiedot',
  material: 'Materiaali',
  fitnessRating: 'Kuntoluokka',
  reference: 'Referenssi',
  year: 'Vuosi',
  country: 'Maa',
  nominalValue: 'Nimellisarvo',
  weight: 'Paino',
  size: 'Koko',
  nextSteps: 'Jatkotoimenpiteet',
  nextStep: 'Jatkotoimenpide',
  andThen: 'Sijoita kohde',
  publish: 'Valmis',
  saveDraft: 'Tallenna keskeneräisenä',
  editSelectedReceptions: 'Muokkaa valittuja vastaanottoja',
  editReception: 'Muokkaa vastaanottoa',
  upload: 'Lisää',
  uploading: 'Lisätään',
  personnelAssigned: 'Vastuuhenkilö(t)',
  status: 'Tila',
  productStatus: 'Tuotteen tila',
  featuredImage: 'Pääkuva',
  required: 'Pakollinen tieto',
  adminNote: 'Kommentti',
  name: 'Nimi',
  condition: 'Kunto',
  conditionDescription: 'Kunnon tarkempi kuvaus',
  quantity: 'Määrä',
  ruler: 'Hallitsija',
  olympia: 'Olympia',
  designer: 'Suunnittelija',
  manufacturer: 'Valmistaja',
  taxableStatus: 'Verotusluokka',
  webShopPrice: 'Hinta verkkokaupassa',
  purchasedPrice: 'Ostohinta',
  AuctionMinimumPrice: 'Huutokaupan lähtöhinta',
  estimatedPrice: 'Arvioitu hinta',
  productCategory: 'Tuotekategoria',
  editNewProduct: 'Muokkaa tuotetta',
  editProducts: 'Muokkaa tuotteita',
  editSelectedProducts: 'Muokkaa valittuja tuotteita',
  filter: 'Suodata',
  inAuction: 'Huutokaupassa',
  inWebshop: 'Verkkokaupassa',
  notSold: 'Ei myyty',
  bulkEdit: 'Muokkaa useaa kohdetta',
  image: 'Kuva',
  date: 'Pvm',
  edit: 'Muokkaa',
  assignToWoocommerce: 'Verkkokauppaan',
  AssignToAuction: 'Huutokauppaan',
  PutIntoStorage: 'Varastoon',
  holmastoWillBuyIt: 'Holmasto ostaa',
  holmastoWillSellIt: 'Holmasto myy asiakkaan puolesta (huutokauppa)',
  returnToSeller: 'Palautetaan myyjälle',
  auction: 'Huutokauppa',
  addAuction: 'Luo uusi huutokauppa',
  auctionId: 'Huutokaupan numero',
  startDate: 'Alkupäivä',
  estimatedValue: 'Arvioitu arvo',
  categories: 'Kategoriat',
  uncategorizedProduct: 'Huutokauppakohteet ilman kategoriaa',
  login: 'Kirjaudu sisään',
  forgotPassword: 'Unohditko salasanan?',
  resetPassword: 'Resetoi salasana',
  notValidEmail: 'Tämä sähköpostiosoite on jo käytössä',
  backToLogin: 'Takaisin sisäänkirjaukseen',
  createdAt: 'Luotu',
  email: 'Sähköposti',
  users: 'Työntekijät',
  addUser: 'Lisää',
  firstName: 'Etunimi',
  lastName: 'Sukunimi',
  postalCode: 'Postinumero',
  admin: 'Admin',
  shippingContainer: 'Pakettikoko',
  small: 'Pieni (fi)',
  medium: 'Medium (fi)',
  large: 'Suuri (fi)',
  requiresSpecialHandling: 'Vaatii erikoiskäsittelyn',
  owner: 'Asiakas',
  proposedPrice: 'Ehdotettu sisäänostohinta',
  proposalText: 'Ehdotuksen vapaa teksti',
  city: 'Kaupunki',
  areYouSure: 'Oletko varma?',
  cancel: 'Keskeytä',
  delete: 'Poista',
  thisActionCanNotBeUndone: 'Tätä toimenpidettä ei voi peruuttaa',
  warehouseId: 'Varastopaikka',
  lengthShouldBe1: 'Pituus tulee olla 1 ',
  onlyCapitalAlphabeticChars: 'Vain isoja kirjaimia',
  addClient: 'Lisää Asiakas',
  active: 'Aktiivinen',
  inactive: 'Passiivinen',
  sentForApproval: 'Lähetä asiakkaalle hyväksyttäväksi',
  draft: 'Luonnos',
  rejected: 'Hylätty',
  accepted: 'Hyväksytty',
  pending: 'Odottaa',
  holmasto: 'Holmasto',
  customer: 'Asiakas',
  itemStatus: 'Kohteiden status',
  noImage: 'KUVA PUUTTUU',
  noInformation: 'TIETO PUUTTUU',
  createCategory: 'Luo kategoria',
  editCategory: 'Muokkaa kategoriaa',
  label: 'Tarra',
  categoryImage: 'Kategoriakuva',
  chooseImage: 'Valitse kuva',
  choose: 'Calitse',
  category: 'Kategoria',
  productsYoureEditing: 'Tuotteet jota editoit',
  changeFieldsToUpdate: 'Muuta kentät päivittääksesi',
  updateAll: 'Päivitä kaikki',
  auctionCategory: 'Huutokauppakategoriat',
  productCategories: 'Tuotekategoriat',
  sku: 'SKU',
  maxLengthIsThree: 'Max 3 merkkiä',
  editAuction: 'Muokkaa huutokauppaa',
  generatePdf: 'Tulosta tarra',
  markAsComplete: 'Merkkaa valmiiksi',
  experts: 'Asiantuntijat',
  completed: 'Valmis',
  incomplete: 'Kesken',
  canBeSentByAssignedPersona: 'Ehdotuksen voi lähettää vain vastuuhenkilö',
  sendProposal: 'Lähetä ehdotus asiakkaalle',
  publishForPrebid: 'Julkaise ennakkohuutoluettelo',
  editUser: 'Muokkaa työntekijä',
  from: 'Lähettäjä',
  to: 'Vastaanottaja',
  images: 'Kuvat',
  superAdmin: 'Super Admin',
  role: 'Rooli',
  client: 'Asiakas',
  disable: 'Poista luettelosta',
  enable: 'Ota käyttöön',
  parent: 'Kuuluu tämän alle',
  newPasswordWasSentToUser: 'Uusi salasana on lähetetty työntekijälle',
  changePassword: 'Muuta salasanasi',
  profile: 'Profiili',
  logOut: 'Kirjaudu ulos',
  password: 'Salasana',
  confirmPassword: 'Vahvista salasana',
  passwordsDoesntMatch: 'Salasana ei täsmää',
  passwordWasUpdated: 'Salasanasi on päivitetty',
  profileWasUpdated: 'Profiili on päivitetty',
  passwordShouldBemoreThen6Chars: 'Salasanan tulee olla pitempi kuin 6 merkkiä',
  wrongPassword: 'Väärä salasana',
  currentPassword: 'Nykyinen salasana',
  mightBuyLabel: 'Sisältää tuotteita joita Holmasto saattaa ostaa',
  soldOnBehalfLabel: 'Sisältää huutokauppaan meneviä tuotteita',
  internalNote: 'Sisäinen kommentti',
  startDatePreBid: 'Ennakkohuuto avataan',
  createTag: 'Luo Tägi',
  endDatePreBid: 'Ennakkohuuto sulkeutuu',
  auctionIdShouldBeUnique: 'Huutokauppanumero on jo käytössä',
  more: 'Enemmän',
  less: 'Vähemmän',
  itemNumber: 'Kohdenumero',
  dateTime: 'Pvm ja aika',
  user: 'Käyttäjä',
  userId: 'Käyttäjätunnus',
  bid: 'Tarjoukset',
  bids: 'Tarjous',
  amount: 'Määrä',
  addBid: 'Lisää tarjous',
  minimumAmountIs: 'Pienin tarjous vähintään',
  increase: 'KOROTA',
  timeToMakeAnOffer: 'Aikaa tehdä tarjous',
  nSeconds: '{{ n }} sek',
  timerLength: 'Tarjousajan pituus',
  startAgain: 'MEKLAA KOHDE UUDESTAAN',
  pause: 'PYSÄYTÄ',
  next: 'SEURAAVA',
  liveAuctionHasEndedAt: 'Live-huutokauppa on sulkeutunut {{ date }}',
  paletteNumber: 'Salinumero',
  fieldShouldBeUnique: 'Tämänniminen käyttäjä on jo olemassa',
  liveAuction: 'Live huutokauppa',
  shouldBeInteger: 'Tulee olla kokonaisluku',
  attachUserOnBid: 'Kiinnitä asiakas huutoon',
  complete: 'Valmis',
  bidding: 'Huutaminen',
  bidder: 'Huutaja',
  nextItem: 'Seuraava kohde',
  itemNumberNofT: 'Kohdenumero {{ n }} / {{ t }}',
  raise: 'KOROTUS',
  minimumRiseN: 'Korotus (minimi {{ n }} €)',
  closeCurrentItem: 'VASAROI KOHDE',
  orders: 'Tilaukset',
  items: 'Kohteet',
  phoneNumber: 'Puhelinnumero',
  bidCount: 'Huutolaskuri',
  myWork: 'MyWork',
  clients: 'Asiakkaat',
  editClientN: 'Muokkaa asiakasta {{ holmastoId }} ',
  userDocuments: 'Asiakkaan asiakirjat',
  note: 'Huomautus',
  notes: 'Huomautukset',
  inWarehouse: 'Varastossa',
  auctionStatus: 'Huutokaupan status',
  maxBiddingLevel: 'Hyväksytty huutotaso',
  copyProduct: 'Kopioi tuote',
  productWasSaved: 'Tuote on tallennettu',
  productWasCreated: 'Tuote on luotu',
  publishOnline: 'Valmis ja Julkaise ',
  markAsFeatured: 'Merkkaa näkyviin pääsivulla',
  itemsCountN: 'Kohdelaskuri: {{ n }}',
  bidsCountN: 'Huutolaskuri: {{ n }}',
  auctionN: 'Huutokauppa {{ n }}',
  itemsSoldN: 'Myydyt kohteet: {{ n }}',
  trendingItems: 'Suositut kohteet',
  trendingCategories: 'Suositut kategoriat',
  price: 'Hinta',
  unpublish: 'Lopeta julkaisu',
  compensate: 'Tilitä',
  undoCompensation: 'Peruuta tilitys',
  compensated: 'Tilitetty',
  yes: 'Kyllä',
  no: 'Ei',
  sum: 'Summa',
  commission: 'Provisio',
  sellerReceives: 'Myyjälle tilitetään',
  sellerCommissions: 'Myyjäprovisio',
  sellerProducts: 'Myyjän tuotteet',
  startAmount: 'Alkusumma',
  soldAmount: 'Loppusumma',
  sellerCommissionWasUpdated: 'Myyjäprovisio on päivitetty',
  update: 'Päivitä',
  auctionHasUnpublishedProducts: 'Huutokauppaan on liitetty keskeneräisiä tuotteita, haluatko silti jatkaa?',
  editSeller: 'Muokkaa myyjä',
  sellers: 'Myyjät',
  accountNumber: 'Tilinumero (IBAN)',
  countries: 'Maat',
  none: 'Ei mitään',
  receptionWasSaved: 'Vastaanotto on tallennettu',
  attachedCard: 'Liitteenä kortti',
  lastDigits: 'Viimeiset numerot',
  expirationDate: 'Viimeinen voimassaolopäivä',
  accountType: 'Tilin tyyppi',
  personal: 'Henkilö',
  business: 'Yritys',
  shippingOrderStatus: 'Toimituksen tila',
  manageOrder: 'Käsittele tilauksia',
  manageShippingOrder: 'Käsittele toimituksia',
  verified: 'On hyväksytty',
  serialNumber: 'Sarjanumero',
  zipCode: 'Postinumero',
  liveAuctionStarted: 'Live-huutokauppa on alkanut',
  liveAuctionEnded: 'Huutokauppa on sulkeutunut',
  shippingIsSameAsBilling: 'Toimitusosoite sama kuin laskutusosoite',
  shippingAddress: 'Toimitusosoite',
  billingAddress: 'Laskutusosoite',
  nationality: 'Kansallisuus',
  bidLimitRequests: 'Pyyntö huutorajan nostolle',
  disabled: 'Ei käytettävissä',
  editBid: 'Muokkaa huutoa',
  notifyWhenBidIsWinning: 'Ilmoita kun minulla on voittava huuto',
  notifyWhenBidIsntWinning: 'Ilmoita kun huutoni on ylitetty',
  notifications: 'Ilmoitukset',
  wrongCredentials: 'Sähköpostiosoite tai salasana on väärä',
  generateExcel: 'Generoi Excel-tiedosto',
  alreadyCompensated: 'Tilitys tehty',
  sellerGets: 'Myyjän osuus',
  invoice: 'Lasku',
  approvedReceptions: 'Hyväksytyt sisäänostotarjoukset',
  passwordConfirmation: 'Vahvista salasana',
  passwordShouldBeMinimum: 'Salasanan vähimmäisvaatimus {{ minimum }}',
  passwordsMustMatch: 'Salasana ei täsmä',
  userCantBeDeletedBecauseHeHasReceptions: 'Asiakasta ei voi poistaa koska hänellä on vastaanottoja kesken',
  userCantBeDeletedBecauseHeHasBids: 'Asiakasta ei voi poistaa koska hänellä on aktiivisia huutoja',
  unknownError: 'Tuntematon virhe tapahtui',
  userWasDeleted: 'Asiakas on poistettu',
  deleteUser: 'Poista asiakas',
  areYouSureCantUndone: 'Oletko varma? Muutosta ei voi palauttaa',
  confirmDeleteReception: 'Oletko varma, että haluat poistaa vastaanoton? Tätä toimintoa ei voi peruuttaa.',
  isWinning: 'Voittava tarjous',
  userName: 'Asiakkaan nimi',
  lotNumber: 'Kohdenumero',
  marginalRate: 'Marginaaliverotus',
  vatPercent: 'ALV %',
  openOrders: 'Avoimet tilaukset',
  createWebshopOrder: 'Luo verkkokauppatilaus',
  generateSoldProductExcel: 'Generoi vasarahinta-Excel',
  system: 'Ennakkotarjous',
  online: 'Online',
  facilitator: 'Sali',
  wonItems: 'Voitetut kohteet',
  wonPrice: 'Vasarahinta',
  receptions: 'Vastaanotot',
  soldProducts: 'Myydyt kohteet',
  paidWith: 'Maksettu:',
  shipment: 'Toimitus',
  total: 'Yhteensä',
  view: 'Katso',
  open: 'Aktiivinen',
  closed: 'Suljettu',
  deadline: 'Deadline (fi)',
  openReceptionIAmPersonalAssigned: 'Avoin vastaanotto jossa olen vastuuhenkilö',
  openReceptionIAmExpert: 'Avoin vastaanotto jossa minulla on tehtävä',
  id: 'ID ',
  daysBeforeDeadline: 'päivää jäljellä määräaikaan',
  openItemAcceptancesWhereIAmAssignedReception: 'Avoimet vastaanoton kohdehyväksynnät johon minut on kiinnitetty',
  openItemAcceptancesWhereIAmAssigned: 'Avoimet kohdehyväksynnät johon minut on kiinnitetty',
  receptionId: 'Vastaanoton numero',
  receptionPersonell: 'Vastaanoton henkilöstö',
  customerName: 'Asiakkaan nimi',
  sellerName: 'Myyjän nimi',
  bidsTotal: 'Huutoja yhteensä',
  uniqueCustomersWithBids: 'Uniikit huutajat',
  itemsWithBids: 'Kohteita jossa huutoja',
  sumOfWinningPreBids: 'Ennakkohuudettujen kohteiden yhteissumma',
  sumOfWinningLiveBids: 'Vasarahintojen yhteissumma',
  itemsWithMostBids: 'Kohteet joissa eniten huutoja',
  categoriesWithMostBids: 'Kategoriat joissa eniten huutoja',
  kpi: 'Huutokauppatilastot',
  auctionTotals: 'Huutokaupan tuotto',
  openReceptions: 'Avoimet vastaanotot',
  openItems: 'Avoimet asiakkaiden kohteet',
  newCustomers: 'Uudet asiakkaat',
  requestedAt: 'Pyyntö lähetetty',
  payments: 'Maksaminen',
  totalSum: 'Yhteissumma',
  completedOrders: 'Valmiit tilaukset',
  shippingMethod: 'Toimitusmuoto',
  buyerPays: 'Ostaja maksaa',
  orderItems: 'Tilauksen kohteet',
  acceptedReceptionProposals: 'Hyväksytyt ostotarjoukset',
  notCompensated: 'Tilittämättä',
  numberOfItems: 'Kohteiden lukumäärä',
  paymentStatus: 'Maksun status',
  compensationStatus: 'Tilityksen status',
  fee: 'Käsittelumaksu',
  sellerItems: 'Myyjän kohteet',
  sellerSoldItems: 'Myydyt myyjän kohteet',
  compensatedWithoutPurchaseInvoice: 'Tilitetty ilman ostolaskua',
  compensateSeller: 'Suorita tilitys myyjälle',
  createPurchaseInvoices: 'Luo ostolasku valinnalle',
  markPaidInStore: 'Merkitse maksetuksi liikkeessä',
  language: 'Kieli',
  generateContract: 'Generoi sopimus',
  verificationMethod: 'Tunnistustapa',
  basic_info_filled: 'Perustiedot syötetty',
  copyProductToNewAuction: 'Kopioi tuote toiseen huutokauppaan',
  copyToAuction: 'Kopioi huutokauppaan',
  totalCommission: 'provisio yhteensä',
  boughtBy: 'Ostaja',
  unsavedChangesPrompt: 'There are some unsaved changes (fi)',
  clientWasUpdated: 'Client was updated (fi)',
  clientWasCreated: 'Client was created (fi)',
  categoryWasUpdated: 'Category was updated (fi)',
  categoryWasCreated: 'Category was created (fi)',
  tagWasUpdated: 'Tag was updated (fi)',
  tagWasCreated: 'Tag was created (fi)',
  receptionWasUpdated: 'Reception was updated (fi)',
  receptionWasCreated: 'Reception was created (fi)',
  timerIsStillActive: 'Timer is still active (fi)',
  onlineCustomers: 'Online Customers (fi)',
  otherCustomers: 'Other Customers (fi)',
  evaluation: 'Arviointi',
  toSell: 'Myyntitarkoitus',
  estateInventory: 'Perunkirjoitus',
  insuranceEvaluation: 'Vakuutusarviointi',
  gemmologicalExamination: 'Gemmologinen tutkimus',
  purposeOfSale: 'Vastaanoton tarkoitus',
  toAuction: 'Huutokauppaan',
  salesOrder: 'Myyntitoimeksianto',
  notifySeller: 'Notify seller (fi)',
  oneTimeCustomers: 'Kertaluontoiset asiakkaat',
  paidInStore: 'Paid in store (fi)',
  before: 'Ennen',
  after: 'Jälkeen',
  verifyUsers: 'Verify users (fi)',
  internalError: 'Internal Error (fi)',
  notificationsWhereScheduled: 'Email notifications where scheduled (fi)',
  proposal: 'Ehdotus',
  proposalPdf: 'Proposal PDF (fi)',
  printTheReceptionReceipt: 'Tulosta vastaanoton kuitti',
  enableEdit: 'Ota käyttöön',
  disableEdit: 'Ota pois käytöstä',
  approved: 'Approved (fi)',
  created: 'Aloitettu',
  readyToSend: 'Valmis lähetettäväksi',
  published: 'Julkaistu',
  declined: 'Ei hyväksytty',
  auctionCountdown: 'Auction should start in {{ m }} Months, {{ d }} days, {{ h }} hours, {{ s }} seconds (fi)',
  notifyUsersToPay: 'Notify users to pay (fi)',
  sentWaitingForAnswer: 'Lähetetty, odottaa vastausta',
  liveAuctionWillStartIn: 'Live-huutokaupan alkuun',
  other: 'Muu',
  pendingEmails: 'Sähköpostiosoitteen muutosloki',
  resendEmail: 'Lähetä sähköposti uudelleen',
  yourRequestHasCompleted: 'Lähetä viesti: Jättämänne toimeksianto on suoritettu',
  userWasAlreadyNotifiedNotifyAgain: 'Email was already send. Do you want to send again? (fi)',
  companyName: 'Yrityksen nimi',
  proposalSent: 'Ehdotus lähetetty',
  tools: 'Työkalut',
  notifiSellerReceptionReceived: 'Lähetä viesti: Olemme vastaanottaneet esineesi arvioitavaksi',
  printTheQrCode: 'Tulosta QR koodi',
  directPurchase: 'Suoraosto',
  notifyReceptionEvaluationHasCompleted: 'Lähetä viesti: Arviointityö on valmistunut!',
  printSalesReceipt: 'Tulosta myyntitosite',
  createPurchaseInvoice: 'Luo ostolasku',
  purchaseInvoiceWasCreated: 'Ostolasku luotiin',
  sendSalesContract: 'Lähetä viesti: Myyntisopimus',
  printSalesContract: 'Tulosta myyntisopimus',
  notificationWasSent: 'Ilmoitus lähetettiin',
  auctionHasNoProducts: 'Ole hyvä ja lisää tuotteita huutokauppaan ennen kuin käynnistät livehuutokaupan',
  saveChanges: 'Tallenna muutokset',
  updateFastTrackReception: 'Päivitä pikaosto',
  unPublished: 'Julkaisematon',
  markAsCompensated: 'Merkitse maksetuksi liikeessä',
  address: 'Osoite',
  phone: 'Puhelinnumero',
  fastTrackReceptionWasUpdated: 'Pikaosto päivitetty',
  fastTrackReceptionWasCreated: 'Fast track reception was created (fi)',
  add: 'Add (fi)',
  history: 'History (fi)',
  cash: 'Käteinen',
  netvisor: 'Netvisor',
  paymentMethod: 'Maksutapa',
  taxClass: 'Veroluokka',
  fastBuyItems: 'Kauppatavara',
  productCantBePublished: "Productb {{ sku }} can't be published",
  setStatus: 'Päivitä valittujen tuotteiden tila',
  accountNumberIsRequired: 'In order to create purchase invoice fill account number',
  fastTrackReceptions: 'Pikaosto',
  addRow: 'Lisää rivi',
  receipts: 'Tositteet',
  addFastTrackReception: 'Lisää uusi pikaosto',
  socialSecurityNumber: 'Syntymäaika ja sosiaaliturvatunnus',
  form: 'Lomake',
  createSalesContract: 'Luo myyntisopimus',
  runningNumber: 'Running number',
  productWasUnsold: 'Product was unsold (fi)',
  sellToUser: 'Sell to user (fi)',
  youReAboutToChangeAssignedAuctionId: "You're about to change assigned auction (fi)",
  youCantChangeSoldProductsAuction: "You can't change assigned auction for sold product (fi)",
  itemWasSoldToTheUser: 'Items as sold to user (fi)',
  printActions: 'Print actions',
  editProduct: 'Edit product',
  unlockForEditing: 'Unlock for editing',
  lockForEditing: 'Lock for editing',
  afterAuctionTools: 'After auction tools',
  unsell: 'Unsell',
  purchaseItemToHolmasto: 'Purchase item to holmasto',
  moveToAnotherAuction: 'Move to another auction',
  previewProductInCatalogue: 'Preview auction in catalogue',
  returnedToSeller: 'Returned to seller (fi)',
  sold: 'Sold (fi)',
  current: 'Current (fi)',
  waiting: 'Waiting (fi)',
  biddingEnded: 'Bidding ended (fi)',
  readyForCatalogue: 'Valmis luetteloon',
  actions: 'Actions (fi)',
  soldToHolmasto: 'Sold to holmasto (fi)',
  movedFromEarlierAuction: 'Moved from earlier auction (fi)',
  itemCreated: 'Luotu',
  updated: 'Päivitetty',
  grouped: 'Kohteet',
  list: 'Viimeisimmät huudot',
  type: 'Tyyppi',
  verfictionStatus: 'Hyväksymistaso',
  disableFromCatalogue: 'Disable from catalogue (fi)',
  enableInCatalogue: 'Enable in catalogue (fi)',
  featuredImages: 'Featured Images (fi)',
  startPrice: 'Start price',
  filterItemDescription: 'Filter item description',
  copy: 'Copy',
  historyItems: 'History items (fi)',
  itemWasCompied: 'Item was copied',
  createWebshopProduct: 'Create webshop product',
  webshopProduct: 'Webshop product',
  editWebshopProduct: 'Edit webshop product ({{ lang }})',
  showFullTable: 'Show full table (fi)',
  tempId: 'Väliaikainen tunnus',
  archived: 'Arkistoitu',
  archive: 'Arkistoi',
  archiveLabel: 'Arkisto',
  archiveReception: 'Arkistoi',
  unarchive: 'Palauta avoimeksi',
  proposalWasSent: 'Sales contract was sent',
})

export default fi

import axios from 'axios'
import { FUNCTIONS_API_BASE_URL } from 'constants/index'

export const apiInstance = axios.create({
  baseURL: FUNCTIONS_API_BASE_URL,
  headers: { 'Content-Type': 'application/json' },
})

export const createFastTrackReception = data => apiInstance.post('/fast-track-receptions', data)
export const updateFastTrackReception = (id, data) => apiInstance.put('/fast-track-receptions/' + id, data)
export const deleteFastTrackReception = id => apiInstance.delete('/fast-track-receptions/' + id)
export const createFastTrackReceptionPurchaseInvoice = id =>
  apiInstance.put('/fast-track-receptions/' + id + '/invoice')

export const markFastTrackReceptionAsCompensated = id => apiInstance.put('/fast-track-receptions/' + id + '/cash')
export const getFastTrackReceptionContract = id =>
  apiInstance.get('/fast-track-receptions/' + id + '/contract', {
    responseType: 'arraybuffer',
  })

export const setProductsStatus = data => apiInstance.post('/products/set-status', data)
export const unsellAuctionProduct = id => apiInstance.post('/products/' + id + '/unsell')
export const sellAuctionProduct = (id, userId, amount, commission) =>
  apiInstance.post('/products/' + id + '/sell', { userId, amount, commission })
export const sellAuctionProductToHolmasto = (id, amount, channel, assignedAuctionId, sellerCommission) =>
  apiInstance.post('/products/' + id + '/sell-holmasto', { amount, channel, assignedAuctionId, sellerCommission })

export const createWebshopProduct = id => apiInstance.post('/products/' + id + '/create-webshop-product')

export const previewProductsPending = (ids, receptionId) =>
  apiInstance.post('/preview/products-pending', { ids, receptionId })

export const previewReceptionReceived = id => apiInstance.post('/preview/reception-received/' + id)
export const receptionEvaluationFinished = id => apiInstance.post('/preview/reception-evaluation-finished/' + id)
export const receptionSendProposal = id => apiInstance.post('/preview/reception-send-proposal/' + id)

import { useState, useEffect, useRef, useMemo } from 'react'
import {
  collection,
  onSnapshot,
  setDoc,
  query,
  addDoc,
  serverTimestamp,
  doc,
  where,
  orderBy,
  documentId,
} from 'firebase/firestore'

import { db } from './firebase'
import { auctionConventer } from 'conventers/auctionConventer'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useAddChangelogs } from './changelogs'
import { changelogTypes } from 'constants/changelogs'
import { useMultiQueryData } from 'hooks/useMultiQueryData'
import { chunk, uniq } from 'lodash'

/**
 * Get product with id. Also the data keeps updating if it changes in Firestore.
 *
 * TODO: Add loading and error properties to the return value.
 *
 * @returns {object} Containing auction data
 */
export function useAuctions(getList = true) {
  // Store auctions data to local state
  const [auctions, setAuctions] = useState([])
  const [loading, setLoading] = useState(true)
  const addChangelog = useAddChangelogs()

  // Sync product data
  useEffect(() => {
    if (!getList) return setLoading(false)

    setLoading(true)
    const q = query(
      collection(db, 'auctions'),
      where('deletedAt', '==', null),
      orderBy('startDate', 'desc')
    ).withConverter(auctionConventer)

    // Subscribe to data from /auctions
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })

        // Set auctions data to local state in this hook.
        setAuctions(data)
        setLoading(false)
      },
      error => {
        console.error('Failed to sync auction data', error)
      }
    )

    return () => {
      // Unsubscript to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [setAuctions, getList])

  // These functions mutate data in Firestore.
  const mutationsRef = useRef(
    {
      // Update auction in Firestore.
      setAuction: (data, auctionId) => {
        const auctionDocument = doc(db, 'auctions', auctionId)
        return addChangelog(auctionDocument, changelogTypes.UPDATE, () =>
          setDoc(
            auctionDocument,
            {
              ...data,
              updatedAt: serverTimestamp(),
            },
            { merge: true }
          )
        )
      },
      addAuction: async data => {
        const auctionDoc = await addDoc(collection(db, 'auctions'), {
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          deletedAt: null,
          ...data,
        })

        await addChangelog(auctionDoc, changelogTypes.CREATE)

        return auctionDoc
      },
      deleteAuction: id => {
        const auctionDoc = doc(db, 'auctions', id)
        return addChangelog(auctionDoc, changelogTypes.DELETE, () =>
          setDoc(auctionDoc, { deletedAt: serverTimestamp() }, { merge: true })
        )
      },
    },
    []
  )

  return {
    // auctions data from Firebase
    auctions,
    loading,
    // Spread Firestore mutation functions.
    ...mutationsRef.current,
  }
}

/**
 * Get Auction which is ended.
 *
 *
 * @returns {object} Containing auction data
 */
export function useEndedAuctions() {
  // Store auctions data to local state
  const [auctions, setAuctions] = useState([])
  const [loading, setLoading] = useState(true)

  // Sync product data
  useEffect(() => {
    setLoading(true)
    const q = query(
      collection(db, 'auctions'),
      where('liveAuctionEndedAt', '!=', null),
      where('deletedAt', '==', null),
      orderBy('liveAuctionEndedAt', 'desc')
    )

    // Subscribe to data from /auctions
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })

        // Set auctions data to local state in this hook.
        setAuctions(data)
        setLoading(false)
      },
      error => {
        console.error('Failed to sync auction data', error)
      }
    )

    return () => {
      // Unsubscript to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [])

  return {
    // auctions data from Firebase
    auctions,
    loading,
  }
}

export const usePublishedAuctions = () => {
  const [auctions = [], loading, error] = useCollectionData(
    query(
      collection(db, 'auctions'),
      where('status', '==', 'published'),
      where('deletedAt', '==', null),
      orderBy('startDate', 'desc')
    ).withConverter(auctionConventer)
  )

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return { auctions, loading }
}

export const useAuctionsByIds = ids => {
  const queries = useMemo(
    () =>
      chunk(uniq(ids || []), 10).map(ids =>
        query(collection(db, 'auctions'), where(documentId(), 'in', ids)).withConverter(auctionConventer)
      ),
    [ids]
  )

  const [auctions, loading] = useMultiQueryData(...queries)

  return [auctions, loading]
}

import { useAppTranslation } from 'i18n/hooks'
import styled from 'styled-components'
import { Tab } from '@mui/material'
import { spacing } from 'theme/spacing'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box } from '@mui/system'
import { General } from './General'
import { NoInformation } from './NoInformation'
import { useQuery } from 'utils/useQuery'
import { useSetQuery } from 'utils/useSetQuery'
import { Bids } from './Bids'
import { MyWork } from './MyWork'

function Home() {
  const { t } = useAppTranslation()

  const { tab = tabTypes.MY_WORK } = useQuery()
  const setQuery = useSetQuery()

  return (
    <MainDiv>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(e, tab) => setQuery({ tab }, false)} aria-label="lab API tabs example">
            <Tab label={t('general')} value={tabTypes.GENERAL} />
            <Tab label={t('myWork')} value={tabTypes.MY_WORK} />
            <Tab label={t('noInformation')} value={tabTypes.NO_INFORMATION} />
            <Tab label={t('bids')} value={tabTypes.BIDS} />
          </TabList>
          <TabPanel value={tabTypes.GENERAL}>
            <General />
          </TabPanel>
          <TabPanel value={tabTypes.MY_WORK}>
            <MyWork />
          </TabPanel>
          <TabPanel value={tabTypes.NO_INFORMATION}>
            <NoInformation />
          </TabPanel>
          <TabPanel value={tabTypes.BIDS}>
            <Bids />
          </TabPanel>
        </Box>
      </TabContext>
    </MainDiv>
  )
}

const tabTypes = Object.freeze({
  GENERAL: 'general',
  NO_IMAGES: 'no_images',
  NO_INFORMATION: 'no_information',
  BIDS: 'bids',
  MY_WORK: 'my_work',
})

export default Home

const MainDiv = styled.div`
  display: flex;
  flex-direction: column;

  .card_spacing {
    margin-bottom: ${spacing(6)};
    margin-top: ${spacing(6)};
  }
  .grid_box {
    padding: ${spacing(6)};
  }
  .grid_title {
    margin-bottom: ${spacing(6)};
  }
`

import Text, { TextTypes } from '../index'
import { Colors } from 'constants/colors'
import { string } from 'prop-types'

export const Error = ({ children }) => (
  <Text type={TextTypes.BODY_13} color={Colors.CRIMSON}>
    {children}
  </Text>
)

Error.propTypes = {
  children: string,
}

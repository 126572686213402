import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, ListItemButton, ListItemText } from '@mui/material'
import { Colors } from 'constants/colors'
import { string } from 'prop-types'
import { useAppHistory } from 'routes/dashboard'
import styled from 'styled-components'
import DeleteConfirmationDialog from 'Components/Shared/DeleteConfirmationDialog'
import { useState } from 'react'
import { useTag } from 'data/tag'

export const Item = ({ id, label }) => {
  const history = useAppHistory()
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const { softDeleteTag } = useTag()

  const goToEdit = () => history.push(`/tag/edit/${id}`)
  const onClose = action => {
    if (action === 'delete') softDeleteTag(id)
    setShowDeleteDialog()
  }

  return (
    <>
      <StyledListItem sx={{ mb: 2 }}>
        <ListItemText primary={label} />
        <IconButton size="small" onClick={goToEdit}>
          <FontAwesomeIcon size="sm" icon={['fas', 'pen-to-square']} color={Colors.MAROON} />
        </IconButton>
        <IconButton size="small" onClick={() => setShowDeleteDialog(true)}>
          <FontAwesomeIcon size="sm" icon={['fas', 'trash']} color={Colors.MAROON} />
        </IconButton>
      </StyledListItem>
      <DeleteConfirmationDialog open={showDeleteDialog} onClose={onClose} />
    </>
  )
}

Item.propTypes = {
  id: string,
  label: string,
}

const StyledListItem = styled(ListItemButton)`
  background-color: ${Colors.WHITE}!important;
`

import { useState, useEffect, useRef } from 'react'
import { doc, onSnapshot, query, serverTimestamp, setDoc } from 'firebase/firestore'

import { db } from './firebase'

/**
 * Get tag with id. Also the data keeps updating if it changes in Firestore.
 *
 * TODO: Add loading and error properties to the return value.
 *
 * @param {string} id tagId
 * @returns {object} Containing tag data
 */
export function useTag(tagId) {
  // Store tag data to local state
  const [tag, setCategories] = useState({})

  // Sync tag data
  useEffect(() => {
    if (!tagId) return
    const q = query(doc(db, 'tags', tagId))

    // Subscribe to data from /tags
    const unsubscribe = onSnapshot(
      q,
      // This callback is called when data is initially fetched and when it changes.
      doc => {
        const data = { ...doc.data(), id: doc.id }

        // Set tags data to local state in this hook.
        setCategories(data)
      },
      error => {
        console.error('Failed to sync Tag data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
      setCategories({})
    }
  }, [setCategories, tagId])

  // These functions mutate data in Firestore.
  const mutationsRef = useRef(
    {
      // Update tag in Firestore.
      setTag: (data, id) => {
        return setDoc(
          doc(db, 'tags', id),
          {
            ...data,
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        )
      },
      softDeleteTag: id => {
        return setDoc(
          doc(db, 'tags', id),
          {
            deletedAt: serverTimestamp(),
          },
          { merge: true }
        )
      },
    },
    [tagId]
  )

  return {
    // Categories data from Firebase
    tag,
    // Spread Firestore mutation functions.
    ...mutationsRef.current,
  }
}

import { useAuctionBids } from 'data/bids'
import { usePublishedProducts } from 'data/publishedProducts'
import { useMemo } from 'react'
import { useGetTranslated } from 'utils/useGetTranslated'
import _, { uniqBy, orderBy } from 'lodash'
import { biddedByValues, bidTypes } from 'constants/bid'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { getMinimumRise } from 'utils/getMinimumRise'
import { useProductDynamic } from 'data/productDynamic'

export const useLatestAuctionStats = auctionId => {
  const { products, loading: productsLoading } = usePublishedProducts(auctionId)
  const { products: originalProducts } = useProductDynamic({
    queryKey: 'assignedAuctionId',
    queryValue: auctionId,
    queryTerm: '==',
  })
  const [bids, bidsLoading] = useAuctionBids(auctionId)
  const getTranslated = useGetTranslated()

  const bidCount = useMemo(() => bids.length, [bids])
  const itemsCount = useMemo(() => products.length, [products])
  const itemsWithBidsCount = useMemo(() => uniqBy(bids, 'itemId').length, [bids])
  const custumersWithBidsCount = useMemo(() => uniqBy(bids, 'userId').length, [bids])
  const winningPreBidsSum = useMemo(
    () =>
      _(bids)
        .filter(({ type }) => type === bidTypes.PRE)
        .groupBy('itemId')
        .reduce((sum, items, key) => {
          const [winningBid, secondHighest] = orderBy(items, 'amount', 'desc')

          const amount = winningBid.amount

          if (secondHighest && getMinimumRise(secondHighest.amount) + secondHighest.amount) {
            // If there is previous bid and it's too less then winning bid
            return sum + getMinimumRise(secondHighest.amount) + secondHighest.amount
          } else if (secondHighest) {
            // if there is second highest bid then we need to return amoumnt
            return sum + amount
          }

          const firstLiveSystemBid = orderBy(
            bids.filter(({ itemId, biddedBy }) => itemId === key && biddedBy === biddedByValues.SYSTEM),
            'amount',
            'asc'
          )[0]

          // First system bid always equals to hihhest prebid so if there is system bid return it
          if (firstLiveSystemBid) return sum + firstLiveSystemBid.amount

          const product = products.find(({ id }) => id === key)

          // If there is no system bid then return second highest as auction isn't started and highest prebid is seciond highest attribute of products
          return sum + product.secondHighestBid
        }, 0),
    [bids, products]
  )
  const winningLiveBidsSum = useMemo(
    () =>
      bids
        .filter(({ isWinning, type, invalid }) => !invalid && isWinning && type === bidTypes.LIVE)
        .reduce((sum, { amount }) => sum + amount, 0) +
      (originalProducts
        ?.filter(({ soldToUserId }) => !!soldToUserId)
        ?.reduce((sum, { finalAmount }) => sum + finalAmount, 0) ?? 0),
    [bids, originalProducts]
  )

  const mostBiddedProducts = useMemo(
    () =>
      products
        .sort((a, b) => (b.bidsCount || 0) - (a.bidsCount || 0))
        .slice(0, 5)
        .map(({ name, bidsCount }) => ({
          label: getTranslated(name),
          value: <Text type={TextTypes.BODY_20}>{bidsCount}</Text>,
        })),
    [products, getTranslated]
  )

  const topCategories = useMemo(
    () =>
      products
        .map(({ category }) => category)
        .filter(({ id }, index, list) => list.findIndex(item => item.id === id) === index)
        .map(({ label, id }) => ({
          name: getTranslated(label),
          bidsCount: products
            .filter(({ category }) => category.id === id)
            .reduce((total, { bidsCount = 0 }) => total + bidsCount, 0),
        }))
        .sort((a, b) => (b.bidsCount || 0) - (a.bidsCount || 0))
        .map(({ name, bidsCount }) => ({
          label: name,
          value: <Text type={TextTypes.BODY_20}>{bidsCount}</Text>,
        }))
        .slice(0, 5),
    [products, getTranslated]
  )

  const loading = bidsLoading || productsLoading

  return {
    bidCount,
    itemsCount,
    mostBiddedProducts,
    topCategories,
    itemsWithBidsCount,
    custumersWithBidsCount,
    winningPreBidsSum,
    winningLiveBidsSum,
    loading,
  }
}

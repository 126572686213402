import { useLocation } from 'react-router'
import { useMemo } from 'react'

export const useQuery = () => {
  const { search } = useLocation()

  return useMemo(() => {
    const searchParams = new URLSearchParams(search)
    const returnObject = {}

    searchParams.forEach((val, key) => {
      returnObject[key] = val
    })

    return returnObject
  }, [search])
}

import { AgGridColumn } from 'ag-grid-react'
import { dateTimeFormat } from 'constants/index'
import dayjs from 'dayjs'
import { useAppTranslation } from 'i18n/hooks'
import { array } from 'prop-types'
import { Edit } from './Edit'
import { AccountType, getProfileVerificationStatusColor } from 'constants/profiles'
import { useMemo } from 'react'
import { AgLink } from 'Components/Shared/AgLink'
import { GridStatus } from 'Components/Shared/Status'
import { GridWrap } from 'Components/Shared/GridWrap'

export const Table = ({ items }) => {
  const { t } = useAppTranslation()

  const data = useMemo(
    () =>
      items.map(item => ({
        ...item,
        name: item.accountType === AccountType.BUSINESS ? item.company : [item.firstName, item.lastName].join(' '),
        createdAt: dayjs(item?.createdAt).format(dateTimeFormat),
      })),
    [items]
  )

  return (
    <GridWrap
      rowData={data}
      gridOptions={{ headerHeight: 50 }}
      defaultColDef={{
        sortable: true,
        flex: 2,
        filter: true,
        floatingFilter: true,
        resizable: true,
      }}
      frameworkComponents={{
        edit: Edit,
        link: AgLink,
        status: GridStatus,
      }}
      rowHeight={60}
      height={400}
    >
      <AgGridColumn headerClass={'grid_header'} field="name" headerName={t('name')} />
      <AgGridColumn
        headerClass={'grid_header'}
        field="holmastoId"
        headerName={t('holmastoId')}
        cellRenderer="link"
        cellRendererParams={{
          to: '/clients/{id}/view',
        }}
      />
      <AgGridColumn headerClass={'grid_header'} field="email" headerName={t('email')} />
      <AgGridColumn
        headerClass={'grid_header'}
        field="verificationStatus"
        headerName={t('verificationStatus')}
        cellRenderer="status"
        cellRendererParams={{
          getColor: getProfileVerificationStatusColor,
        }}
      />
      <AgGridColumn headerClass={'grid_header'} headerName={t('createdAt')} field="createdAt" />
      <AgGridColumn
        headerClass={'grid_header'}
        field="id"
        headerName={t('actions')}
        cellRenderer="edit"
        filter={false}
      />
    </GridWrap>
  )
}

Table.propTypes = {
  items: array,
}

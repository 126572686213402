import { useState, useEffect, useMemo, useCallback } from 'react'
import {
  collection,
  query,
  where,
  documentId,
  getDocs,
  doc,
  setDoc,
  addDoc,
  orderBy,
  onSnapshot,
  limit,
} from 'firebase/firestore'
import { chunk } from 'lodash'
import { auth, db } from './firebase'
import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'
import { useAuthState } from 'react-firebase-hooks/auth'
import { profileNoteConventer } from 'conventers/profileNoteConventer'
import { profileConventer } from 'conventers/profileConventer'
import dayjs from 'dayjs'
import { useAddChangelogs } from './changelogs'
import { changelogTypes } from 'constants/changelogs'
import { pendingEmailConventer } from 'conventers/pendingEmailConventer'

/**
 * Get profiles with id.
 *
 * TODO: Add loading and error properties to the return value.
 *
 * @param {string[]} ids
 * @returns {object} Containing profiles data
 */
export function useProfiles(ids = []) {
  // Store profile data to local state
  const [profiles, setProfiles] = useState([])
  const [loading, setLoading] = useState(false)

  // Sync profiles data
  useEffect(() => {
    if (!ids?.length) return
    setLoading(true)
    const batches = chunk(
      ids.filter((id, index, list) => list.indexOf(id) === index),
      10
    ).map(ids => getDocs(query(collection(db, 'profiles'), where(documentId(), 'in', ids))).then(result => result.docs))

    Promise.all(batches).then(content => {
      const data = content.flat().map(doc => ({ ...doc.data(), id: doc.id }))
      setProfiles(data)
      setLoading(false)
    })
  }, [ids])

  return {
    // profile data from Firebase
    profiles,
    loading,
  }
}

/**
 * Get all profiles.
 *
 * @returns {object} Containing profiles
 */
export function useAllProfiles() {
  // Store profile data to local state
  const [profiles, setProfiles] = useState([])
  const [loading, setLoading] = useState(true)

  // Sync profiles data
  useEffect(() => {
    getDocs(query(collection(db, 'profiles'))).then(content => {
      const data = content.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        createdAt: (doc.data().createdAt?.toDate && doc.data().createdAt?.toDate()) || null,
      }))
      setProfiles(data)
      setLoading(false)
    })
  }, [])

  return {
    // profile data from Firebase
    profiles,
    loading,
  }
}

/**
 * Get new profiles.
 *
 * @returns {object} Containing profiles
 */
export function useNewProfiles() {
  // Store profile data to local state
  const [profiles, setProfiles] = useState([])

  // Sync profiles data
  useEffect(() => {
    getDocs(
      query(
        collection(db, 'profiles'),
        where('createdAt', '>=', dayjs().subtract(2, 'month').toDate()),
        orderBy('createdAt', 'desc')
      )
    ).then(content => {
      const data = content.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        createdAt: (doc.data().createdAt?.toDate && doc.data().createdAt?.toDate()) || null,
      }))
      setProfiles(data)
    })
  }, [])

  return {
    // profile data from Firebase
    profiles,
  }
}
/**
 * Get all profiles.
 *
 * @returns {object} Containing profiles
 */
export function useProfilesByEmailVerified(emailVerified = true) {
  // Store profile data to local state
  const [profiles, loading, error] = useCollectionData(
    query(
      collection(db, 'profiles'),
      where('emailVerified', '==', emailVerified),
      where('email', '!=', null)
    ).withConverter(profileConventer)
  )

  const sortedProfiles = useMemo(
    () => (profiles || []).sort((p1, p2) => p2.createdAt.getTime() - p1.createdAt.getTime()),
    [profiles]
  )

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return {
    // profile data from Firebase
    profiles: sortedProfiles,
    loading,
    error,
  }
}

/**
 * Get profiles without email.
 *
 * @returns {object} Containing profiles
 */
export function useProfilesWithoutEmail() {
  // Store profile data to local state
  const [profiles, loading, error] = useCollectionData(
    query(collection(db, 'profiles'), where('email', '==', null), orderBy('createdAt', 'desc')).withConverter(
      profileConventer
    )
  )

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return {
    // profile data from Firebase
    profiles,
    loading,
    error,
  }
}

/**
 * Get all profiles.
 *
 * @returns {object} Containing profiles
 */
export function useRequestedProfiles() {
  // Store profile data to local state
  const [profiles, setProfiles] = useState([])

  // Sync profiles data
  useEffect(() => {
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      query(
        collection(db, 'profiles'),
        where('maxBiddingLevelIncreaseRequested', '!=', null),
        orderBy('maxBiddingLevelIncreaseRequested', 'asc')
      ),
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          createdAt: (doc.data().createdAt?.toDate && doc.data().createdAt?.toDate()) || null,
          maxBiddingLevelIncreaseRequested: doc.data()?.maxBiddingLevelIncreaseRequested?.toDate
            ? doc.data().maxBiddingLevelIncreaseRequested.toDate()
            : null,
        }))
        setProfiles(data)
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [])

  return {
    // profile data from Firebase
    profiles,
  }
}

/**
 * Get non seller profiles.
 *
 * @returns {object} Containing profiles
 */
export function useNonSellerProfiles() {
  // Store profile data to local state
  const [profiles, setProfiles] = useState([])

  // Sync profiles data
  useEffect(() => {
    getDocs(query(collection(db, 'profiles'), where('isSeller', '==', false))).then(content => {
      const data = content.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data().createdAt?.toDate() || null,
      }))
      setProfiles(data)
    })
  }, [])

  return {
    // profile data from Firebase
    profiles,
  }
}

export const useProfile = id => {
  const docRef = useMemo(() => (id ? doc(db, 'profiles', id) : undefined), [id])
  const [profile, loading, error] = useDocumentData(docRef)

  useEffect(() => {
    if (error) {
      console.error(error.message, error.stack)
    }
  }, [error])

  return [profile, loading]
}

export const useProfilePendingEmails = id => {
  const docRef = useMemo(
    () =>
      id
        ? query(collection(db, 'profiles/' + id + '/pendingEmails'), orderBy('createdAt', 'desc')).withConverter(
            pendingEmailConventer
          )
        : undefined,
    [id]
  )
  const [pendingEmails, loading, error] = useCollectionData(docRef)

  useEffect(() => {
    if (error) {
      console.error(error.message, error.stack)
    }
  }, [error])

  return [pendingEmails, loading]
}

export const useUpdateProfile = id => {
  const docRef = useMemo(() => doc(db, 'profiles', id), [id])
  const [loading, setLoading] = useState(false)
  const addChangeLogs = useAddChangelogs()
  const [user] = useAuthState(auth)

  const createNote = useCallback(
    /**
     *
     * @param {string} note
     */
    async note => {
      if (!user) return
      const collectionRef = collection(db, `profiles/${id}/notes`)
      addDoc(collectionRef, { note, user: { id: user.uid } })
    },
    [id, user]
  )

  const update = useCallback(
    async ({ note, ...data }) => {
      setLoading(true)
      if (note) await createNote(note)
      await addChangeLogs(docRef, changelogTypes.UPDATE, () => setDoc(docRef, data, { merge: true }))
      setLoading(false)
    },
    [docRef, createNote, addChangeLogs]
  )

  return [update, loading]
}

export const useProfileNotes = id => {
  const collectionRef = useMemo(
    () =>
      query(collection(db, `profiles/${id}/notes`), orderBy('createdAt', 'desc')).withConverter(profileNoteConventer),
    [id]
  )
  const [profile, loading, error] = useCollectionData(collectionRef)

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return [profile, loading, error]
}

export const useLastNote = id => {
  const collectionRef = useMemo(
    () =>
      query(collection(db, `profiles/${id}/notes`), orderBy('createdAt', 'desc'), limit(1)).withConverter(
        profileNoteConventer
      ),
    [id]
  )
  const [[note] = [], loading, error] = useCollectionData(collectionRef)

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return [note, loading, error]
}

import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateAdapter from '@mui/lab/AdapterDayjs'
import PropTypes from 'prop-types'

export const AppLocalizationProvider = ({ children }) => {
  return <LocalizationProvider dateAdapter={DateAdapter}>{children}</LocalizationProvider>
}

AppLocalizationProvider.propTypes = {
  children: PropTypes.any,
}

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import styled from 'styled-components'
import { useState } from 'react'
import finlandFlag from 'assets/Finland.svg'
import swedenFlag from 'assets/Sweden.svg'
import UKflag from 'assets/United_Kingdom.svg'
import { Colors } from 'constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppTranslation } from 'i18n/hooks'

const setFlag = lang => (lang === 'fi' ? finlandFlag : lang === 'en' ? UKflag : swedenFlag)

export default function ChangeLanguage() {
  const { i18n } = useAppTranslation()
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentFlag, setCurrentFlag] = useState(setFlag(i18n.language))
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const languageChanged = (selectedLang, image) => {
    setCurrentFlag(image)
    i18n.changeLanguage(selectedLang)
    setAnchorEl(null)
  }

  return (
    <StyledDiv>
      <Button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <img src={currentFlag} style={iconStyle} alt="current language" />
        <FontAwesomeIcon icon={['far', 'chevron-down']} color={Colors.MATT_BLACK} />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={menuStyle}
        transformOrigin={menuStyle}
      >
        <MenuItem onClick={() => languageChanged('fi', finlandFlag)}>
          <img src={finlandFlag} style={iconStyle} alt="Finland flag" />
        </MenuItem>
        <MenuItem onClick={() => languageChanged('sv', swedenFlag)}>
          <img src={swedenFlag} style={iconStyle} alt="Finland flag" />
        </MenuItem>
        <MenuItem onClick={() => languageChanged('en', UKflag)}>
          <img src={UKflag} style={iconStyle} alt="Finland flag" />
        </MenuItem>
      </Menu>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50px;
  border-bottom: 1px solid ${Colors.DARK_GRAY};
`

const iconStyle = { width: 33, height: 21, marginRight: 10 }
const menuStyle = { vertical: 'top', horizontal: 'left' }

import { Button } from '@mui/material'
import { any, number } from 'prop-types'
import { useState } from 'react'
import { Bids } from '../Bids'

export const BidsCount = ({ value, data }) => {
  const [open, setOpen] = useState(false)

  const onClose = () => setOpen(false)

  return (
    <>
      <Button disabled={!value} onClick={() => setOpen(true)}>
        {value}
      </Button>
      {open && (
        <Bids onClose={onClose} tempId={data.tempId} auctionPK={data.auctionPK} description={data.description} />
      )}
    </>
  )
}

BidsCount.propTypes = {
  value: number,
  data: any,
}

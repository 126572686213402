import { yupResolver } from '@hookform/resolvers/yup'
import { Prompt } from 'Components/Shared/Prompt'
import Button from 'Components/Ui-Kits/Buttons'
import InputText from 'Components/Ui-Kits/Inputs/InputText'
import { Margin } from 'Components/Ui-Kits/Margin'
import { Colors } from 'constants/colors'
import { useAppTranslation } from 'i18n/hooks'
import { useSnackbar } from 'notistack'
import { bool, func, object, string } from 'prop-types'
import { useForm } from 'react-hook-form'
import { useAppHistory } from 'routes/dashboard'
import * as Yup from 'yup'

const tagSchrma = t =>
  Yup.object().shape({
    label: Yup.object().shape({
      en: Yup.string().required(t('required')),
      sv: Yup.string().required(t('required')),
      fi: Yup.string().required(t('required')),
    }),
  })

export const Form = ({ defaultValues, submitLabel, goBackVisible = false, onSubmitSuccess, loading = false }) => {
  const { t } = useAppTranslation()
  const {
    formState: { errors, isDirty },
    register,
    handleSubmit,
    reset,
  } = useForm({
    defaultValues,
    resolver: yupResolver(tagSchrma(t)),
  })

  const { enqueueSnackbar } = useSnackbar()

  const history = useAppHistory()

  const onSubmit = handleSubmit(async data => {
    reset(data)
    await onSubmitSuccess(data, reset)
    enqueueSnackbar(defaultValues?.id ? t('tagWasUpdated') : t('tagWasCreated'), { variant: 'success' })
  })

  return (
    <form onSubmit={onSubmit}>
      <Prompt when={isDirty} />
      <InputText
        {...register('label.en')}
        label={`${t('label')} (en)`}
        error={!!errors?.label?.en?.message}
        helperText={errors?.label?.en?.message}
        disabled={loading}
      />
      <Margin t={5} />
      <InputText
        {...register('label.fi')}
        label={`${t('label')} (fi)`}
        error={!!errors?.label?.fi?.message}
        helperText={errors?.label?.fi?.message}
        disabled={loading}
      />
      <Margin t={5} />
      <InputText
        {...register('label.sv')}
        label={`${t('label')} (sv)`}
        error={errors?.label?.sv?.message}
        helperText={errors?.label?.sv?.message}
        disabled={loading}
      />
      <Margin t={5} />
      <Button type="submit" isFetching={loading}>
        {submitLabel}
      </Button>
      {goBackVisible && (
        <Button
          type="button"
          isFetching={loading}
          color={Colors.TRANSPARENT}
          textColor={Colors.DARK_GRAY}
          onClick={() => history.push('/tag')}
        >
          {t('goBack')}
        </Button>
      )}
    </form>
  )
}

Form.propTypes = {
  defaultValues: object,
  goBackVisible: bool,
  submitLabel: string.isRequired,
  onSubmitSuccess: func.isRequired,
  selectedTagId: string,
  loading: bool,
}

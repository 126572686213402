import { useAppTranslation } from 'i18n/hooks'
import { string } from 'prop-types'
import { useMemo } from 'react'
import MuiTabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { Margin } from 'Components/Ui-Kits/Margin'
import { Table } from './Table'
import { usePublishedProductsOnce } from 'data/publishedProducts'
import { ListTable } from './ListTable'
import { useAuctionBidsOnce } from 'data/bids'
import { useProfiles } from 'data/profiles'
import { CircularProgress } from '@mui/material'
import styled from 'styled-components'
import { useQuery } from 'utils/useQuery'
import { useSetQuery } from 'utils/useSetQuery'

const tabs = Object.freeze({
  GROUPED: 'grouped',
  LIST: 'list',
})

export const Tabs = ({ auction }) => {
  const { subTab } = useQuery()
  const setQuery = useSetQuery()
  const tab = useMemo(() => {
    if (!subTab || !Object.values(tabs).includes(subTab)) {
      return tabs.GROUPED
    }

    return subTab
  }, [subTab])
  const { t } = useAppTranslation()
  const { products } = usePublishedProductsOnce(auction)
  const [bids, loading] = useAuctionBidsOnce(auction)
  const userIds = useMemo(() => bids.map(({ userId }) => userId).filter(id => id), [bids])
  const { profiles, loading: profilesLoading } = useProfiles(userIds)

  const productsWithBids = useMemo(
    () =>
      products.map(product => ({
        ...product,
        bids: bids.filter(({ itemId }) => itemId === product.id),
      })),
    [products, bids]
  )

  if (loading || profilesLoading) {
    return (
      <ProogressWrap>
        <CircularProgress />
      </ProogressWrap>
    )
  }

  return (
    <>
      <MuiTabs onChange={(e, tab) => setQuery({ subTab: tab })} value={tab}>
        <Tab label={t('grouped')} value={tabs.GROUPED} />
        <Tab label={t('list')} value={tabs.LIST} />
      </MuiTabs>
      <Margin t={5}>
        {tab === tabs.GROUPED && <Table products={productsWithBids} auction={auction} profiles={profiles} />}
        {tab === tabs.LIST && (
          <ListTable auction={auction} products={products} bids={bids} loading={loading} profiles={profiles} />
        )}
      </Margin>
    </>
  )
}

Tabs.propTypes = {
  auction: string,
}

const ProogressWrap = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

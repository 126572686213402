export const userEntity = Object.freeze({
  email: '',
  firstName: '',
  lastName: '',
  postalCode: '',
  city: '',
  country: '',
  status: null,
  role: null,
  emailNotificationAllowed: false,
  smsNotificationAllowed: false,
  marketingInformationAllowed: false,
})

export const userRoles = Object.freeze({
  SELLER: 'seller',
  ADMIN: 'admin',
  SUPER_ADMIN: 'super_admin',
})

export const userStatus = Object.freeze({
  ACTIVE: 'active',
  DISABLED: 'disabled',
  DELETED: 'deleted',
})

export const userRolesOptions = t => [
  { title: t('admin'), value: userRoles.ADMIN },
  { title: t('superAdmin'), value: userRoles.SUPER_ADMIN },
]

export const getUserRoleLabel = (role, t) => userRolesOptions(t).find(({ value }) => value === role).title

import { collection, collectionGroup, documentId, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { db } from './firebase'
import { bidTypes } from 'constants/bid'
import { useCollectionData, useCollectionDataOnce } from 'react-firebase-hooks/firestore'
import { bidConventer } from 'conventers/bidConventer'
import { useMultiQueryData } from 'hooks/useMultiQueryData'
import { productConventer } from 'conventers/productConventer'
import { chunk } from 'lodash'
import { useGetTranslated } from 'utils/useGetTranslated'
import dayjs from 'dayjs'
import { dateTimeFormat } from 'constants/index'

export function usePreBids(auctionId, productId) {
  // Store bid data to local state
  const [bids, setBids] = useState([])

  // Sync prodbiduct data
  useEffect(() => {
    const q = query(
      collection(db, `auctions/${auctionId}/products/${productId}/bids`),
      where('type', '==', bidTypes.PRE),
      orderBy('date', 'desc')
    )

    // Subscribe to data from /bids
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id, date: doc.data().date.toDate() })
        })

        // Set bids data to local state in this hook.
        setBids(data)
      },
      error => {
        console.error('Failed to sync Bid data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [auctionId, productId])

  // These functions mutate data in Firestore.

  return {
    // Bids data from Firebase
    bids,
  }
}

export function useLiveBids(auctionId, productId) {
  // Store bid data to local state
  const [bids, setBids] = useState([])

  // Sync prodbiduct data
  useEffect(() => {
    const q = query(
      collection(db, `auctions/${auctionId}/products/${productId}/bids`),
      where('type', '==', bidTypes.LIVE),
      orderBy('date', 'desc')
    )

    // Subscribe to data from /bids
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id, date: doc.data().date.toDate() })
        })

        // Set bids data to local state in this hook.
        setBids(data)
      },
      error => {
        console.error('Failed to sync Bid data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [auctionId, productId])

  // These functions mutate data in Firestore.

  return {
    // Bids data from Firebase
    bids,
  }
}

export const useWinningPreBid = (auctionId, productId) => {
  // Store bid data to local state
  const [bid, setBid] = useState(null)
  const [loading, setLoading] = useState(true)

  // Sync prodbiduct data
  useEffect(() => {
    setLoading(true)
    const q = query(
      collection(db, `auctions/${auctionId}/products/${productId}/bids`),
      where('isWinning', '==', true),
      where('type', '==', bidTypes.PRE),
      orderBy('amount', 'desc'),
      limit(1)
    )

    // Subscribe to data from /bids
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        setLoading(false)
        const [doc] = querySnapshot.docs
        if (!doc?.exists()) return setBid(null)

        setBid({ ...doc.data(), id: doc.id })
      },
      error => {
        console.error('Failed to sync Bid data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [auctionId, productId])

  // These functions mutate data in Firestore.

  return {
    // Bids data from
    loading,
    bid,
  }
}

export const useWinningLiveBid = (auctionId, productId) => {
  // Store bid data to local state
  const [bid, setBid] = useState(null)
  const [loading, setLoading] = useState(true)

  // Sync prodbiduct data
  useEffect(() => {
    setLoading(true)
    const q = query(
      collection(db, `auctions/${auctionId}/products/${productId}/bids`),
      where('isWinning', '==', true),
      where('type', '==', bidTypes.LIVE),
      orderBy('amount', 'desc'),
      limit(1)
    )

    // Subscribe to data from /bids
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        setLoading(false)
        const [doc] = querySnapshot.docs
        if (!doc?.exists()) return

        setBid({ ...doc.data(), id: doc.id })
      },
      error => {
        console.error('Failed to sync Bid data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [auctionId, productId])

  // These functions mutate data in Firestore.

  return {
    // Bids data from
    loading,
    bid,
  }
}

export const useAuctionBids = auctionId => {
  const [bids = [], loading, error] = useCollectionData(
    auctionId
      ? query(collectionGroup(db, 'bids'), where('auctionId', '==', auctionId), orderBy('date', 'desc')).withConverter(
          bidConventer
        )
      : undefined
  )

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return [bids, loading]
}

export const useAuctionBidsOnce = auctionId => {
  const [bids = [], loading, error] = useCollectionDataOnce(
    auctionId
      ? query(collectionGroup(db, 'bids'), where('auctionId', '==', auctionId), orderBy('date', 'desc')).withConverter(
          bidConventer
        )
      : undefined
  )

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return [bids, loading]
}

export const useUserAuctionBids = (auctionId, userId) => {
  const [bids, setBids] = useState([])
  const getTranslated = useGetTranslated()
  const [baseBids, bidsLoading, error] = useCollectionData(
    userId &&
      auctionId &&
      query(
        collectionGroup(db, 'bids'),
        where('auctionId', '==', auctionId),
        where('userId', '==', userId),
        orderBy('date', 'desc')
      ).withConverter(bidConventer)
  )
  const queries = useMemo(
    () =>
      chunk(baseBids?.map(bid => bid.itemId).filter((id, index, list) => list.indexOf(id) === index) || [], 10).map(
        bids => {
          return query(collection(db, `auctions/${auctionId}/products`), where(documentId(), 'in', bids)).withConverter(
            productConventer
          )
        }
      ),
    [baseBids, auctionId]
  )

  const [products, productsLoading] = useMultiQueryData(...queries)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  useEffect(() => {
    if (productsLoading || bidsLoading) return

    setBids(
      baseBids.map(bid => {
        const product = products.find(({ id }) => id === bid.itemId)
        return {
          ...bid,
          productName: product && getTranslated(product?.name),
          lotNumber: product && product?.sort + 1,
          date: dayjs(bid.date).format(dateTimeFormat),
        }
      })
    )
    setLoading(false)
  }, [productsLoading, bidsLoading, products, baseBids, getTranslated])

  return [bids, loading]
}

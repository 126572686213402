import { Grid } from '@mui/material'
import { Route, Switch, useRouteMatch } from 'react-router'
import { TagList } from './TagList'
import { Create } from './Create'
import { Edit } from './Edit'

const Tag = () => {
  const { path } = useRouteMatch()

  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={6}>
        <Switch>
          <Route exact path={path}>
            <Create />
          </Route>
          <Route exact path={`${path}/edit/:id`}>
            <Edit />
          </Route>
        </Switch>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TagList />
      </Grid>
    </Grid>
  )
}

export default Tag

import { List as MuiList } from '@mui/material'
import { useAppTranslation } from 'i18n/hooks'
import { arrayOf, object } from 'prop-types'
import React from 'react'
import { Item } from './Item'

export const List = ({ tags }) => {
  const {
    i18n: { language },
  } = useAppTranslation()

  return (
    <MuiList sx={{ width: '100%' }}>
      {tags.map(({ id, label }) => (
        <Item id={id} key={id} label={label[language]} />
      ))}
    </MuiList>
  )
}

List.propTypes = {
  tags: arrayOf(object),
}

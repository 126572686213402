import { useAuth } from 'data/auth'
import Grid from 'Components/Ui-Kits/Grid'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Colors } from 'constants/colors'
import { useAppTranslation } from 'i18n/hooks'
import { useAppHistory } from 'routes/dashboard'
import { userStatus } from 'constants/users'
import { Form } from '../Form'
import { useToast } from 'decorators/Toast'
import { useState } from 'react'

function AddUser() {
  const { t } = useAppTranslation()
  const history = useAppHistory()
  const { signupUser } = useAuth()
  const { toast } = useToast()
  const [loading, setLoading] = useState(false)

  const onSubmit = async user => {
    user.status = userStatus.ACTIVE
    setLoading(true)
    const result = await signupUser(user)
    if (!result) return
    history.push({
      pathname: `/users/show`,
    })
    toast('userWWasCreated')
    setLoading(false)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text type={TextTypes.HEADING_40} color={Colors.BLACK}>
            {t('addUser')}
          </Text>
        </Grid>
      </Grid>
      <Form onSubmit={onSubmit} loading={loading} />
    </>
  )
}

AddUser.propTypes = {}

export default AddUser

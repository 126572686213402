import { useCallback, useEffect, useState } from 'react'

export const useSessionStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    return getSessionStorageValue(initialValue, key)
  })

  useEffect(() => {
    setStoredValue(initialValue => getSessionStorageValue(initialValue, key))
  }, [key])

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = useCallback(
    value => {
      setStoredValue(storedValue => {
        try {
          // Allow value to be a function so we have same API as useState
          const valueToStore = value instanceof Function ? value(storedValue) : value
          // Save to session storage
          if (typeof window !== 'undefined') {
            window.sessionStorage.setItem(key, JSON.stringify(valueToStore))
          }
          return valueToStore
        } catch (error) {
          // A more advanced implementation would handle the error case
          console.error(error)
        }
      })
    },
    [key]
  )
  return [storedValue, setValue]
}

const getSessionStorageValue = (initialValue, key) => {
  if (typeof window === 'undefined') {
    return initialValue
  }
  try {
    // Get from session storage by key
    const item = window.sessionStorage.getItem(key)
    // Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : initialValue
  } catch (error) {
    // If error also return initialValue
    console.error(error)
    return initialValue
  }
}

import Accordion from '@mui/material/Accordion'
import BaseAccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { useAppTranslation } from 'i18n/hooks'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from 'constants/colors'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Stats } from './Stats'
import { usePublishedAuctions } from 'data/auctions'
import { useMemo, useState, useEffect } from 'react'
import { useQuery } from 'utils/useQuery'
import { Margin } from 'Components/Ui-Kits/Margin'
import Select from 'Components/Ui-Kits/Inputs/Select'
import { Grid } from '@mui/material'
import { useSetQuery } from 'utils/useSetQuery'
import { reverse } from 'lodash'
import dayjs from 'dayjs'

export const AuctionStats = () => {
  const { t } = useAppTranslation()
  const { auctions, loading } = usePublishedAuctions()
  const auctionOptions = useMemo(
    () => auctions.map(({ id, auctionId }) => ({ value: id, title: `${auctionId}` })),
    [auctions]
  )
  const { auctionId: auction } = useQuery()
  const auctionId = useMemo(() => {
    if (auction) return auction

    return (
      reverse([...auctions]).find(({ startDate }) => startDate && dayjs(startDate.toDate()).diff(dayjs()) > 0)?.id ??
      auctions[0]?.id ??
      null
    )
  }, [auction, auctions])

  const setQuery = useSetQuery()
  const onAuctionChange = e => {
    setQuery({
      auctionId: e.target.value,
    })
  }
  const [unmountOnExit, setUnmountOnExit] = useState(true)
  useEffect(() => {
    setUnmountOnExit(true)
  }, [auctionId])

  if (loading) return null

  return (
    <>
      <Margin t={5} b={5}>
        <Grid container>
          <Grid xs={12} md={6}>
            <Select
              label={t('auctions')}
              hasSearch
              displayNone={false}
              options={auctionOptions}
              value={auctionId}
              onChange={onAuctionChange}
            />
          </Grid>
        </Grid>
      </Margin>
      <Accordion
        TransitionProps={{ unmountOnExit }}
        onChange={() => {
          setUnmountOnExit(false)
        }}
      >
        <AccordionSummary expandIcon={<Icon icon={faChevronDown} />}>
          <Text type={TextTypes.HEADING_40}>{t('kpi')}</Text>
        </AccordionSummary>
        <AccordionDetails>
          <Stats auctionId={auctionId} />
        </AccordionDetails>
      </Accordion>
    </>
  )
}

const Icon = styled(FontAwesomeIcon)`
  color: ${Colors.MAROON};
  font-size: 2rem;
`
const AccordionSummary = styled(BaseAccordionSummary)`
  padding: 2rem !important;
`

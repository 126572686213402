import { Grid } from '@mui/material'

import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Colors } from 'constants/colors'
import { useAppTranslation } from 'i18n/hooks'
import { AuctionStats } from './AuctionStats'
import { MaxIncreaseRequests } from './MaxIncreaseRequests'
import { NewCustomers } from './NewCustomers'
import { OpenProducts } from './OpenProducts'
import { OpenReceptions } from './OpenReceptions'

export const General = () => {
  const { t } = useAppTranslation()

  return (
    <>
      <Text type={TextTypes.HEADING_40} color={Colors.BLACK}>
        {t('welcome')} Admin!
      </Text>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <AuctionStats />
        </Grid>
        <Grid item xs={12}>
          <NewCustomers />
        </Grid>
        <Grid item xs={12}>
          <MaxIncreaseRequests />
        </Grid>
        <Grid item xs={12}>
          <OpenReceptions />
        </Grid>
        <Grid item xs={12}>
          <OpenProducts />
        </Grid>
      </Grid>
    </>
  )
}

import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/pro-light-svg-icons'
import {
  faBoxingGlove as faBoxingGloveSolid,
  faEdit,
  faSignOut,
  faTrash,
  faPaperPlane,
  faUser as faSolidUser,
  faUserSlash,
  faCheckCircle,
  faMinusCircle,
  faCopy,
  faCreditCard,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faHomeAlt,
  faGem,
  faFileAlt,
  faGavel,
  faPlus,
  faMinus,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faUser,
  faList,
  faTag,
  faFileExcel,
  faPrint,
  faClock,
  faLaptop,
  faHand,
  faRotateLeft,
  faPause,
  faForwardStep,
  faStopwatch,
  faHourglass,
  faPlay,
  faCheck,
  faEllipsisV,
  faClose,
  faShop,
  faSearch,
} from '@fortawesome/pro-regular-svg-icons'

library.add(
  faHomeAlt,
  faBars,
  faBoxingGloveSolid,
  faGem,
  faFileAlt,
  faGavel,
  faPlus,
  faMinus,
  faEdit,
  faChevronDown,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faTimes,
  faSignOut,
  faUser,
  faTrash,
  faPaperPlane,
  faList,
  faSolidUser,
  faUserSlash,
  faCheckCircle,
  faMinusCircle,
  faTag,
  faFileExcel,
  faPrint,
  faClock,
  faLaptop,
  faHand,
  faRotateLeft,
  faPause,
  faForwardStep,
  faStopwatch,
  faHourglass,
  faPlay,
  faCheck,
  faEllipsisV,
  faClose,
  faCopy,
  faShop,
  faSearch,
  faCreditCard
)

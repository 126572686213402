import { Form } from '../Form'
import { useAppTranslation } from 'i18n/hooks'
import { Card, CardContent } from '@mui/material'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Margin } from 'Components/Ui-Kits/Margin'
import { useTag } from 'data/tag'
import { useParams } from 'react-router'
import { useState } from 'react'

export const Edit = () => {
  const { t } = useAppTranslation()

  const { id } = useParams()
  const [loading, setLoading] = useState(false)

  const { setTag, tag } = useTag(id)

  const onSubmit = async data => {
    setLoading(true)
    await setTag(data, id)
    setLoading(false)
  }

  return (
    <Card>
      <CardContent>
        <Text type={TextTypes.HEADING_20}>{t('editTag')}</Text>
        <Margin t={10} />
        {tag.id && (
          <Form
            defaultValues={tag}
            submitLabel={t('update')}
            onSubmitSuccess={onSubmit}
            goBackVisible
            selectedTagId={id}
            loading={loading}
          />
        )}
      </CardContent>
    </Card>
  )
}

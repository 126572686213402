import { IconButton, TableCell, TableRow, Tooltip, Link as Href } from '@mui/material'
import { func, string, number, array } from 'prop-types'
import dayjs from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppTranslation } from 'i18n/hooks'
import { Colors } from 'constants/colors'
import { EditModal } from './EditModal'
import ConfirmDialog from 'Components/Shared/ConfirmDialog'
import { deleteWinningBid } from 'cloudFunctions'
import { Link } from 'react-router-dom'
import { bidTypes, getBidderTypeLabel } from 'constants/bid'
import Text from 'Components/Ui-Kits/Text'
import styled from 'styled-components'

export const Row = ({ id, name, sort, auctionId, setItemId, allBids, profiles }) => {
  const preBids = useMemo(() => allBids.filter(({ type }) => type === bidTypes.PRE), [allBids])
  const liveBids = useMemo(() => allBids.filter(({ type }) => type === bidTypes.LIVE), [allBids])
  const postBids = useMemo(() => allBids.filter(({ type }) => type === 'post'), [allBids])
  const [full, setFull] = useState(false)
  const { t } = useAppTranslation()
  const [currentBid, setCurrentBid] = useState(null)
  const [bidToDelete, setBidToDelete] = useState(null)

  const { bid, bids } = useMemo(() => {
    const bid =
      postBids.find(({ isWinning }) => isWinning) ||
      liveBids.find(({ isWinning }) => isWinning) ||
      preBids.find(({ isWinning }) => isWinning)

    const bids = [
      ...postBids.filter(({ isWinning }) => !isWinning),
      ...liveBids.filter(({ isWinning }) => !isWinning),
      ...preBids.filter(({ isWinning }) => !isWinning || liveBids.length),
    ]

    return { bid, bids: full ? bids : bids.slice(0, 1) }
  }, [preBids, liveBids, postBids, full])

  const deleteBid = async () => {
    await deleteWinningBid({ bidId: bidToDelete.id, auctionId, itemId: bidToDelete.itemId })
    setBidToDelete(null)
  }

  const getProfile = useCallback(id => profiles.find(profile => profile.id === id), [profiles])

  const getUserName = useCallback(
    id => {
      const profile = getProfile(id)

      return profile?.company || [profile?.firstName, profile?.lastName].filter(item => item).join(' ')
    },
    [getProfile]
  )

  return (
    <>
      {currentBid && (
        <EditModal
          productId={id}
          auctionId={auctionId}
          bidId={currentBid.id}
          amount={currentBid.amount}
          userName={[getProfile(currentBid.userId)?.firstName, getProfile(currentBid.userId)?.lastName]
            .filter(item => item)
            .join(' ')}
          open={!!currentBid}
          onClose={() => setCurrentBid(null)}
        />
      )}
      <ConfirmDialog
        onClose={() => setBidToDelete(null)}
        onConfirm={deleteBid}
        open={!!bidToDelete}
        message={t('areYouSure')}
      />
      <TableRow>
        <TableCell sx={{ borderBottom: bids.length ? 0 : undefined, backgroundColor: Colors.DARK_GRAY }}>
          <Href href={`/product/edit/${id}`} target="_blank">
            {sort + 1}
          </Href>
        </TableCell>
        <TableCell sx={{ borderBottom: bids.length ? 0 : undefined, backgroundColor: Colors.DARK_GRAY }}>
          {name}
          <Tooltip title={t('addBid')}>
            <IconButton size="small" onClick={() => setItemId(id)}>
              <FontAwesomeIcon icon={['far', 'plus']} color={Colors.MAROON} />
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell sx={{ borderBottom: bids.length ? 0 : undefined, backgroundColor: Colors.DARK_GRAY }}>
          {liveBids.length + preBids.length}
        </TableCell>
        <TableCell sx={{ borderBottom: bids.length ? 0 : undefined, backgroundColor: Colors.DARK_GRAY }}>
          {bid?.date && dayjs(bid.date).format('DD.MM.YYYY HH:mm')}
        </TableCell>
        <TableCell sx={{ borderBottom: bids.length ? 0 : undefined, backgroundColor: Colors.DARK_GRAY }}>
          <StyledText del={bid?.amount === 0}>{bid?.isHolmasto ? 'Holmasto' : getUserName(bid?.userId)}</StyledText>
        </TableCell>
        <TableCell sx={{ borderBottom: bids.length ? 0 : undefined, backgroundColor: Colors.DARK_GRAY }}>
          <Link to={`/clients/${bid?.userId}/view`} target="_blank">
            {getProfile(bid?.userId)?.holmastoId}
          </Link>
        </TableCell>
        <TableCell sx={{ borderBottom: bids.length ? 0 : undefined, backgroundColor: Colors.DARK_GRAY }}>
          {bid?.amount}
        </TableCell>
        <TableCell sx={{ borderBottom: bids.length ? 0 : undefined, backgroundColor: Colors.DARK_GRAY }}>
          {[bid?.type, getBidderTypeLabel(bid?.biddedBy, t)].filter(item => item).join(' - ')}
        </TableCell>
        <TableCell sx={{ borderBottom: bids.length ? 0 : undefined, backgroundColor: Colors.DARK_GRAY }}>
          {!!bid && (
            <>
              <Tooltip title={t('edit')}>
                <IconButton size="small" onClick={() => setCurrentBid(bid)}>
                  <FontAwesomeIcon icon={['fas', 'pen-to-square']} color={Colors.MAROON} />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('delete')}>
                <IconButton onClick={() => setBidToDelete(bid)} size="small">
                  <FontAwesomeIcon icon={['fas', 'trash']} color={Colors.MAROON} />
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
        <TableCell sx={{ borderBottom: bids.length ? 0 : undefined, backgroundColor: Colors.DARK_GRAY }}>
          {preBids.length + liveBids.length > 2 && (
            <Tooltip title={t(full ? 'less' : 'more')}>
              <IconButton size="small" onClick={() => setFull(full => !full)}>
                <FontAwesomeIcon icon={['far', full ? 'minus' : 'plus']} color={Colors.MAROON} />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>
      </TableRow>
      {bids.map((bid, index, list) => (
        <TableRow key={bid.id}>
          <TableCell sx={{ borderBottom: index < list.length - 1 ? 0 : undefined }}></TableCell>
          <TableCell sx={{ borderBottom: index < list.length - 1 ? 0 : undefined }}></TableCell>
          <TableCell sx={{ borderBottom: index < list.length - 1 ? 0 : undefined }}></TableCell>
          <TableCell sx={{ borderBottom: index < list.length - 1 ? 0 : undefined }}>
            {bid?.date && dayjs(bid.date).format('DD.MM.YYYY HH:mm')}
          </TableCell>
          <TableCell sx={{ borderBottom: index < list.length - 1 ? 0 : undefined }}>
            <StyledText del={bid?.amount === 0}>{bid?.isHolmasto ? 'Holmasto' : getUserName(bid?.userId)}</StyledText>
          </TableCell>
          <TableCell sx={{ borderBottom: index < list.length - 1 ? 0 : undefined }}>
            <Link to={`/clients/${bid?.userId}/view`}>{getProfile(bid?.userId)?.holmastoId}</Link>
          </TableCell>
          <TableCell sx={{ borderBottom: index < list.length - 1 ? 0 : undefined }}>{bid?.amount}</TableCell>
          <TableCell sx={{ borderBottom: index < list.length - 1 ? 0 : undefined }}>
            {[bid?.type, getBidderTypeLabel(bid?.biddedBy, t)].filter(item => item).join(' - ')}
          </TableCell>
          <TableCell colSpan={2} sx={{ borderBottom: index < list.length - 1 ? 0 : undefined }}>
            {bid.isWinning && (
              <>
                <Tooltip title={t('edit')}>
                  <IconButton size="small" onClick={() => setCurrentBid(bid)}>
                    <FontAwesomeIcon icon={['fas', 'pen-to-square']} color={Colors.MAROON} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('delete')}>
                  <IconButton onClick={() => setBidToDelete(bid)} size="small">
                    <FontAwesomeIcon icon={['fas', 'trash']} color={Colors.MAROON} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}

Row.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  sort: number.isRequired,
  auctionId: string.isRequired,
  setItemId: func,
  allBids: array.isRequired,
  profiles: array.isRequired,
}

const StyledText = styled(Text)`
  ${({ del }) => del && 'text-decoration: line-through;'}
`

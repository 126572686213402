import styled from 'styled-components'
import { spacing } from 'theme/spacing'

export const Margin = styled.div`
  ${({ t }) => `margin-top: ${spacing(t) ?? 'inherit'}`};
  ${({ b }) => `margin-bottom: ${spacing(b) ?? 'inherit'}`};
  ${({ l }) => `margin-left: ${spacing(l) ?? 'inherit'}`};
  ${({ r }) => `margin-right: ${spacing(r) ?? 'inherit'}`};
  ${({ display }) => `display: ${display ? display : 'block'}`};
`

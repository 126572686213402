import { faCopy } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton } from '@mui/material'
import { Colors } from 'constants/colors'
import { useAppTranslation } from 'i18n/hooks'
import { useSnackbar } from 'notistack'
import { string } from 'prop-types'
import { useRef } from 'react'
import styled from 'styled-components'

export const Copy = ({ value }) => {
  const ref = useRef(null)
  const { t } = useAppTranslation()

  const { enqueueSnackbar } = useSnackbar()

  const trimmedValue = value?.replace(/(\t|\s)+/g, ' ') ?? ''

  const onClick = e => {
    e.preventDefault()
    const element = ref.current
    element.select()
    element.setSelectionRange(0, 99999)
    enqueueSnackbar(t('itemWasCompied'), { variant: 'success' })
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(trimmedValue)
      return
    }

    document.execCommand('copy')
  }

  return (
    <>
      <IconButton onClick={onClick}>
        <FontAwesomeIcon icon={faCopy} color={Colors.MAROON} />
      </IconButton>
      <br />
      <HiddenTextarea defaultValue={trimmedValue} ref={ref} />
    </>
  )
}

Copy.propTypes = {
  value: string,
}

const HiddenTextarea = styled.textarea`
  width: 0;
  height: 0;
`

import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Colors } from 'constants/colors'
import { Card, CardContent, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { Margin } from 'Components/Ui-Kits/Margin'
import styled from 'styled-components'

function InfoBox({ data, header, className }) {
  return (
    <StyledCard className={className}>
      <CardContent>
        <Margin b={9}>
          <Text type={TextTypes.HEADING_20} color={Colors.BLACK}>
            {header}
          </Text>
        </Margin>
        {data.map(({ label, value }, index) => (
          <Margin b={5} key={index}>
            <Grid container justifyContent="space-between">
              <Grid item flex={1}>
                <Text type={TextTypes.BODY_16} color={Colors.BLACK}>
                  {label}
                </Text>
              </Grid>
              <Grid item xs="auto">
                {value}
              </Grid>
            </Grid>
          </Margin>
        ))}
      </CardContent>
    </StyledCard>
  )
}

export default InfoBox

InfoBox.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  header: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
}

const StyledCard = styled(Card)`
  margin: 0 !important;
  min-height: 100%;
`

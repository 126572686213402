import { Card, CardContent, Grid } from '@mui/material'
import Select from 'Components/Ui-Kits/Inputs/Select'
import { Margin } from 'Components/Ui-Kits/Margin'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { useAuctions } from 'data/auctions'
import { useAppTranslation } from 'i18n/hooks'
import { getDefaultAuction } from 'utils/getDefaultAuction'
import { useQuery } from 'utils/useQuery'
import { useSetQuery } from 'utils/useSetQuery'
import { Tabs } from './Tabs'

export const Bids = () => {
  const { auctions, loading } = useAuctions()
  const { auction = getDefaultAuction(auctions) } = useQuery()
  const setQuery = useSetQuery()
  const { t } = useAppTranslation()

  if (loading) return null

  return (
    <Card>
      <CardContent>
        <Text type={TextTypes.HEADING_20}>{t('bids')}</Text>
        <Margin b={10} />
        <Grid container>
          <Grid item sm={6}>
            <Select
              options={auctions.map(({ id, auctionId }) => ({ value: id, title: auctionId }))}
              value={auction}
              label={t('auction')}
              onChange={e => setQuery({ auction: e.target.value })}
              id="auctionList"
            />
          </Grid>
          <Grid item sm={12}>
            {auction && <Tabs auction={auction} />}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

import styled from 'styled-components'
import { Colors } from '../../../../constants/colors'

export const TextHeading40 = styled.div`
  font: normal normal 500 40px/36px kepler-std;
  letter-spacing: 0;
  color: ${({ color = Colors.BLACK }) => color};
`

export const TextHeading34 = styled.div`
  font: normal normal 500 34px/40px kepler-std;
  letter-spacing: 0;
  color: ${({ color = Colors.BLACK }) => color};
`

export const TextHeading20 = styled.div`
  font: normal normal bold 20px/24px atten-new;
  letter-spacing: 1px;
  color: ${({ color = Colors.BLACK }) => color};
`
export const TextBody20 = styled.div`
  font: normal normal 500 20px/24px atten-new;
  letter-spacing: 0;
  color: ${({ color = Colors.DEEP_FOREST_BROWN }) => color};
`
export const TextBody16 = styled.div`
  font: normal normal 500 16px/20px atten-new;
  letter-spacing: 0;
  color: ${({ color = Colors.MATT_BLACK }) => color};
`
export const TextBody13 = styled.div`
  font: normal normal bold 13px/24px atten-new;
  letter-spacing: 0;
  color: ${({ color = Colors.MATT_BLACK }) => color};
`

import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes, { bool } from 'prop-types'
import { useAppTranslation } from 'i18n/hooks'

export default function ConfirmDialog({ open, onClose, message, onConfirm, loading }) {
  const { t } = useAppTranslation()

  return (
    <div>
      <Dialog open={open} fullWidth={true} maxWidth={'xs'}>
        <DialogTitle>{message}</DialogTitle>
        <DialogActions>
          <Button onClick={onClose} autoFocus disabled={loading}>
            {t('cancel')}
          </Button>
          <Button onClick={onConfirm} color="success" disabled={loading}>
            {t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: bool,
}

import { ProductsTable } from 'Components/Shared/ProductsTable'
import { useAssignedProducts } from 'data/products'
import { useAppTranslation } from 'i18n/hooks'
import { string } from 'prop-types'

export const AssignedProducts = ({ email }) => {
  const { products = [], loading } = useAssignedProducts(email)
  const { t } = useAppTranslation()

  return <ProductsTable products={products} loading={loading} title={t('openItemAcceptancesWhereIAmAssigned')} />
}

AssignedProducts.propTypes = {
  email: string,
}

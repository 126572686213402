import { Card, CardContent } from '@mui/material'
import { AgGridColumn } from 'ag-grid-react'
import { AgLink } from 'Components/Shared/AgLink'
import { GridStatus } from 'Components/Shared/Status'
import { Margin } from 'Components/Ui-Kits/Margin'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { dateTimeFormat } from 'constants/index'
import { Colors } from 'constants/colors'
import { getPuposeOfSaleLabel, getReceptionStatusColor } from 'constants/receptions'
import dayjs from 'dayjs'
import { useAppTranslation } from 'i18n/hooks'
import { array, bool, string } from 'prop-types'
import { useEffect, useState } from 'react'
import { GridWrap } from '../GridWrap'

export const ReceptionsTable = ({ receptions, loading, title }) => {
  const [grid, setGrid] = useState(null)
  const { t } = useAppTranslation()

  useEffect(() => {
    if (!grid || loading) return
    grid.api.setRowData(
      receptions.map(reception => {
        let arr = [getPuposeOfSaleLabel(reception.purposeOfSale, t)]

        if (reception.mightBuyProducts) {
          arr = [t('toSell'), ...arr]
        }
        if (reception.hasProductsSoldOnBehalf) {
          arr = [t('toAuction'), ...arr]
        }
        return {
          ...reception,
          daysLeft: dayjs(reception.createdAt)
            .add(reception.deadline || 0, 'day')
            .diff(dayjs(), 'day'),
          createdAt: reception.createdAt && dayjs(reception.createdAt).format(dateTimeFormat),
          purposeOfSale: arr.filter(item => item).join(', '),
        }
      })
    )
  }, [receptions, loading, grid, t])

  return (
    <Card>
      <CardContent>
        <Text type={TextTypes.HEADING_34}>{title}</Text>
        <Margin b={10} />
        <GridWrap
          height={400}
          gridOptions={{ headerHeight: 50 }}
          defaultColDef={{
            sortable: true,
            filter: true,
            floatingFilter: true,
            resizable: true,
          }}
          frameworkComponents={{
            status: GridStatus,
            link: AgLink,
            deadline({ value }) {
              return (
                <Text as="span" type={TextTypes.BODY_14} color={value <= 3 ? Colors.MAROON : Colors.BLACK}>
                  {value}
                </Text>
              )
            },
          }}
          rowHeight={60}
          onGridReady={setGrid}
        >
          <AgGridColumn
            headerClass={'grid_header'}
            field="serialNumber"
            headerName={t('id')}
            cellRenderer="link"
            cellRendererParams={{
              to: '/reception/edit/{id}',
            }}
          />
          <AgGridColumn headerClass={'grid_header'} field="sellerName" headerName={t('sellerName')} />
          <AgGridColumn
            headerClass={'grid_header'}
            field="holmastoId"
            headerName={t('holmastoId')}
            cellRenderer="link"
            cellRendererParams={{
              to: '/clients/{sellerId}/view',
            }}
          />
          <AgGridColumn headerClass={'grid_header'} field="personnelAssigned" headerName={t('personnelAssigned')} />
          <AgGridColumn headerClass={'grid_header'} field="createdAt" headerName={t('date')} />
          <AgGridColumn
            headerClass={'grid_header'}
            field="daysLeft"
            headerName={t('daysBeforeDeadline')}
            cellRenderer="deadline"
          />
          <AgGridColumn headerClass={'grid_header'} field="purposeOfSale" headerName={t('purposeOfSale')} />
          <AgGridColumn
            headerClass={'grid_header'}
            headerName={t('status')}
            cellRenderer="status"
            field="status"
            cellRendererParams={{
              getColor: getReceptionStatusColor,
            }}
          />
        </GridWrap>
      </CardContent>
    </Card>
  )
}

ReceptionsTable.propTypes = {
  receptions: array.isRequired,
  loading: bool.isRequired,
  title: string.isRequired,
}

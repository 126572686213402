import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import PropTypes from 'prop-types'
import { useAppTranslation } from 'i18n/hooks'

export default function DeleteConfirmationDialog({ open, onClose }) {
  const { t } = useAppTranslation()

  const handleClose = action => onClose(action)

  return (
    <div>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'xs'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('areYouSure')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t('thisActionCanNotBeUndone')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose('cancel')} autoFocus>
            {t('cancel')}
          </Button>
          <Button onClick={() => handleClose('delete')} color={'error'}>
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

DeleteConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

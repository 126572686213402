import { useCallback, useEffect, useRef } from 'react'
import { useQuery } from 'utils/useQuery'
import { useSetQuery } from 'utils/useSetQuery'

export const useSetTableFilters = () => {
  const { table } = useQuery()
  const setQuery = useSetQuery()
  const prevInput = useRef(null)

  useEffect(() => {
    const focusEvent = e => {
      if (e.target.classList.contains('ag-header-cell') && prevInput.current) {
        return prevInput.current.focus()
      }

      prevInput.current = e.target
    }

    document.body.addEventListener('focus', focusEvent, true)

    return () => document.body.removeEventListener('focus', focusEvent)
  }, [])

  const onRowDataChanged = useCallback(event => table && event.api.setFilterModel(JSON.parse(table)), [table])
  const onFilterChanged = useCallback(
    event => setQuery({ table: JSON.stringify(event.api.getFilterModel()) }, true, true),
    [setQuery]
  )

  return { onRowDataChanged, onFilterChanged }
}

import React from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUpdateProfile } from 'data/profiles'

export const Edit = ({ value }) => {
  const { t } = useTranslation()
  const [updateProfile, loading] = useUpdateProfile(value)

  return (
    <ButtonGroup>
      <Button
        disabled={loading}
        variant="contained"
        onClick={() => {
          const note = prompt(t('note'))
          updateProfile({ maxBiddingLevel: -1, maxBiddingLevelIncreaseRequested: null, note })
        }}
      >
        {t('accept')}
      </Button>
      <Button
        disabled={loading}
        variant="contained"
        color="error"
        onClick={() => {
          const note = prompt(t('note'))
          updateProfile({ maxBiddingLevelIncreaseRequested: null, note })
        }}
      >
        {t('decline')}
      </Button>
    </ButtonGroup>
  )
}

Edit.propTypes = {
  value: PropTypes.string,
}

const { Snackbar, Alert } = require('@mui/material')
const { oneOfType, arrayOf, node } = require('prop-types')
const { useContext } = require('react')
const { useCallback } = require('react')
const { useState } = require('react')
const { createContext } = require('react')

const ToastContext = createContext()

export const ToastProvider = ({ children }) => {
  const [message, setMessage] = useState()
  const [type, setType] = useState()
  const [open, setOpen] = useState(false)

  const toast = useCallback((message, type = 'success') => {
    setMessage(message)
    setType(type)
    setOpen(true)
  }, [])

  return (
    <ToastContext.Provider value={{ setMessage, setOpen, toast }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={() => setOpen(false)} severity={type} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext)

ToastProvider.propTypes = {
  children: oneOfType([arrayOf(node), node]),
}

import { omit } from 'lodash'

export const profileConventer = {
  toFirestore(data) {
    return omit(data, ['id'])
  },
  fromFirestore(doc, options) {
    const data = doc.data(options)

    return {
      ...data,
      id: doc.id,
      createdAt: (data.createdAt?.toDate && data.createdAt?.toDate()) || null,
    }
  },
}

import { ProductsTable } from 'Components/Shared/ProductsTable'
import { useOpenProducts } from 'data/products'
import { useAppTranslation } from 'i18n/hooks'

export const OpenProducts = () => {
  const { products = [], loading } = useOpenProducts()
  const { t } = useAppTranslation()

  return <ProductsTable products={products} loading={loading} title={t('openItems')} />
}

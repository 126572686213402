import Grid from 'Components/Ui-Kits/Grid'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Colors } from 'constants/colors'
import { useAppTranslation } from 'i18n/hooks'
import { Form } from '../../Form'
import { useToast } from 'decorators/Toast'
import { useUsers } from 'data/users'
import { useState } from 'react'
import { object } from 'prop-types'

export const General = ({ authUser }) => {
  const { t } = useAppTranslation()

  const { user, editUser } = useUsers(authUser.uid)
  const { toast } = useToast()
  const [loading, setLoading] = useState()

  const onSubmit = async data => {
    delete data.email
    delete data.createdAt
    delete data.role

    setLoading(true)
    await editUser(data, user.id)
    toast(t('profileWasUpdated'))
    setLoading(false)
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text type={TextTypes.HEADING_40} color={Colors.BLACK}>
            {t('profile')}
          </Text>
        </Grid>
      </Grid>
      {user?.id && <Form onSubmit={onSubmit} defaultValues={user} loading={loading} sameUser={true} />}
    </>
  )
}

General.propTypes = {
  authUser: object.isRequired,
}

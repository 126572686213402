import { useEffect, useState } from 'react'
import { isEqual } from 'lodash'

export const useChangedQueries = (...args) => {
  const [queries, setQueryes] = useState(args)

  useEffect(() => {
    if (isEqual(args, queries)) return

    setQueryes(args)
  }, [args, queries])

  return queries
}

import { flatten } from 'lodash'
export const receptionStatus = Object.freeze([
  { title: 'Active', value: 'Active' },
  { title: 'Inactive', value: 'Inactive' },
])

export const receptionStatusValues = Object.freeze({
  CREATED: 'created',
  READY_TO_SEND: 'ready_to_send',
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  COMPENSATED: 'compensated',
})

export const receptionStatusOptions = t => [
  { title: t('created'), value: receptionStatusValues.CREATED },
  { title: t('readyToSend'), value: receptionStatusValues.READY_TO_SEND },
  { title: t('sentWaitingForAnswer'), value: receptionStatusValues.PENDING },
  { title: t('accepted'), value: receptionStatusValues.APPROVED },
  { title: t('rejected'), value: receptionStatusValues.REJECTED },
  { title: t('compensated'), value: receptionStatusValues.COMPENSATED },
]

export const getReceptionStatusLabel = (status, t) =>
  receptionStatusOptions(t).find(({ value }) => value === status)?.title

export const purposeOfSaleValues = Object.freeze({
  EVALUATION: 'evaluation',
  ESTATE_INVENTORY: 'estate_inventory',
  INSURANCE_EVALUATION: 'insurance_evaluation',
  GEMMOLOGICAL_EXAMINATION: 'gemmological_examination',
  SALES_ORDER: 'sales_order',
  OTHER: 'Other',
})

export const purposeOfSaleOptions = t => [
  {
    title: t('evaluation'),
    value: purposeOfSaleValues.EVALUATION,
  },
  {
    title: t('salesOrder'),
    value: purposeOfSaleValues.SALES_ORDER,
  },
  {
    title: t('estateInventory'),
    value: purposeOfSaleValues.ESTATE_INVENTORY,
  },
  {
    title: t('insuranceEvaluation'),
    value: purposeOfSaleValues.INSURANCE_EVALUATION,
  },
  {
    title: t('gemmologicalExamination'),
    value: purposeOfSaleValues.GEMMOLOGICAL_EXAMINATION,
  },
  {
    title: t('other'),
    value: purposeOfSaleValues.OTHER,
  },
]

export const getPuposeOfSaleLabel = (value, t) =>
  flatten([value])
    .map(value => purposeOfSaleOptions(t).find(option => option.value === value)?.title)
    .join(', ')

export const receptionStateValues = Object.freeze({
  OPEN: 'open',
  CLOSED: 'closed',
})

export const receptionStateOptions = t => [
  { title: t('open'), value: receptionStateValues.OPEN },
  { title: t('archived'), value: receptionStateValues.CLOSED },
]

export const receptionProductStatusValues = Object.freeze({ ACTIVE: 'active', INACTIVE: 'inactive' })

export const getReceptionStatusColor = value => {
  switch (value) {
    case receptionStatusValues.PENDING:
      return 'warning'
    case receptionStatusValues.APPROVED:
      return 'success'
    case receptionStatusValues.REJECTED:
      return 'error'
    default:
      return 'info'
  }
}

export const receptionProductStatusOptions = t => [
  { title: t('active'), value: receptionProductStatusValues.ACTIVE },
  { title: t('inactive'), value: receptionProductStatusValues.INACTIVE },
]

export const receptionEntity = Object.freeze({
  seller: '',
  receptionDate: '',
  personnelAssigned: null,
  description: '',
  featuredImage: null,
  images: [],
  status: '',
  proposedPrice: null,
  proposalText: '',
  mightBuyProducts: false,
  hasProductsSoldOnBehalf: false,
  vatPercent: null,
  deadline: 14,
  state: receptionStateValues.OPEN,
})

export const extractCategory = (data, key) => (data ? data.find(item => item.taxonomy === key) : {})

export const makeSelectOptionDS = (data, lang) => {
  if (!data?.terms) return []
  return data.terms.map(item => ({ title: item.name[lang] ?? item.name.fi, value: item.term_id }))
}

export const auctionCategoriesMap = (categories, parentId = null, currentDepth = 0, maxDepth = null) =>
  categories
    .filter(category => category.parentId === parentId)
    .map(item => {
      if (maxDepth !== null && currentDepth === maxDepth) {
        return { ...item, label: item.label }
      }

      return {
        ...item,
        label: item.label,
        children: auctionCategoriesMap(categories, item.id, currentDepth + 1, maxDepth),
      }
    })

export const auctionCategoriesMapOption = (categories, lang, maxDepth = null, parentId = null, currentDepth = 0) =>
  categories
    .filter(category => category.parentId === parentId)
    .map(item => {
      if (maxDepth !== null && currentDepth === maxDepth) {
        return { label: item.label[lang], value: item.id }
      }

      return {
        value: item.id,
        label: item.label[lang],
        children: auctionCategoriesMapOption(categories, lang, maxDepth, item.id, currentDepth + 1),
      }
    })

export const categoriesMapOption = (categories, lang, maxDepth = null, parentId = 0, currentDepth = 0) =>
  categories
    .filter(category => category.parent_id === parentId)
    .map(item => {
      const label = item.name[lang] || Object.values(item.name)[0]
      const value = item.term_id
      if (maxDepth !== null && currentDepth === maxDepth) {
        return { label, value }
      }

      return {
        value,
        label,
        children: categoriesMapOption(categories, lang, maxDepth, value, currentDepth + 1),
      }
    })

export const getAuctionCategoryParentIds = (categories, categoryId) => {
  const category = categories.find(({ id }) => id === categoryId)
  if (!categoryId || !category?.parentId) {
    return []
  }

  return [category.parentId, ...getAuctionCategoryParentIds(categories, category.parentId)]
}

export const getAuctionCategoryChildrenIds = (categories, categoryId) => {
  return categories
    .filter(({ parentId }) => parentId === categoryId)
    .reduce((result, { id }) => {
      result = [...result, id, ...getAuctionCategoryChildrenIds(categories, id)]

      return result
    }, [])
}

export const getCategoryParentIds = (categories, categoryId) => {
  const category = categories.find(({ term_id }) => term_id === categoryId)
  if (!categoryId || !category?.parent_id) {
    return []
  }

  return [category.parent_id, ...getCategoryParentIds(categories, category.parent_id)]
}

export const getCategoryChildrenIds = (categories, categoryId) => {
  return categories
    .filter(({ parent_id }) => parent_id === categoryId)
    .reduce((result, { term_id }) => {
      result = [...result, term_id, ...getCategoryChildrenIds(categories, term_id)]

      return result
    }, [])
}

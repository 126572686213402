import { Checkbox, FormControlLabel, Grid, debounce } from '@mui/material'
import { Copy } from 'Components/Copy'
import { GridWrap } from 'Components/Shared/GridWrap'
import InputText from 'Components/Ui-Kits/Inputs/InputText'
import { Margin } from 'Components/Ui-Kits/Margin'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { AgGridColumn } from 'ag-grid-react'
import { historyProductIndex } from 'api/algolia'
import { useDivHeight } from 'hooks/useDivHeight'
import { useAppTranslation } from 'i18n/hooks'
import { useCallback, useEffect, useRef, useState } from 'react'
import { BidsCount } from './BidsCount'
import { Image } from './Image/Image'

export const HistoryItems = () => {
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const { t } = useAppTranslation()
  const loadingRef = useRef(loading)
  loadingRef.current = loading
  const [query, setQuery] = useState('')
  const { ref: tableRef, height } = useDivHeight(items)
  const [gridApi, setGridApi] = useState(null)
  const noMorePages = useRef(false)
  const [fullTable, setFullTable] = useState(false)

  const onQueryUpdate = useCallback(e => {
    setQuery(e.target.value)
    setPage(0)
    setItems([])
    noMorePages.current = false
  }, [])

  const onGridReady = params => {
    setGridApi(params.api)
  }

  useEffect(() => {
    setLoading(true)
    historyProductIndex
      .search(query, {
        hitsPerPage: 50,
        page,
        typoTolerance: false,
        filters: `auctionId < 700`, // Dont get 700 auction items
      })
      .then(result => {
        setItems(items => [...items, ...result.hits])
        noMorePages.current = page >= result.nbPages
      })
      .finally(() => setLoading(false))
  }, [page, query])

  const onGridScrolled = () => {
    if (loading || noMorePages.current) return

    if (gridApi.getLastDisplayedRow() < items.length - 1) {
      return
    }

    setPage(page => page + 1)
  }

  useEffect(() => {
    if (!gridApi) return
    gridApi.setRowData(items)
  }, [gridApi, items])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Text type={TextTypes.HEADING_34}>{t('historyItems')}</Text>
      </Grid>
      <Grid item xs={12}>
        <Margin b={10}>
          <Grid container>
            <Grid xs={6} item>
              <InputText label={t('filterItemDescription')} onChange={debounce(onQueryUpdate, 500)} />
            </Grid>
          </Grid>
        </Margin>
        <div>
          <FormControlLabel
            control={<Checkbox onChange={() => setFullTable(fullTable => !fullTable)} checked={fullTable} />}
            label={t('showFullTable')}
          />
        </div>
        <GridWrap
          gridOptions={{ headerHeight: 50 }}
          defaultColDef={{
            sortable: false,
            filter: false,
            floatingFilter: false,
            resizable: true,
          }}
          suppressScrollOnNewData
          ref={tableRef}
          height={height}
          onBodyScroll={onGridScrolled}
          onGridReady={onGridReady}
          frameworkComponents={{ copy: Copy, bidsCount: BidsCount, image: Image }}
        >
          <AgGridColumn
            headerClass={'grid_header'}
            headerName={t('copy')}
            field="description"
            sortable={true}
            cellRenderer="copy"
          />
          <AgGridColumn headerClass={'grid_header'} headerName={t('description')} field="description" sortable={true} />
          <AgGridColumn headerClass={'grid_header'} headerName={t('startPrice')} field="startPrice" sortable={true} />
          <AgGridColumn headerClass={'grid_header'} headerName={t('wonPrice')} field="wonPrice" sortable={true} />
          <AgGridColumn headerClass={'grid_header'} headerName={t('auctionId')} field="auctionId" sortable={true} />
          <AgGridColumn headerClass={'grid_header'} headerName={t('date')} field="auctionStartDate" sortable={true} />

          <AgGridColumn
            headerClass={'grid_header'}
            headerName={t('images')}
            field="id"
            sortable={true}
            hide={!fullTable}
            cellRenderer="image"
          />
          <AgGridColumn
            headerClass={'grid_header'}
            headerName={t('tempId')}
            field="tempId"
            sortable={true}
            hide={!fullTable}
          />
          <AgGridColumn
            headerClass={'grid_header'}
            headerName={t('bids')}
            field="bidsCount"
            sortable={true}
            hide={!fullTable}
            cellRenderer="bidsCount"
          />
          <AgGridColumn
            headerClass={'grid_header'}
            headerName={t('winnerName')}
            field="winnerName"
            sortable={true}
            hide={!fullTable}
          />
        </GridWrap>
      </Grid>
    </Grid>
  )
}

import { bool, any, string, oneOf, func } from 'prop-types'
import { TextField } from '@mui/material'
import { forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'

const InputText = forwardRef(
  ({ fullWidth = true, validationFn = () => {}, multiline, helperText, error, ...rest }, ref) => {
    const innerRef = useRef(null)

    useImperativeHandle(ref, () => innerRef.current)

    useEffect(() => {
      if (rest.type !== 'number') return

      const wheelEventHandler = () => {
        innerRef.current.blur()
      }

      document.addEventListener('wheel', wheelEventHandler)

      return () => document.removeEventListener('wheel', wheelEventHandler)
    }, [rest.type])

    return (
      <>
        <StyledTextField
          {...rest}
          inputRef={innerRef}
          className={'background_white'}
          {...validationFn()}
          fullWidth={fullWidth}
          multiline={multiline}
          minRows={4}
          error={error}
          InputLabelProps={{ shrink: true }}
        />
        {!!helperText && (
          <Text type={TextTypes.BODY_13} color={error ? Colors.CRIMSON : Colors.BLACK}>
            {helperText}
          </Text>
        )}
      </>
    )
  }
)

export default InputText
InputText.displayName = 'InputText'
InputText.propTypes = {
  placeholder: string,
  disabled: bool,
  label: string,
  defaultValue: any,
  type: string,
  value: any,
  fullWidth: bool,
  size: oneOf(['medium', 'small', string]),
  variant: oneOf(['filled', 'outlined', 'standard']),
  required: bool,
  multiline: bool,
  onChange: func,
  onPressEnter: func,
  validationFn: func,
  helperText: string,
  error: bool,
}

const StyledTextField = styled(TextField)`
  .Mui-error {
    color: ${Colors.CRIMSON}!important;
    background-color: ${Colors.TRANSPARENT};
  }
`

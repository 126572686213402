import Menu from 'Components/Menu'
import Header from 'Components/Header'
import React, { useState } from 'react'
import { browserHistory } from './browserHistory'
import DashboardRoutes from './routes/dashboard'
import { Router } from 'react-router'
import { AppLocalizationProvider } from './decorators/localizationProvider'
import GlobalStyle from 'theme/globalStyles'
import AuthGuard from './routes/guards/authGuard'
import { Switch, Route } from 'react-router-dom'
import AuthRoutes from './routes/auth'
import Taxonomies from 'decorators/Taxonomies'
import { ToastProvider } from 'decorators/Toast'
import { createTheme, ThemeProvider } from '@mui/material'
import { Colors } from 'constants/colors'
import { SnackbarProvider } from 'notistack'
import 'react-nestable/dist/styles/index.css'
import { IsBackUrl } from 'decorators/IsBackUrl'
import { useSetApiIdToken } from 'hooks/useSetApiIdToken'

const theme = createTheme({
  palette: {
    green: {
      main: Colors.LIME_GREEN,
    },
    maroon: {
      main: Colors.MAROON,
      text: Colors.WHITE,
      secondary: Colors.WHITE,
    },
  },
})

function App() {
  const [isMenuToggled, setIsMenuToggled] = useState(false)

  useSetApiIdToken()

  return (
    <Router history={browserHistory}>
      <IsBackUrl>
        <ThemeProvider theme={theme}>
          <AppLocalizationProvider>
            <SnackbarProvider>
              <GlobalStyle />
              <Taxonomies>
                <Switch>
                  <React.Suspense fallback={<>Loading...</>}>
                    <Route path="/auth">
                      <AuthRoutes />
                    </Route>
                    <Route>
                      <AuthGuard>
                        <ToastProvider>
                          <Header toggled={() => setIsMenuToggled(!isMenuToggled)} />
                          <Menu isMenuToggled={isMenuToggled}>
                            <DashboardRoutes />
                          </Menu>
                        </ToastProvider>
                      </AuthGuard>
                    </Route>
                  </React.Suspense>
                </Switch>
              </Taxonomies>
            </SnackbarProvider>
          </AppLocalizationProvider>
        </ThemeProvider>
      </IsBackUrl>
    </Router>
  )
}

export default App

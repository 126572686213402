import { useState, useEffect, useRef } from 'react'
import { doc, onSnapshot, query, serverTimestamp, setDoc } from 'firebase/firestore'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from 'firebase/auth'

/******
 * if you use "auth" for creating user,
 * firebase will automatically login with the new created user (which is not suitable for the current functionality).
 * so please use "creatingUserAuth" for creating user as super admin.
 * ****/
import { auth, db, creatingUserAuth } from './firebase'
import { VerificationStatuses } from 'constants/profiles'
import { useAddChangelogs } from './changelogs'
import { changelogTypes } from 'constants/changelogs'

/**
 * Get product with id. Also the data keeps updating if it changes in Firestore.
 *
 * TODO: Add loading and error properties to the return value.
 *
 * @param {string} userId
 * @returns {object} Containing auction data
 */
export function useAuth(userId) {
  // Store auction data to local state
  const [user, setUser] = useState({})
  const addChangelog = useAddChangelogs()

  // Sync auction data
  useEffect(() => {
    if (!userId) return
    const q = query(doc(db, 'users', userId))

    // Subscribe to data from /users
    const unsubscribe = onSnapshot(
      q,
      // This callback is called when data is initially fetched and when it changes.
      doc => {
        const data = { ...doc.data(), id: doc.id }

        // Set auction data to local state in this hook.
        setUser(data)
      },
      error => {
        console.error('Failed to sync User data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [setUser, userId])

  // These functions mutate data in Firestore.
  const mutationsRef = useRef(
    {
      signupUser: async ({ email, password, ...restUserData }) => {
        try {
          const { user } = await createUserWithEmailAndPassword(creatingUserAuth, email, password)
          const userDocRef = doc(db, 'users', user.uid)
          await setDoc(
            userDocRef,
            {
              ...restUserData,
              uid: user.uid,
              name: user.displayName,
              authProvider: user.providerId,
              email,
              emailVerified: user.emailVerified,
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
              deletedAt: null,
            },
            { merge: true }
          )
          await addChangelog(userDocRef, changelogTypes.CREATE)
          return user
        } catch (err) {
          console.error(err)
        }
      },
      signupSeller: async ({ email, password, ...restUserData }) => {
        try {
          const { user } = await createUserWithEmailAndPassword(creatingUserAuth, email, password)
          await setDoc(
            doc(db, 'profiles', user.uid),
            {
              ...restUserData,
              uid: user.uid,
              name: user.displayName,
              authProvider: user.providerId,
              email,
              emailVerified: user.emailVerified,
              isSeller: true,
              verificationStatus: VerificationStatuses.DRAFT,
              createdAt: serverTimestamp(),
              updatedAt: serverTimestamp(),
              holmastoId: `${restUserData.lastName.slice(0, 3).toLocaleUpperCase()}${restUserData.phone.slice(-3)}`,
              deletedAt: null,
            },
            { merge: true }
          )
          return user
        } catch (err) {
          alert(err.message)
        }
      },
      signInWithEmailAndPass: async (email, password) => {
        try {
          const result = await signInWithEmailAndPassword(auth, email, password)
          return result
        } catch (err) {
          alert(err.message)
        }
      },
      sendResetPassLink: async email => {
        try {
          await sendPasswordResetEmail(auth, email)
          alert('Password reset link sent!')
        } catch (err) {
          alert(err.message)
        }
      },
      logout: () => signOut(auth),
    },
    []
  )

  return {
    // auction data from Firebase
    user,
    auth,
    // Spread Firestore mutation functions.
    ...mutationsRef.current,
  }
}

import { Card, CardContent } from '@mui/material'
import { useNewProfiles } from 'data/profiles'
import { Table } from './Table'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { useAppTranslation } from 'i18n/hooks'
import { Margin } from 'Components/Ui-Kits/Margin'

export const NewCustomers = () => {
  const { profiles } = useNewProfiles()
  const { t } = useAppTranslation()

  return (
    <Card>
      <CardContent>
        <Text type={TextTypes.HEADING_34}>{t('newCustomers')}</Text>
        <Margin b={10} />
        <Table items={profiles} />
      </CardContent>
    </Card>
  )
}

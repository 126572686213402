export const getDefaultAuction = auctions => {
  const currentDate = new Date().getTime()

  const id = auctions
    .sort(({ startDatePreBid: date1, startDatePreBid: date2 }) => date2?.getTime() - date1?.getTime())
    .find(({ startDatePreBid }) => {
      return startDatePreBid?.getTime() < currentDate
    })?.id

  if (!id) {
    return auctions[0]?.id
  }

  return id
}

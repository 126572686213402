import { useState, useEffect } from 'react'
import { collection, onSnapshot, query, where } from 'firebase/firestore'

import { db } from './firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { productConventer } from 'conventers/productConventer'

/**
 * Get product with id. Also the data keeps updating if it changes in Firestore.
 *
 * TODO: Add loading and error properties to the return value.
 *
 * @param {object} arg
 * @returns {object} Containing product data
 */
export function useProductDynamic(arg = {}) {
  // Store product data to local state
  const [queryArgs, setQueryArgs] = useState({ ...arg })
  const [products, setProducts] = useState([])

  // Sync product data
  useEffect(() => {
    if (!Object.keys(queryArgs).length) return
    const { queryKey, queryValue, queryTerm } = queryArgs
    const q = query(collection(db, 'products'), where('deletedAt', '==', null), where(queryKey, queryTerm, queryValue))

    // Subscribe to data from /products
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })

        // Set products data to local state in this hook.
        setProducts(data)
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [setProducts, queryArgs])

  return {
    // Products data from Firebase
    products,
    setQueryArgs,
  }
}

export const useMissingInfoProductsForAuction = auctionId => {
  const [products = [], loading, error] = useCollectionData(
    auctionId
      ? query(
          collection(db, 'products'),
          where('deletedAt', '==', null),
          where('assignedAuctionId', '==', auctionId),
          where('informationIsMissing', '==', true)
        ).withConverter(productConventer)
      : undefined
  )

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return [products, loading, error]
}

import PropTypes, { number, string, arrayOf, oneOf, node, object, bool, oneOfType } from 'prop-types'
import { Grid as MatGrid } from '@mui/material'
import { forwardRef } from 'react'

const Grid = forwardRef((props, ref) => {
  return <MatGrid {...props} ref={ref} />
})

Grid.displayName = 'Grid'

export default Grid

const gridSizes = Object.freeze(['auto', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, bool])

Grid.propTypes = {
  children: node,
  classes: PropTypes.object,
  columns: oneOfType([number, object, arrayOf(number)]),
  columnSpacing: oneOfType([number, string, object, arrayOf(number), arrayOf(string)]),
  rowSpacing: oneOfType([number, string, object, arrayOf(number), arrayOf(string)]),
  spacing: oneOfType([number, string, object, arrayOf(number), arrayOf(string)]),
  container: bool,
  item: bool,
  direction: oneOf([
    'column-reverse',
    'column',
    'row-reverse',
    'row',
    arrayOf(oneOf(['column-reverse', 'column', 'row-reverse', 'row'])),
    object,
  ]),
  lg: oneOf(gridSizes),
  md: oneOf(gridSizes),
  sm: oneOf(gridSizes),
  xl: oneOf(gridSizes),
  xs: oneOf(gridSizes),
  wrap: oneOf(['nowrap', 'wrap-reverse', 'wrap']),
  zeroMinWidth: bool,
}

import { useCallback, useEffect, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { useSessionStorage } from './useSessionStorage'
import { useLocation } from 'react-router'
import { useIsBackUrl } from 'decorators/IsBackUrl'

export const useDivHeight = data => {
  const ref = useRef(null)
  const [height, setHeight] = useState(500)
  const location = useLocation()
  const [scrollTop, setScrollTop] = useSessionStorage(location.pathname, 0)
  const scrollTopRef = useRef(scrollTop)
  const isFirst = useRef(true)
  const isBackUrl = useIsBackUrl()

  const onScroll = useCallback(
    event => {
      setScrollTop(event.target.scrollTop)
    },
    [setScrollTop]
  )

  useEffect(() => {
    if (!ref.current) return
    const handler = debounce(() => {
      setHeight(Math.max(500, window.innerHeight - ref.current?.offsetTop - 50))
    }, 500)
    handler()
    window.addEventListener('resize', handler)

    return () => window.removeEventListener('resize', handler)
  }, [])

  useEffect(() => {
    if (!ref.current) return
    const handler = debounce(onScroll, 500)
    const scrollDiv = ref.current.querySelector('[ref=eBodyViewport]')
    scrollDiv.addEventListener('scroll', handler)

    return () => scrollDiv.removeEventListener('scroll', handler)
  }, [onScroll])

  useEffect(() => {
    if (!data?.length || !isFirst.current || !ref.current || !isBackUrl) return
    const timeout = setTimeout(() => {
      if (ref?.current?.querySelector('[ref=eBodyViewport]'))
        ref.current.querySelector('[ref=eBodyViewport]').scrollTop = scrollTopRef.current
    }, 500)

    isFirst.current = false

    return () => clearTimeout(timeout)
  }, [data, isBackUrl])

  return { ref, height }
}

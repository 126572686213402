export const auctionStatuses = Object.freeze({
  CURRENT: 'current',
  SOLD: 'sold',
  NOT_SOLD: 'not_sold',
  WAITING: 'waiting',
  DISABLED: 'disabled',
  BIDDING_ENDED: 'bidding_ended',
})

export const auctionStatusTranslation = (status, t) => {
  switch (status) {
    case auctionStatuses.CURRENT:
    case auctionStatuses.SOLD:
    case auctionStatuses.WAITING:
    case auctionStatuses.DISABLED:
      return t(status)
    case auctionStatuses.NOT_SOLD:
      return t('notSold')
    case auctionStatuses.BIDDING_ENDED:
      return t('biddingEnded')
    default:
      return ''
  }
}

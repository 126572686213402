import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from 'constants/colors'

export const Edit = ({ value }) => {
  const { t } = useTranslation()
  return (
    <Tooltip title={t('edit')}>
      <IconButton size="small" href={`/clients/${value}`} target="_blank">
        <FontAwesomeIcon icon={['fas', 'pen-to-square']} color={Colors.MAROON} size="sm" />
      </IconButton>
    </Tooltip>
  )
}

Edit.propTypes = {
  value: PropTypes.string,
}

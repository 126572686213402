import { useAppTranslation } from 'i18n/hooks'
import { useAuth } from 'data/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Password } from './Password'
import { General } from './General'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box } from '@mui/system'
import { useState } from 'react'
import { Tab } from '@mui/material'

const tabTypes = Object.freeze({
  GENERAL: 'general',
  PASSWORD: 'password',
})

export const Profile = () => {
  const { t } = useAppTranslation()

  const { auth } = useAuth()
  const [authUser, authLoading] = useAuthState(auth)
  const [activeTab, setActiveTab] = useState(tabTypes.GENERAL)

  return (
    <>
      {!authLoading && !!authUser && (
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={(e, value) => setActiveTab(value)} aria-label="lab API tabs example">
              <Tab label={t('general')} value={tabTypes.GENERAL} />
              <Tab label={t('changePassword')} value={tabTypes.PASSWORD} />
            </TabList>
            <TabPanel value={tabTypes.GENERAL}>
              <General authUser={authUser} />
            </TabPanel>
            <TabPanel value={tabTypes.PASSWORD}>
              <Password user={authUser} />
            </TabPanel>
          </Box>
        </TabContext>
      )}
    </>
  )
}

Profile.propTypes = {}

import { CircularProgress, Grid } from '@mui/material'
import InfoBox from './InfoBox'
import { useAppTranslation } from 'i18n/hooks'
import { string } from 'prop-types'
import { useLatestAuctionStats } from './useLatestAuctionStats'
import styled from 'styled-components'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareArrowUpRight } from '@fortawesome/pro-solid-svg-icons'
import { Colors } from 'constants/colors'
import { useSetQuery } from 'utils/useSetQuery'

export const Stats = ({ auctionId }) => {
  const {
    itemsCount,
    itemsWithBidsCount,
    bidCount,
    custumersWithBidsCount,
    mostBiddedProducts,
    topCategories,
    winningPreBidsSum,
    winningLiveBidsSum,
    loading,
  } = useLatestAuctionStats(auctionId)
  const { t } = useAppTranslation()
  const setQuery = useSetQuery()
  const goToBids = () => {
    setQuery({ auction: auctionId, tab: 'bids', subTab: 'list' })
  }

  if (loading) {
    return (
      <LoadingWrap>
        <CircularProgress size={60} />
      </LoadingWrap>
    )
  }

  return (
    <Grid container spacing={5} alignItems="stretch">
      <Grid item xs={4} dispay="flex">
        <InfoBox
          className={'card_spacing'}
          header={t('auctionTotals')}
          data={[
            {
              label: t('bidsTotal'),
              value: (
                <Value>
                  <Text type={TextTypes.BODY_20}>{bidCount}</Text>
                  <Icon icon={faSquareArrowUpRight} onClick={goToBids} />
                </Value>
              ),
            },
            {
              label: t('uniqueCustomersWithBids'),
              value: (
                <Value>
                  <Text type={TextTypes.BODY_20}>{custumersWithBidsCount}</Text>
                  <Icon icon={faSquareArrowUpRight} onClick={goToBids} />
                </Value>
              ),
            },
            {
              label: t('itemsWithBids'),
              value: (
                <Value>
                  <Text type={TextTypes.BODY_20}>{[itemsWithBidsCount, itemsCount].join(' / ')}</Text>
                  <Icon icon={faSquareArrowUpRight} onClick={goToBids} />
                </Value>
              ),
            },
            {
              label: t('sumOfWinningPreBids'),
              value: (
                <Value>
                  <Text type={TextTypes.BODY_20}>{winningPreBidsSum.toLocaleString('FI-fi')} €</Text>
                  <Icon icon={faSquareArrowUpRight} onClick={goToBids} />
                </Value>
              ),
            },
            {
              label: t('sumOfWinningLiveBids'),
              value: (
                <Value>
                  <Text type={TextTypes.BODY_20}>{winningLiveBidsSum.toLocaleString('FI-fi')} €</Text>
                  <Icon icon={faSquareArrowUpRight} onClick={goToBids} />
                </Value>
              ),
            },
          ]}
        />
      </Grid>
      <Grid item xs={4}>
        <InfoBox className={'card_spacing'} header={t('itemsWithMostBids')} data={mostBiddedProducts} />
      </Grid>
      <Grid item xs={4}>
        <InfoBox className={'card_spacing'} header={t('categoriesWithMostBids')} data={topCategories} />
      </Grid>
    </Grid>
  )
}

const Value = styled.div`
  display: flex;
  flex-direction: row;
  align-items: middle;
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.25rem;
  margin-left: 1.25rem;
  color: ${Colors.MAROON};
  cursor: pointer;
`

Stats.propTypes = {
  auctionId: string.isRequired,
}

const LoadingWrap = styled.div`
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

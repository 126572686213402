import * as React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import { useEffect } from 'react'
import styled from 'styled-components'
import { useAppTranslation } from 'i18n/hooks'
import { Colors } from 'constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAppHistory } from 'routes/dashboard'
import ChangeLanguage from './ChangeLanguage'
import { faHistory, faLayerGroup, faTruckFast } from '@fortawesome/pro-solid-svg-icons'

const drawerWidth = 240

const MenuItems = Object.freeze([
  { name: 'home', icon: ['far', 'house'], url: '/home' },
  { name: 'fastTrackReceptions', icon: faTruckFast, url: '/fast-track-receptions' },
  { name: 'reception', icon: ['far', 'file-lines'], url: '/reception' },
  { name: 'products', icon: ['far', 'gem'], url: '/products' },
  { name: 'auctions', icon: ['far', 'gavel'], url: '/auctions' },
  { name: 'category', icon: ['far', 'list'], url: '/category' },
  { name: 'tag', icon: ['far', 'tag'], url: '/tag' },
  { name: 'users', icon: ['far', 'user'], url: '/users' },
  { name: 'clients', icon: ['far', 'user'], url: '/clients' },
  { name: 'bidLimitRequests', icon: faLayerGroup, url: '/clients/requested' },
  { name: 'payments', icon: ['fas', 'credit-card'], url: '/payments' },
  { name: 'historyItems', icon: faHistory, url: '/history-items', target: '_blank' },
])

function Menu({ isMenuToggled, children }) {
  const { t } = useAppTranslation()
  const history = useAppHistory()
  const [mobileOpen, setMobileOpen] = React.useState(true)
  const [selected, setSelected] = React.useState(
    () => MenuItems.find(({ url }) => history.location.pathname.includes(url))?.name || ''
  )

  const handleDrawerToggle = React.useCallback(() => {
    setMobileOpen(mobileOpen => !mobileOpen)
  }, [])

  const onSelect = (item, target) => {
    setSelected(item.name)
    if (target !== '_blank') return history.push(item.url)

    window.open(item.url, '_blank')
  }

  useEffect(() => {
    handleDrawerToggle()
  }, [isMenuToggled, handleDrawerToggle])

  const drawer = (
    <div>
      <Toolbar />
      <List>
        {MenuItems.filter(({ parent }) => !parent).map(item => (
          <React.Fragment key={item.name}>
            <ListItem
              button
              className={`menu_item ${selected === item.name && 'menu_item__selected'}`}
              onClick={() => onSelect(item, item.target)}
            >
              <FontAwesomeIcon className={'menu_icon'} icon={item.icon} color={Colors.MAROON} />
              <ListItemText primary={t(item.name)} className={selected === item.name ? 'menu_text__selected' : ''} />
            </ListItem>
            {!!MenuItems.filter(({ parent }) => parent === item.url).length && (
              <List component="div" disablePadding>
                {MenuItems.filter(({ parent }) => parent === item.url).map(item => (
                  <ListItem
                    sx={{ pl: 4 }}
                    button
                    className={`menu_item ${selected === item.name && 'menu_item__selected'}`}
                    onClick={() => onSelect(item, item.target)}
                    key={item.name}
                    target={item.target}
                  >
                    <ListItemText
                      primary={t(item.name)}
                      className={selected === item.name ? 'menu_text__selected' : ''}
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </React.Fragment>
        ))}
      </List>
      <ChangeLanguage />
    </div>
  )

  return (
    <MainDiv>
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
        {children}
      </Box>
    </MainDiv>
  )
}

Menu.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
  children: PropTypes.any,
  isMenuToggled: PropTypes.bool,
}

export default Menu

const MainDiv = styled.div`
  display: flex;
  margin-top: 70px;

  .menu_icon {
    margin-right: 16px;
  }

  .menu_item {
    border-left: 4px solid transparent;
  }

  .menu_item__selected {
    border-left: 4px solid ${Colors.MAROON};
  }

  .menu_text__selected {
    color: ${Colors.MAROON};
  }
`

import { ReceptionsTable } from 'Components/Shared/ReceptionsTable'
import { useOpenReceptionsForAssignedUser } from 'data/receptions'
import { useAppTranslation } from 'i18n/hooks'
import { string } from 'prop-types'

export const AssignedReceptions = ({ email }) => {
  const [receptions = [], loading] = useOpenReceptionsForAssignedUser(email)
  const { t } = useAppTranslation()

  return <ReceptionsTable receptions={receptions} loading={loading} title={t('openReceptionIAmPersonalAssigned')} />
}

AssignedReceptions.propTypes = {
  email: string,
}

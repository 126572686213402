import { any } from 'prop-types'
import { useEffect } from 'react'
import { useAuth } from 'data/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useAppHistory } from '../dashboard'
import { CircularProgress } from '@mui/material'
import styled from 'styled-components'
import { useUser } from 'data/users'
import { signOut } from 'firebase/auth'
import { userStatus } from 'constants/users'

function AuthGuard(props) {
  const { auth } = useAuth()
  const [user, loading] = useAuthState(auth)
  const { user: userDoc, loading: userDocLoading } = useUser(user?.uid)

  const history = useAppHistory()
  useEffect(() => {
    if (!loading) {
      if (user === null) history.replace('/auth/login')

      if (!userDocLoading && (!userDoc?.id || userDoc?.status !== userStatus.ACTIVE)) {
        signOut(auth)
        history.replace('/auth/login')
      }
    }
  }, [loading, user, history, userDocLoading, auth, userDoc])

  return loading || userDocLoading ? (
    <StyledDiv>
      <CircularProgress size={100} />
    </StyledDiv>
  ) : (
    user && <>{props.children}</>
  )
}

AuthGuard.propTypes = {
  children: any,
}

export default AuthGuard

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

import { useState, useEffect, useRef } from 'react'
import { addDoc, collection, onSnapshot, query, serverTimestamp, where, orderBy } from 'firebase/firestore'

import { db } from './firebase'
import { useAppTranslation } from 'i18n/hooks'

/**
 * Get tag with id. Also the data keeps updating if it changes in Firestore.
 *
 * TODO: Add loading and error properties to the return value.
 *
 * @param {string} id tagId
 * @returns {object} Containing tag data
 */
export function useTags() {
  // Store tag data to local state
  const [tags, setTags] = useState([])
  const {
    i18n: { language },
  } = useAppTranslation()
  const [isFetching, setIsFetching] = useState(true)

  // Sync tag data
  useEffect(() => {
    setIsFetching(true)
    const q = query(collection(db, 'tags'), where('deletedAt', '==', null), orderBy(`label.${language}`, 'desc'))

    // Subscribe to data from /tags
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })

        // Set tags data to local state in this hook.
        setTags(data)
        if (data.length) setIsFetching(false)
      },
      error => {
        console.error('Failed to sync Tag data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [language])

  // These functions mutate data in Firestore.
  const mutationsRef = useRef(
    {
      addTag: async data => {
        return addDoc(collection(db, 'tags'), {
          ...data,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          deletedAt: null,
        })
      },
    },
    []
  )

  return {
    // Tags data from Firebase
    tags,
    isFetching,

    // Spread Firestore mutation functions.
    ...mutationsRef.current,
  }
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useAppTranslation } from 'i18n/hooks'
import { bool, func, number, string } from 'prop-types'
import InputText from 'Components/Ui-Kits/Inputs/InputText'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { updateBid } from 'cloudFunctions'
import { useState } from 'react'
import { Margin } from 'Components/Ui-Kits/Margin'

const bidSchema = t =>
  Yup.object({
    amount: Yup.number().nullable().required(t('required')),
  })

export const EditModal = ({ onClose, auctionId, productId, bidId, amount, open, userName }) => {
  const { t } = useAppTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(bidSchema(t)),
    defaultValues: { amount },
  })
  const [loading, setLoading] = useState(false)

  const onConfirm = handleSubmit(async ({ amount }) => {
    setLoading(true)
    await updateBid({ auctionId, itemId: productId, bidId, amount })
    setLoading(false)
    onClose()
  })

  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>{t('editBid')}</DialogTitle>
      <DialogContent>
        <Margin t={1} b={5}>
          <InputText disabled value={userName} label={t('user')} />
        </Margin>
        <InputText
          defaultValue={amount}
          type="number"
          {...register('amount')}
          helperText={errors.amount?.message}
          error={!!errors.amount}
          label={t('amount')}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus disabled={loading}>
          {t('cancel')}
        </Button>
        <Button onClick={onConfirm} color="success" disabled={loading}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

EditModal.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
  auctionId: string.isRequired,
  productId: string.isRequired,
  amount: number.isRequired,
  bidId: string.isRequired,
  userName: string,
}

import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { IS_PRODUCTION, FIREBASE_PROJECT_ID } from '../constants/index'

const firebaseConfig = JSON.parse(
  process.env.REACT_APP_CONFIG ||
    `{"projectId": "${FIREBASE_PROJECT_ID}", "apiKey": "123å",  "storageBucket": "${FIREBASE_PROJECT_ID}.appspot.com"}`
)

export const firebaseApp = initializeApp(firebaseConfig)

// Get a reference to the firestore
export const db = getFirestore(firebaseApp)

// Get a reference to the storage service, which is used to create references in your storage bucket
export const storage = getStorage(firebaseApp)

export const functions = getFunctions(firebaseApp, 'europe-west3')

export const auth = getAuth(firebaseApp)

/*********** Don't use this instance except for creating user as super-admin *************/
/******
 * if you use "auth" for creating user,
 * firebase will automatically login with the new created user (which is not suitable for the current functionality).
 * so please use "creatingUserAuth" for creating user as super admin.
 * ****/
export const secondaryAppInstance = initializeApp(firebaseConfig, 'app instance for user creation as super-admin')
export const creatingUserAuth = getAuth(secondaryAppInstance)
/*********** Don't use this instance except for creating user as super-admin *************/

/*********** WARNING! DON'T PUSH THIS TO PRODUCTION! *************/

// NOTE: Uncomment this if you want to use these services from the local emulator.
if (!IS_PRODUCTION) {
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectStorageEmulator(storage, 'localhost', 9199)
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectAuthEmulator(creatingUserAuth, 'http://localhost:9099')
}

/*********** WARNING! DON'T PUSH THIS TO PRODUCTION! *************/

import { apiInstance } from 'api'
import { auth } from 'data/firebase'
import { useEffect } from 'react'

export const useSetApiIdToken = () => {
  useEffect(() => {
    auth.onIdTokenChanged(user => {
      user?.getIdToken().then(token => (apiInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`))
    })
  }, [])
}

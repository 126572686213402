import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from 'constants/colors'
import { CircularProgress } from '@mui/material'

const Button = ({
  color = Colors.MAROON,
  textColor = Colors.WHITE,
  disabled = false,
  children,
  style,
  className,
  onClick,
  type,
  isFetching = false,
  ...props
}) => {
  return (
    <ButtonStyled
      className={className}
      disabled={isFetching || disabled}
      color={color}
      textColor={textColor}
      style={style}
      onClick={onClick}
      type={type}
      {...props}
    >
      {isFetching ? <CircularProgress size={25} /> : children}
    </ButtonStyled>
  )
}

export default Button

Button.propTypes = {
  color: PropTypes.oneOf(Object.values(Colors)),
  textColor: PropTypes.oneOf(Object.values(Colors)),
  children: PropTypes.any,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.any,
  isFetching: PropTypes.bool,
}

const ButtonStyled = styled.button`
  height: 44px;
  max-width: ${({ maxWidth = 250 }) => maxWidth}px;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  border-radius: 0;
  padding: 0 10px;
  font: normal normal bold 16px/24px atten-new;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: ${({ textColor }) => textColor};
  background-color: ${({ color }) => color};

  ${({ secondary, color }) =>
    secondary &&
    `
    background-color: ${color};
    color: ${color};
    border: 2px solid ${color};
    background-color: transparent;
  `}

  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
  :active {
    opacity: 0.7;
  }
  :focus {
    box-shadow: 0 4px 20px 0 rgb(161 113 113 / 10%), 0 0 0 5px rgb(129 2 44 / 30%);
    outline: none;
  }
  :disabled,
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: 0 0 0 0 rgba(0, 127, 255, 0);
  }
`

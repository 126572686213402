import { AgGridReact } from 'ag-grid-react'
import { any, func, number } from 'prop-types'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { debounce } from 'lodash'

export const GridWrap = forwardRef(({ children, height, onRowDataChanged, onGridReady, ...props }, ref) => {
  const grid = useRef(null)
  const innerRef = useRef(null)

  const onInnerGridReady = e => {
    grid.current = e
    onGridReady?.(e)
  }

  useImperativeHandle(ref, () => innerRef.current)

  const fixGridColumns = useCallback(grid => {
    const columns = []
    const gridWidth = innerRef.current.offsetWidth - 5

    const getVisibleColumns = () => grid.columnApi.getAllColumns().filter(({ visible }) => visible)

    getVisibleColumns().forEach(column => {
      columns.push(column.getId())
    })

    grid.columnApi.autoSizeColumns(columns, true)

    getVisibleColumns().forEach(column => {
      if (column.getActualWidth() < 70) {
        grid.columnApi.setColumnWidth(column, 70)
      }
    })

    const columnsWidth = getVisibleColumns().reduce((sum, column) => sum + column.getActualWidth(), 0)
    if (gridWidth < columnsWidth) {
      let newColumnsWidth = columnsWidth
      let i = 0
      while (
        newColumnsWidth > gridWidth &&
        !!getVisibleColumns().find(column => Math.ceil(column.getActualWidth()) > 70 && column.getMinWidth() < 70)
      ) {
        i++
        const longColumns = getVisibleColumns().filter(column => column.getActualWidth() > 70)
        const widthUsed = longColumns.reduce((sum, column) => sum + column.getActualWidth(), 0)
        let widthCanBeUsed =
          gridWidth -
          getVisibleColumns()
            .filter(column => column.getActualWidth() <= 70)
            .reduce((sum, column) => sum + column.getActualWidth(), 0) -
          10

        let tmpWidthCanBeUsed = widthCanBeUsed
        let tmpWidthUsed = widthUsed
        longColumns.forEach(column => {
          const calculatedWidth = Math.min(
            (column.getActualWidth() * tmpWidthCanBeUsed) / tmpWidthUsed,
            widthCanBeUsed * 0.8
          )
          tmpWidthUsed -= column.getActualWidth()
          tmpWidthCanBeUsed -= calculatedWidth
          const toSet = Math.max(70, calculatedWidth)
          grid.columnApi.setColumnWidth(column, toSet)
        })

        newColumnsWidth = getVisibleColumns().reduce((sum, column) => sum + column.getActualWidth(), 0)

        if (i == 1000) break
      }
    } else if (gridWidth > columnsWidth) {
      getVisibleColumns().forEach(column => {
        const calculatedWidth = (column.getActualWidth() * gridWidth) / columnsWidth
        const toSet = Math.max(70, calculatedWidth)
        grid.columnApi.setColumnWidth(column, toSet)
      })
    }
  }, [])

  useEffect(() => {
    const resizeListenner = debounce(() => fixGridColumns(grid.current), 500)

    window.addEventListener('resize', resizeListenner)

    return () => window.removeEventListener('resize', resizeListenner)
  }, [fixGridColumns])

  const onInnerRowDataChanged = grid => {
    setTimeout(() => fixGridColumns(grid), 50)

    onRowDataChanged?.(grid)
  }

  return (
    <div
      ref={innerRef}
      style={{
        height,
        width: '100%',
      }}
      className="ag-theme-alpine"
    >
      <AgGridReact
        onGridReady={onInnerGridReady}
        onRowDataChanged={onInnerRowDataChanged}
        onColumnVisible={onInnerRowDataChanged}
        {...props}
      >
        {children}
      </AgGridReact>
    </div>
  )
})

GridWrap.displayName = 'GridWrap'

GridWrap.propTypes = {
  children: any,
  height: number,
  onRowDataChanged: func,
  onGridReady: func,
}

import { Form } from '../Form'
import { tagEntity } from 'constants/tags'
import { useAppTranslation } from 'i18n/hooks'
import { Card, CardContent } from '@mui/material'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Margin } from 'Components/Ui-Kits/Margin'
import { useTags } from 'data/tags'
import { useState } from 'react'

export const Create = () => {
  const { t } = useAppTranslation()

  const { addTag } = useTags()
  const [loading, setLoading] = useState(false)

  const onSubmit = async (data, reset) => {
    setLoading(true)
    await addTag(data)
    reset(tagEntity)
    setLoading(false)
  }

  return (
    <Card>
      <CardContent>
        <Text type={TextTypes.HEADING_20}>{t('createTag')}</Text>
        <Margin t={10} />
        <Form defaultValues={tagEntity} submitLabel={t('submit')} onSubmitSuccess={onSubmit} loading={loading} />
      </CardContent>
    </Card>
  )
}

import PropTypes, { any } from 'prop-types'
import { useState, useMemo } from 'react'
import styled from 'styled-components'
import { getPublicUrl } from 'utils/getPublicUrl'

export const ImageThumbnail = ({ value, data }) => {
  const [failed, setFailed] = useState(false)

  const url = useMemo(() => {
    if (!data.featuredImage?.refPath) return ''

    const path = [
      ...data.featuredImage?.refPath.split('/').slice(0, -1),
      'thumbs',
      data.featuredImage?.refPath.split('/').pop(),
    ].join('/')

    return getPublicUrl(path)
  }, [data])

  return <StyledImg src={!failed ? url : value} onError={() => setFailed(true)} />
}

ImageThumbnail.propTypes = {
  value: PropTypes.any,
  data: any,
}

const StyledImg = styled.img`
  width: 50px;
  max-height: 50px;
`

import { Route, Switch } from 'react-router-dom'
import { useRouteMatch } from 'react-router'
import RegisterUser from 'Components/Users/Add'
import Login from 'Components/Auth/Login'
import ResetPassword from 'Components/Auth/ResetPassword'
import * as React from 'react'

const AuthRoutes = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={`${path}/register`}>
        <RegisterUser />
      </Route>
      <Route exact path={`${path}/login`}>
        <Login />
      </Route>
      <Route exact path={`${path}/forgot-password`}>
        <ResetPassword />
      </Route>
    </Switch>
  )
}

export default AuthRoutes

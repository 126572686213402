import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { GridWrap } from 'Components/Shared/GridWrap'
import { AgGridColumn } from 'ag-grid-react'
import { bidsIndex } from 'api/algolia'
import { func, number, string } from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'

export const Bids = ({ onClose, tempId, auctionPK, description }) => {
  const { t } = useTranslation()
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(false)
  const noMorePages = useRef(false)
  const [gridApi, setGridApi] = useState(null)
  const [page, setPage] = useState(0)

  useEffect(() => {
    setLoading(true)
    bidsIndex
      .search('', {
        hitsPerPage: 50,
        filters: `productId = ${tempId} AND auctionId = ${auctionPK}`,
      })
      .then(result => {
        setItems(items => [...items, ...result.hits])
        noMorePages.current = page >= result.nbPages
      })
      .finally(() => setLoading(false))
  }, [tempId, auctionPK, page])

  const onGridReady = params => {
    setGridApi(params.api)
  }

  useEffect(() => {
    if (!gridApi) return
    gridApi.setRowData(items)
  }, [gridApi, items])

  const onGridScrolled = () => {
    if (loading || noMorePages.current) return

    if (gridApi.getLastDisplayedRow() < items.length - 1) {
      return
    }

    setPage(page => page + 1)
  }

  return createPortal(
    <Dialog open onClose={onClose} fullWidth maxWidth="xl" PaperProps={{ style: { height: '90%', minHeight: 400 } }}>
      <DialogTitle>
        <div style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100%', overflow: 'hidden' }}>
          {t('bids')} - {description}
        </div>
      </DialogTitle>
      <DialogContent>
        <GridWrap
          gridOptions={{ headerHeight: 50 }}
          defaultColDef={{
            sortable: false,
            filter: false,
            floatingFilter: false,
            resizable: true,
          }}
          suppressScrollOnNewData
          height={'100%'}
          onBodyScroll={onGridScrolled}
          onGridReady={onGridReady}
        >
          <AgGridColumn headerClass={'grid_header'} headerName={t('bidder')} field="bidder" sortable={true} />
          <AgGridColumn headerClass={'grid_header'} headerName={t('amount')} field="amount" sortable={true} />
          <AgGridColumn headerClass={'grid_header'} headerName={t('type')} field="type" sortable={true} />
        </GridWrap>
      </DialogContent>
    </Dialog>,
    document.body
  )
}

Bids.propTypes = {
  onClose: func.isRequired,
  tempId: number,
  auctionPK: number,
  description: string,
}

export const categoriesApiUrl = 'https://holmasto.fi/wp-json/holmasto/v1/categories/'

export const Taxonomies = Object.freeze({
  MATERIAL: 'pa_materiaali',
  PRODUCT_CATEGORY: 'product_cat', // ??
  RULER: 'pa_hallitsija',
  JEWELRY_TYPE: 'pa_korutyyppi', // ??
  CONDITION: 'pa_kunto',
  COUNTRY: 'pa_maa',
  NOMINAL_VALUE: 'pa_nimellisarvo',
  Olympia: 'pa_olympia',
  SIZE: 'pa_size',
  DESIGNER: 'pa_suunnittelija',
  MANUFACTURER: 'pa_valmistaja',
})

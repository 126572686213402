import { AppBar, Toolbar, IconButton, Box, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import logo from 'assets/holmasto-logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Colors } from 'constants/colors'
import { useAuth } from 'data/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useAppTranslation } from 'i18n/hooks'
import { useAppHistory } from 'routes/dashboard'
import { useCallback } from 'react'

function Header(props) {
  const { logout, auth } = useAuth()
  const [user] = useAuthState(auth)
  const { t } = useAppTranslation()
  const history = useAppHistory()

  const goToProfile = useCallback(() => {
    history.push('/profile')
  }, [history])

  return (
    <Box sx={{ flexGrow: 1, position: 'fixed', left: '0px', zIndex: '1201', top: '0px', width: '100%' }}>
      <AppBar position="static" color={'inherit'}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2, display: { sm: 'none' } }}
            onClick={props.toggled}
          >
            <FontAwesomeIcon icon={['fal', 'bars']} color={Colors.MAROON} />
          </IconButton>
          <img src={logo} style={{ height: '33px' }} />
          <div style={{ flexGrow: 1 }} />
          {!!user && (
            <Tooltip title={t('profile')}>
              <IconButton
                size="medium"
                edge="start"
                color="inherit"
                aria-label="logout"
                sx={{ mr: 2 }}
                onClick={goToProfile}
              >
                <FontAwesomeIcon icon={['fas', 'user']} color={Colors.MAROON} />
              </IconButton>
            </Tooltip>
          )}
          {!!user && (
            <Tooltip title={t('logOut')}>
              <IconButton size="large" edge="start" color="inherit" aria-label="logout" sx={{ mr: 2 }} onClick={logout}>
                <FontAwesomeIcon icon={['fas', 'arrow-right-from-bracket']} color={Colors.MAROON} />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

Header.propTypes = {
  toggled: PropTypes.func,
}

export default Header

import { useState, useEffect, useRef, useMemo } from 'react'
import {
  addDoc,
  collection,
  onSnapshot,
  query,
  serverTimestamp,
  where,
  orderBy,
  limit,
  getDocs,
  setDoc,
  doc,
} from 'firebase/firestore'

import { db } from './firebase'
import { skuGenerator, statusValues } from '../constants/products'
import { productConventer } from 'conventers/productConventer'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { auctionStatuses } from 'constants/publishedProduct'
import { useAddChangelogs } from './changelogs'
import { changelogTypes } from 'constants/changelogs'

/**
 * Get product with id. Also the data keeps updating if it changes in Firestore.
 *
 * TODO: Add loading and error properties to the return value.
 *
 * @param {string} id productId
 * @returns {object} Containing product data
 */
export function useProducts(arg = {}) {
  const { productId, receptionId } = arg
  // Store product data to local state
  const [products, setProducts] = useState([])
  const addChangelogs = useAddChangelogs()

  // Sync product data
  useEffect(() => {
    const q = receptionId
      ? query(
          collection(db, 'products'),
          where('deletedAt', '==', null),
          where('receptionId', '==', receptionId),
          orderBy('createdAt', 'desc')
        )
      : query(collection(db, 'products'), where('deletedAt', '==', null), orderBy('createdAt', 'desc'))

    // Subscribe to data from /products
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })

        // Set products data to local state in this hook.
        setProducts(data)
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [receptionId])

  // These functions mutate data in Firestore.
  const mutationsRef = useRef(
    {
      addProduct: async data => {
        const [doc] = await getDocs(query(collection(db, 'products'), orderBy('sku', 'desc'), limit(1))).then(
          data => data.docs
        )

        if (data.assignedAuctionId && !data.auctionHistoryIds) {
          data.auctionHistoryIds = [data.assignedAuctionId]
        }

        if (data.technicalDetails?.auctionCategory?.id && !data.technicalDetails?.auctionCategory?.index) {
          const [lastCategoryDoc] = await getDocs(
            query(
              collection(db, 'products'),
              where('technicalDetails.auctionCategory.id', '==', data.technicalDetails?.auctionCategory?.id),
              where('assignedAuctionId', '==', data.assignedAuctionId),
              orderBy('technicalDetails.auctionCategory.index', 'desc'),
              limit(1)
            )
          ).then(data => data.docs)
          data.technicalDetails.auctionCategory.index =
            lastCategoryDoc?.data()?.technicalDetails?.auctionCategory?.index + 1 || 0
        }

        if (!data.status) {
          data.status = statusValues.CREATED
        }

        const docRef = await addDoc(collection(db, 'products'), {
          ...data,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          deletedAt: null,
          sku: skuGenerator(doc?.data()?.sku, data.warehouseId),
        })

        await addChangelogs(docRef, changelogTypes.CREATE)

        return docRef
      },
      copyProduct: async (data, oldProductId) => {
        if (data.technicalDetails?.auctionCategory?.id) {
          const [lastCategoryDoc] = await getDocs(
            query(
              collection(db, 'products'),
              where('technicalDetails.auctionCategory.id', '==', data.technicalDetails?.auctionCategory?.id),
              where('assignedAuctionId', '==', data.assignedAuctionId),
              orderBy('technicalDetails.auctionCategory.index', 'desc'),
              limit(1)
            )
          ).then(data => data.docs)
          data.technicalDetails.auctionCategory.index =
            lastCategoryDoc?.data()?.technicalDetails?.auctionCategory?.index + 1 || 0
        }

        const docRef = await addDoc(collection(db, 'products'), {
          ...data,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          deletedAt: null,
        }).then(async document => {
          const oldProductRef = doc(db, 'products', oldProductId)
          await addChangelogs(oldProductRef, changelogTypes.UPDATE, () =>
            setDoc(oldProductRef, { clonedProductId: oldProductId }, { merge: true })
          )
          return document
        })

        await addChangelogs(docRef, changelogTypes.CREATE)

        return docRef
      },
    },
    [productId]
  )

  return {
    // Products data from Firebase
    products,

    // Helper
    getSeller: () => products?.seller,

    // Spread Firestore mutation functions.
    ...mutationsRef.current,
  }
}

/**
 * Get last n products from query. Also the data keeps updating if product was added in Firestore.
 *
 * @param {number} count
 * @returns {array} Product list
 */
export function useLastProducts(count) {
  // Store product data to local state
  const [products, setProducts] = useState([])

  // Sync product data
  useEffect(() => {
    // Throw an error if count isn't integer
    if (!/^\d+$/.test(`${count}`)) {
      throw new Error('count should be integer')
    }

    // Ordering products by createdAt desc and limiting results based on count.
    const q = query(collection(db, 'products'), where('deletedAt', '==', null), orderBy('sku', 'desc'), limit(count))

    // Subscribe to data from /products
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })

        // Set products data to local state in this hook.
        setProducts(data)
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [setProducts, count])

  // Array of product data
  return products
}

/**
 * Get last n products from query. Also the data keeps updating if product was added in Firestore.
 *
 * @param {number} count
 * @returns {array} Product list
 */
export function useAuctionHasUnpublishedProducts(auctionId) {
  // Store product data to local state
  const [hasUnpublishedProducts, setHasUnpublishedProducts] = useState(false)

  // Sync product data
  useEffect(() => {
    if (!auctionId) return
    // Ordering products by createdAt desc and limiting results based on count.
    const q = query(
      collection(db, 'products'),
      where('deletedAt', '==', null),
      where('assignedAuctionId', '==', auctionId),
      where('status', '!=', statusValues.PUBLISHED),
      limit(1)
    )

    // Subscribe to data from /products
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        setHasUnpublishedProducts(!!querySnapshot.docs.length)
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [auctionId])

  // Array of product data
  return hasUnpublishedProducts
}

export const useClientProducts = (id, auctionId) => {
  const q = useMemo(() => {
    if (!id || !auctionId) return undefined

    return query(
      collection(db, 'products'),
      where('seller.id', '==', id),
      where('assignedAuctionId', '==', auctionId),
      where('deletedAt', '==', null),
      orderBy('createdAt', 'desc')
    ).withConverter(productConventer)
  }, [id, auctionId])

  const [products, loading, error] = useCollectionData(q)

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return { products, loading, auctionId }
}

export const useAssignedProducts = email => {
  const q = useMemo(() => {
    if (!email) return undefined

    return query(
      collection(db, 'products'),
      where('personnelAssigned', 'array-contains', email),
      where('deletedAt', '==', null),
      where('status', '!=', statusValues.PUBLISHED),
      orderBy('status', 'desc'),
      orderBy('createdAt', 'desc')
    ).withConverter(productConventer)
  }, [email])

  const [products, loading, error] = useCollectionData(q)

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return { products, loading }
}

export const useOpenProducts = () => {
  const [products, loading, error] = useCollectionData(
    query(
      collection(db, 'products'),
      where('deletedAt', '==', null),
      where('status', '!=', statusValues.PUBLISHED),
      orderBy('status', 'desc'),
      orderBy('createdAt', 'desc')
    ).withConverter(productConventer)
  )

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return { products, loading }
}

export const useReceptionAssignedProducts = email => {
  const q = useMemo(() => {
    if (!email) return undefined

    return query(
      collection(db, 'products'),
      where('reception.personnelAssigned', '==', email),
      where('deletedAt', '==', null),
      where('status', '!=', statusValues.PUBLISHED),
      orderBy('status', 'desc'),
      orderBy('createdAt', 'desc')
    ).withConverter(productConventer)
  }, [email])

  const [products, loading, error] = useCollectionData(q)

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return { products, loading }
}

export const useSellerNotSoldProducts = (auctionId, clientId) => {
  const q = useMemo(() => {
    return query(
      collection(db, 'products'),
      where('assignedAuctionId', '==', auctionId),
      where('seller.id', '==', clientId),
      where('auctionStatus', '==', auctionStatuses.NOT_SOLD),
      where('deletedAt', '==', null),
      where('status', '==', statusValues.PUBLISHED),
      orderBy('createdAt', 'desc')
    ).withConverter(productConventer)
  }, [auctionId, clientId])

  const [products, loading, error] = useCollectionData(q)

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return { products, loading }
}

export const useSellerProductsForAuction = (auctionId, clientId) => {
  const q = useMemo(() => {
    return query(
      collection(db, 'products'),
      where('assignedAuctionId', '==', auctionId),
      where('seller.id', '==', clientId),
      where('deletedAt', '==', null),
      where('status', 'in', [statusValues.PUBLISHED, statusValues.SOLD_TO_HOLMASTO, statusValues.RETURNED_TO_SELLER]),
      orderBy('createdAt', 'desc')
    ).withConverter(productConventer)
  }, [auctionId, clientId])

  const [products, loading, error] = useCollectionData(q)

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return { products, loading }
}

import { onSnapshot } from 'firebase/firestore'
import { useEffect, useMemo, useState } from 'react'
import { useChangedQueries } from './useChangedQueries'

// This hool will return joined data based on provided queries.
export const useMultiQueryData = (...args) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const changed = useChangedQueries(...args)

  useEffect(() => {
    setLoading(true)
    if (!changed.length) {
      return setLoading(false)
    }
    setData([])
    const unsubscribeFunctions = changed.map(query =>
      onSnapshot(query, ({ docs }) => {
        setLoading(false)
        setData(data => [...data, ...docs.map(doc => doc.data())])
      })
    )

    return () => unsubscribeFunctions.forEach(unsubscribe => unsubscribe())
  }, [changed])

  return useMemo(() => [data, loading], [data, loading])
}

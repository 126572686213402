import { Card, CardContent } from '@mui/material'
import { AgGridColumn } from 'ag-grid-react'
import { AgLink } from 'Components/Shared/AgLink'
import { GridStatus } from 'Components/Shared/Status'
import { Margin } from 'Components/Ui-Kits/Margin'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { dateTimeFormat } from 'constants/index'
import { Colors } from 'constants/colors'
import { getProductStatusColor } from 'constants/products'
import dayjs from 'dayjs'
import { useAppTranslation } from 'i18n/hooks'
import { array, bool, string } from 'prop-types'
import { useEffect, useState } from 'react'
import { useGetTranslated } from 'utils/useGetTranslated'
import { GridWrap } from '../GridWrap'

export const ProductsTable = ({ products, loading, title }) => {
  const [grid, setGrid] = useState(null)
  const { t } = useAppTranslation()
  const getTranslated = useGetTranslated()

  useEffect(() => {
    if (!grid || loading) return
    grid.api.setRowData(
      products.map(product => ({
        ...product,
        name: getTranslated(product.name),
        users: product.personnelAssigned?.join(', ') || '',
        sellerName: [product?.seller?.firstName, product?.seller?.lastName].filter(item => item).join(' '),
        createdAt: product.createdAt && dayjs(product.createdAt).format(dateTimeFormat),
      }))
    )
  }, [products, loading, grid, getTranslated])

  return (
    <Card>
      <CardContent>
        <Text type={TextTypes.HEADING_34}>{title}</Text>
        <Margin b={10} />
        <GridWrap
          gridOptions={{ headerHeight: 50 }}
          defaultColDef={{
            sortable: true,
            flex: 2,
            filter: true,
            floatingFilter: true,
            resizable: true,
          }}
          frameworkComponents={{
            status: GridStatus,
            link: AgLink,
            deadline({ value }) {
              return (
                <Text as="span" type={TextTypes.BODY_14} color={value <= 3 ? Colors.MAROON : Colors.BLACK}>
                  {value}
                </Text>
              )
            },
          }}
          rowHeight={60}
          onGridReady={setGrid}
          height={400}
        >
          <AgGridColumn headerClass={'grid_header'} field="sellerName" headerName={t('customerName')} />
          <AgGridColumn
            headerClass={'grid_header'}
            field="seller.holmastoId"
            headerName={t('holmastoId')}
            cellRenderer="link"
            cellRendererParams={{
              to: '/clients/{seller.id}/view',
            }}
          />
          <AgGridColumn
            headerClass={'grid_header'}
            field="sku"
            headerName={t('sku')}
            cellRenderer="link"
            cellRendererParams={{
              to: '/product/edit/{id}',
            }}
          />
          <AgGridColumn headerClass={'grid_header'} field="name" headerName={t('name')} />
          <AgGridColumn
            headerClass={'grid_header'}
            field="reception.serialNumber"
            headerName={t('receptionId')}
            cellRenderer="link"
            cellRendererParams={{
              to: '/reception/edit/{receptionId}',
            }}
          />
          <AgGridColumn
            headerClass={'grid_header'}
            field="reception.personnelAssigned"
            headerName={t('receptionPersonell')}
          />
          <AgGridColumn headerClass={'grid_header'} field="createdAt" headerName={t('date')} />
          <AgGridColumn
            headerClass={'grid_header'}
            headerName={t('status')}
            cellRenderer="status"
            field="status"
            cellRendererParams={{
              getColor: getProductStatusColor,
            }}
          />
        </GridWrap>
      </CardContent>
    </Card>
  )
}

ProductsTable.propTypes = {
  products: array.isRequired,
  loading: bool.isRequired,
  title: string.isRequired,
}

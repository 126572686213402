import { AgGridColumm } from 'ag-grid-react'
import { GridWrap } from 'Components/Shared/GridWrap'
import { ImageThumbnail } from 'Components/Shared/ImageThumbnail'
import { useDivHeight } from 'hooks/useDivHeight'
import { useSetTableFilters } from 'hooks/useSetTableFilters'
import { array } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { EditActionButton } from './EditActionButton'
import { AgLink } from 'Components/Shared/AgLink'

const Table = ({ data }) => {
  const { t } = useTranslation()
  const { ref, height } = useDivHeight(data)
  const { onRowDataChanged, onFilterChanged } = useSetTableFilters()

  return (
    <GridWrap
      rowData={data}
      gridOptions={{ headerHeight: 50 }}
      frameworkComponents={{ edit: EditActionButton, imageThumbnail: ImageThumbnail, link: AgLink }}
      onRowDataChanged={onRowDataChanged}
      onFilterChanged={onFilterChanged}
      defaultColDef={{
        sortable: true,
        filter: true,
        floatingFilter: true,
        resizable: true,
      }}
      ref={ref}
      height={height}
    >
      <AgGridColumm
        headerClass={'grid_header'}
        headerName={t('sku')}
        field="sku"
        sortable={true}
        cellRenderer="link"
        cellRendererParams={{
          to: '/product/edit/{id}',
        }}
      />
      <AgGridColumm headerClass={'grid_header'} headerName={t('name')} field="name.en" sortable={true} />
      <AgGridColumm headerClass={'grid_header'} field="owner" headerName={t('owner')} />
      <AgGridColumm
        headerClass={'grid_header'}
        headerName={t('AuctionMinimumPrice')}
        field="technicalDetails.AuctionMinimumPrice"
        sortable={true}
      />
      <AgGridColumm
        headerClass={'grid_header'}
        headerName={t('shippingContainer')}
        field="technicalDetails.shippingContainer"
        sortable={true}
      />
      <AgGridColumm
        headerClass={'grid_header'}
        field="featuredImage.url"
        headerName={t('image')}
        filte={false}
        cellRenderer="imageThumbnail"
        sortable={true}
      />

      <AgGridColumm headerClass={'grid_header'} field="id" label="" cellRenderer="edit" headerName={t('edit')} />
    </GridWrap>
  )
}

Table.propTypes = {
  data: array.isRequired,
}

export default Table

import { getShippingStatuses } from 'cloudFunctions'
import { useEffect, useState } from 'react'

export const productStatus = Object.freeze([
  { title: 'Approved', value: 'Approved' },
  { title: 'Unapproved', value: 'Unapproved' },
])

export const channelStatusValue = Object.freeze({
  WEBSHOP: 'webshop',
  AUCTION: 'auction',
  STORAGE: 'storage',
  NOT_SOLD: 'not-sold',
})
export const statusValues = Object.freeze({
  PUBLISHED: 'published',
  CREATED: 'created',
  PENDING: 'pending',
  APPROVED: 'approved',
  RETURNED: 'returned',
  DECLINED: 'declined',
  CLOSED: 'closed',
  READY_TO_SEND: 'ready_to_send',
  RETURNED_TO_SELLER: 'returned_to_seller',
  SOLD_TO_HOLMASTO: 'sold_to_holmasto',
  MOVED_FROM_EARLIER_AUCTION: 'moved_from_earlier_auction',
})

export const getProductStatusOptions = t => [
  { title: t('created'), value: statusValues.CREATED },
  { title: t('readyToSend'), value: statusValues.READY_TO_SEND },
  { title: t('sentWaitingForAnswer'), value: statusValues.PENDING },
  { title: t('accepted'), value: statusValues.APPROVED },
  { title: t('declined'), value: statusValues.DECLINED },
  { title: t('readyForCatalogue'), value: statusValues.PUBLISHED },
  { title: t('closed'), value: statusValues.CLOSED },
  { title: t('returnedToSeller'), value: statusValues.RETURNED_TO_SELLER },
]

export const getProductStatusLabel = (status, t) => {
  switch (status) {
    case statusValues.SOLD_TO_HOLMASTO:
      return t('soldToHolmasto')
    case statusValues.MOVED_FROM_EARLIER_AUCTION:
      return t('movedFromEarlierAuction')
    default:
      return getProductStatusOptions(t).find(({ value }) => value === status)?.title
  }
}

export const getProductStatusColor = value => {
  switch (value) {
    case statusValues.PUBLISHED:
    case statusValues.APPROVED:
      return 'success'
    case statusValues.PENDING:
      return 'info'
    case statusValues.DECLINED:
      return 'error'
    default:
      return 'info'
  }
}

export const productsFilterValues = Object.freeze({
  WEBSHOP: 'webshop',
  AUCTION: 'auction',
  STORAGE: 'storage',
  NOT_SOLD: 'not_sold',
  ALL: 'all',
  RETURNED_TO_SELLER: 'returned_to_seller',
})
export const ownerValues = Object.freeze({ HOLAMSTO: 'holmasto', CUSTOMER: 'customer' })
export const shippingContainerValues = Object.freeze({
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  SPECIAL_HANDLING: 'requires_special_handling',
})

export const ownerOptions = t => [
  { title: t('holmasto'), value: ownerValues.HOLAMSTO },
  { title: t('customer'), value: ownerValues.CUSTOMER },
]

export const channelStatusOptions = t => [
  { title: t('assignToWoocommerce'), value: channelStatusValue.WEBSHOP },
  { title: t('assignToAuction'), value: channelStatusValue.AUCTION },
  { title: t('putIntoStorage'), value: channelStatusValue.STORAGE },
]

export const getChannelLabel = (channel, t) => channelStatusOptions(t).find(({ value }) => value === channel)?.title

export const productsFilterTypes = t => [
  { title: t('inAuction'), value: productsFilterValues.AUCTION },
  { title: t('inWebshop'), value: productsFilterValues.WEBSHOP },
  { title: t('inWarehouse'), value: productsFilterValues.STORAGE },
  { title: t('notSold'), value: productsFilterValues.NOT_SOLD },
  { title: t('returnedToSeller'), value: productsFilterValues.RETURNED_TO_SELLER },
]

export const shippingContainersOptions = t => [
  { title: t('small'), value: shippingContainerValues.SMALL },
  { title: t('medium'), value: shippingContainerValues.MEDIUM },
  { title: t('large'), value: shippingContainerValues.LARGE },
  { title: t('requiresSpecialHandling'), value: shippingContainerValues.SPECIAL_HANDLING },
]

export const useShippingContainerOptions = () => {
  const [options, setOptions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    getShippingStatuses().then(({ data }) => {
      setOptions(data.map(({ slug, name }) => ({ value: slug, title: name })))
      setLoading(false)
    })
  }, [])

  return [options, loading]
}

export const skuGenerator = (latestSku = '', warehouseId = 'A') => {
  const [skuNumber] = latestSku.split('-')
  if (!latestSku || !skuNumber) return `60000-${warehouseId}`
  const newSkuId = +skuNumber + 1
  return `${newSkuId}-${warehouseId}`
}

export const WAREHOUSE_ID_REGEX = /^[A-Z0-9]*$/

export const taxableStatuses = Object.freeze({
  MARGINIAL: 'marginial',
  0: 0,
  12.5: 12.5,
  24: 24,
})

export const taxableStatusOptions = t => [
  { title: t('marginial'), value: taxableStatuses.MARGINIAL },
  { title: '0%', value: taxableStatuses[0] },
  { title: '12.5%', value: taxableStatuses[12.5] },
  { title: '24%', value: taxableStatuses[24] },
]

export const productEntity = Object.freeze({
  receptionId: null,
  channel: null,
  approval: null,
  status: statusValues.CREATED,
  isDrafted: true,
  sellerComment: null,
  name: {
    en: null,
    fi: null,
    sv: null,
  },
  description: {
    en: null,
    fi: null,
    sv: null,
  },
  personnelAssigned: [],
  assignedAuctionId: null,
  technicalDetails: {
    material: '',
    condition: '',
    conditionDescription: '',
    reference: '',
    country: '',
    quantity: '',
    nominalValue: '',
    weight: '',
    size: '',
    ruler: '',
    olympia: '',
    designer: '',
    manufacturer: '',
    taxableStatus: 'marginal-rate',
    webShopPrice: '',
    purchasedPrice: '',
    AuctionMinimumPrice: 0,
    productCategories: null,
    shippingContainer: '',
    auctionCategory: null,
  },
  featuredImage: null,
  images: null,
  owner: null,
  warehouseId: '',
  tags: [],
})

import { omit } from 'lodash'

export const bidConventer = {
  toFirestore(data) {
    return omit(data, ['id'])
  },
  fromFirestore(doc, options) {
    const data = doc.data(options)

    return {
      ...data,
      date: data?.date?.toDate() || null,
      id: doc.id,
    }
  },
}

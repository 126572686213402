import { useState, useEffect, useRef, useMemo } from 'react'
import {
  collection,
  onSnapshot,
  setDoc,
  query,
  addDoc,
  serverTimestamp,
  doc,
  where,
  orderBy,
  documentId,
} from 'firebase/firestore'

import { db } from '../firebase'
import { receptionStateValues, receptionStatusValues } from 'constants/receptions'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { receptionConventer } from 'conventers/receptionConventer'
import { omit, chunk } from 'lodash'
import { useMultiQueryData } from 'hooks/useMultiQueryData'
import { useAddChangelogs } from 'data/changelogs'
import { changelogTypes } from 'constants/changelogs'

/**
 * Get product with id. Also the data keeps updating if it changes in Firestore.
 *
 * TODO: Add loading and error properties to the return value.
 *
 * @param {boolean} getData Set to false if you don't want to get receptions list
 * @returns {object} Containing receptions data
 */
export function useReceptions(getData = true) {
  // Store receptions data to local state
  const [receptions, setReceptions] = useState([])
  const [loading, setLoading] = useState(true)
  const addChangelog = useAddChangelogs()

  // Sync product data
  useEffect(() => {
    if (!getData) return setLoading(false)

    const q = query(
      // Reference (or path) to the data in Firestore.
      collection(db, 'receptions'),
      where('deletedAt', '==', null),
      orderBy('serialNumber', 'desc')
    )

    // Subscribe to data from /receptions
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        setLoading(false)
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })

        // Set receptions data to local state in this hook.
        setReceptions(data)
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscript to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [getData])

  // These functions mutate data in Firestore.
  const mutationsRef = useRef(
    {
      // Update reception in Firestore.
      setReception: (data, receptionId) => {
        const receptionDocRef = doc(db, 'receptions', receptionId)
        return addChangelog(receptionDocRef, changelogTypes.UPDATE, () =>
          setDoc(
            receptionDocRef,
            {
              ...omit(data, 'createdAt', 'purchaseInvoiceCreatedAt', 'updatedAt'),
              updatedAt: serverTimestamp(),
            },
            { merge: true }
          )
        )
      },
      softDeleteReception: receptionId => {
        const receptionDocRef = doc(db, 'receptions', receptionId)
        return addChangelog(receptionDocRef, changelogTypes.DELETE, () =>
          setDoc(
            doc(db, 'receptions', receptionId),
            {
              deletedAt: serverTimestamp(),
            },
            { merge: true }
          )
        )
      },
      addReception: async data => {
        const receptionDocRef = await addDoc(collection(db, 'receptions'), {
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          deletedAt: null,
          ...data,
        })

        await addChangelog(receptionDocRef, changelogTypes.CREATE)

        return receptionDocRef
      },
    },
    []
  )

  return {
    // Receptions data from Firebase
    receptions,
    loading,

    // Helper
    getSeller: () => receptions?.seller,

    // Spread Firestore mutation functions.
    ...mutationsRef.current,
  }
}

export function useReceptionsByIds(ids) {
  // Store receptions data to local state
  const queries = useMemo(
    () =>
      chunk(ids || [], 10).map(ids => {
        return query(collection(db, `receptions`), where(documentId(), 'in', ids))
      }),
    [ids]
  )
  const [receptions, loading] = useMultiQueryData(...queries)

  return {
    // Receptions data from Firebase
    receptions,
    loading,
  }
}

/**
 * Get product with id. Also the data keeps updating if it changes in Firestore.
 *
 * TODO: Add loading and error properties to the return value.
 *
 * @returns {object} Containing receptions data
 */
export function useReceptionsForAssignedUser(email) {
  // Store receptions data to local state
  const [receptions, setReceptions] = useState([])

  // Sync product data
  useEffect(() => {
    if (!email) return

    const q = query(
      // Reference (or path) to the data in Firestore.
      collection(db, 'receptions'),
      where('deletedAt', '==', null),
      where('personnelAssigned', '==', email),
      where('mightBuyProducts', '==', true),
      where('status', '==', receptionStatusValues.CREATED)
    )

    // Subscribe to data from /receptions
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })

        // Set receptions data to local state in this hook.
        setReceptions(data)
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscript to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [setReceptions, email])

  return {
    // Receptions data from Firebase
    receptions,
  }
}

export function useClientReceptions(id) {
  // Store receptions data to local state
  const q = useMemo(() => {
    if (!id) return undefined

    return query(
      // Reference (or path) to the data in Firestore.
      collection(db, 'receptions'),
      where('deletedAt', '==', null),
      where('sellerId', '==', id)
    ).withConverter(receptionConventer)
  }, [id])

  const [receptions, loading, error] = useCollectionData(q)

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return {
    // Receptions data from Firebase
    receptions,
    loading,
    error,
  }
}

export const useApprovedReceptions = () => {
  const [receptions = [], loading, error] = useCollectionData(
    query(
      collection(db, 'receptions'),
      where('status', 'in', [receptionStatusValues.APPROVED, receptionStatusValues.COMPENSATED]),
      where('deletedAt', '==', null),
      orderBy('createdAt', 'desc')
    ).withConverter(receptionConventer)
  )

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return [receptions, loading]
}

export const useOpenReceptionsForAssignedUser = email => {
  const [receptions = [], loading, error] = useCollectionData(
    (email &&
      query(
        collection(db, 'receptions'),
        where('personnelAssigned', '==', email),
        where('state', '==', receptionStateValues.OPEN),
        where('deletedAt', '==', null),
        orderBy('createdAt', 'desc')
      ).withConverter(receptionConventer)) ||
      undefined
  )

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return [receptions, loading]
}

export const useOpenReceptions = () => {
  const [receptions = [], loading, error] = useCollectionData(
    query(
      collection(db, 'receptions'),
      where('state', '==', receptionStateValues.OPEN),
      where('deletedAt', '==', null),
      orderBy('createdAt', 'desc')
    ).withConverter(receptionConventer)
  )

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return [receptions, loading]
}

export const useOpenReceptionsForExperts = uid => {
  const [receptions = [], loading, error] = useCollectionData(
    (uid &&
      query(
        collection(db, 'receptions'),
        where('experts', 'array-contains', uid),
        where('state', '==', receptionStateValues.OPEN),
        where('deletedAt', '==', null),
        orderBy('createdAt', 'desc')
      ).withConverter(receptionConventer)) ||
      undefined
  )

  useEffect(() => {
    if (error) {
      console.error(error)
    }
  }, [error])

  return [receptions, loading]
}

export const useReceptionsByState = state => {
  const [receptions, loading, error] = useCollectionData(
    state &&
      query(
        collection(db, 'receptions'),
        where('state', '==', state),
        where('deletedAt', '==', null),
        orderBy('serialNumber', 'desc')
      ).withConverter(receptionConventer)
  )

  useEffect(() => {
    if (error) console.error(error)
  }, [error])

  return { receptions, loading, error }
}

export const VerificationStatuses = {
  DRAFT: 'draft',
  BLOCKED: 'blocked',
  BASIC_INFO_FILLED: 'basic_info_filled',
  PENDING: 'pending',
  ACCEPTED: 'accepted',
}

export const getProfileVerificationStatusColor = value => {
  switch (value) {
    case VerificationStatuses.ACCEPTED:
      return 'success'
    case VerificationStatuses.PENDING:
      return 'warning'
    case VerificationStatuses.BLOCKED:
      return 'error'
    default:
      return 'info'
  }
}

export const defaultProfileSeller = Object.freeze({
  accountNumber: null,
  commission: 15,
  fee: 19.75,
})

export const AccountType = Object.freeze({
  PERSONAL: 'personal',
  BUSINESS: 'business',
})

export const defaultProfileValues = Object.freeze({
  accountType: AccountType.PERSONAL,
  email: '',
  firstName: '',
  company: '',
  lastName: '',
  phone: '',
  commission: 22,
  identity: {
    address: '',
    country: '',
    zip: '',
  },
  verified: false,
  maxBiddingLevel: null,
  verificationStatus: VerificationStatuses.DRAFT,
  isSeller: false,
  seller: null,
  notifications: {
    winning: false,
    notWinning: true,
  },
})

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Tooltip } from '@mui/material'
import { Colors } from 'constants/colors'
import { useAppTranslation } from 'i18n/hooks'
import { any } from 'prop-types'
import { useState } from 'react'
import { EditModal } from './EditModal'

export const Edit = ({ data }) => {
  const { t } = useAppTranslation()
  const { id, itemId, auctionId, amount } = data
  const [open, setOpen] = useState(false)

  if (!data.isWinning) return null

  return (
    <>
      <Tooltip title={t('edit')}>
        <IconButton onClick={() => setOpen(true)} size="small">
          <FontAwesomeIcon icon={['fas', 'pen-to-square']} color={Colors.MAROON} />
        </IconButton>
      </Tooltip>
      <EditModal
        bidId={id}
        productId={itemId}
        auctionId={auctionId}
        amount={amount}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  )
}

Edit.propTypes = {
  data: any,
}

import { ProductsTable } from 'Components/Shared/ProductsTable'
import { useReceptionAssignedProducts } from 'data/products'
import { useAppTranslation } from 'i18n/hooks'
import { string } from 'prop-types'

export const ReceptionAssignedProducts = ({ email }) => {
  const { products = [], loading } = useReceptionAssignedProducts(email)
  const { t } = useAppTranslation()

  return (
    <ProductsTable products={products} loading={loading} title={t('openItemAcceptancesWhereIAmAssignedReception')} />
  )
}

ReceptionAssignedProducts.propTypes = {
  email: string,
}

import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AgGridColumn } from 'ag-grid-react'
import { GridWrap } from 'Components/Shared/GridWrap'
import { getBidderTypeLabel } from 'constants/bid'
import { Colors } from 'constants/colors'
import { dateTimeFormat } from 'constants/index'
import dayjs from 'dayjs'
import { useDivHeight } from 'hooks/useDivHeight'
import { useSetTableFilters } from 'hooks/useSetTableFilters'
import { useAppTranslation } from 'i18n/hooks'
import { array, bool } from 'prop-types'
import { useEffect, useState } from 'react'
import { filterByDate } from 'utils/agGrid'
import { Delete } from './Delete'
import { Edit } from './Edit'
import { AgLink } from 'Components/Shared/AgLink'
import { red } from '@mui/material/colors'

export const ListTable = ({ products, bids, loading, profiles }) => {
  const {
    i18n: { language },
    t,
  } = useAppTranslation()

  const [bidsData, setBidsData] = useState([])

  useEffect(() => {
    setBidsData(
      bids.map(bid => {
        const product = products.find(({ id }) => id === bid.itemId)
        const profile = profiles?.find(({ id }) => id === bid.userId)
        let userName = bid.isHolmasto
          ? 'Holmasto'
          : profile?.company || [profile?.firstName, profile?.lastName].filter(item => item).join(' ')

        return {
          ...bid,
          date: dayjs(bid.date).format(dateTimeFormat),
          productName: product?.name?.[language] || product?.name?.en,
          lotNumber: product?.sort + 1,
          userName,
          holmastoId: profile?.holmastoId,
          biddedBy: getBidderTypeLabel(bid.biddedBy, t),
        }
      })
    )
  }, [bids, language, products, profiles, t])

  const { ref, height } = useDivHeight(bidsData)
  const { onRowDataChanged, onFilterChanged } = useSetTableFilters()
  if (loading) return null

  return (
    <GridWrap
      defaultColDef={{
        sortable: true,
        filter: true,
        floatingFilter: true,
        suppressSizeToFit: false,
        resizable: true,
      }}
      rowHeight={60}
      rowSelection={'multiple'}
      rowData={bidsData}
      frameworkComponents={{
        edit: Edit,
        isWinning({ value }) {
          return (
            <>
              <FontAwesomeIcon
                color={value ? Colors.LIME_GREEN : Colors.MAROON}
                icon={value ? faCheckCircle : faTimesCircle}
              />
            </>
          )
        },
        delete: Delete,
        link: AgLink,
      }}
      onRowDataChanged={onRowDataChanged}
      onFilterChanged={onFilterChanged}
      height={height}
      ref={ref}
      getRowStyle={({ data }) => {
        if (data.invalid) {
          return { 'background-color': red[200] }
        }
      }}
    >
      <AgGridColumn
        headerClass={'grid_header'}
        field="lotNumber"
        headerName={t('lotNumber')}
        cellRenderer="link"
        cellRendererParams={{
          to: 'product/edit/{itemId}',
        }}
      />
      <AgGridColumn headerClass={'grid_header'} field="productName" headerName={t('name')} />
      <AgGridColumn
        headerClass={'grid_header'}
        field="date"
        headerName={t('date')}
        filter="agDateColumnFilter"
        comparator={filterByDate}
      />
      <AgGridColumn headerClass={'grid_header'} field="userName" headerName={t('userName')} />
      <AgGridColumn
        headerClass={'grid_header'}
        field="holmastoId"
        headerName={t('holmastoId')}
        cellRenderer="link"
        cellRendererParams={{
          to: '/clients/{userId}/view',
        }}
      />
      <AgGridColumn headerClass={'grid_header'} field="amount" headerName={t('amount')} />
      <AgGridColumn headerClass={'grid_header'} field="type" headerName={t('type')} />
      <AgGridColumn headerClass={'grid_header'} field="biddedBy" headerName={t('bidder')} />
      <AgGridColumn
        headerClass={'grid_header'}
        field="isWinning"
        cellRenderer="isWinning"
        headerName={t('isWinning')}
      />
      <AgGridColumn headerClass={'grid_header'} headerName={t('edit')} cellRenderer="edit" />
      <AgGridColumn headerClass={'grid_header'} headerName={t('delete')} cellRenderer="delete" />
    </GridWrap>
  )
}

ListTable.propTypes = {
  bids: array.isRequired,
  loading: bool.isRequired,
  products: array.isRequired,
  profiles: array.isRequired,
}

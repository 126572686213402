import { isEqual, isObject, omit } from 'lodash'

/**
 * @description Function to get not equal properties from object
 *
 * @param {object} obj1
 * @param {object} obj2
 * @param {string} prefix
 * @returns {string[]} returns array keys with different values from two objects
 */
export const getDifferentKeys = (obj1, obj2, prefix, isFirst = true) => {
  const keys = Object.keys(obj1).reduce((keys, key) => {
    const val1 = obj1?.[key]
    const val2 = obj2?.[key]
    // If val1 and val2 are equal there is nothing to do
    if (isEqual(val1, val2)) return keys

    // If value is object calling it with a prefix of key so it'll return foo.bar
    if (isObject(val1) && !Array.isArray(val1) && !(val1 instanceof Date))
      return [...keys, ...getDifferentKeys(val1, val2, prefix ? `${prefix}.${key}` : key)]

    return [...keys, prefix ? `${prefix}.${key}` : key]
  }, [])

  if (!isFirst) return keys

  // Calling function again in reverce orders to include keys which aren't in obj1
  const restKeys = getDifferentKeys(omit(obj2, ...keys), omit(obj1, ...keys), undefined, false)

  return [...keys, ...restKeys]
}

import { Card, CardContent } from '@mui/material'
import { useRequestedProfiles } from 'data/profiles'
import { Table } from './Table'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { useAppTranslation } from 'i18n/hooks'
import { Margin } from 'Components/Ui-Kits/Margin'

export const MaxIncreaseRequests = () => {
  const { profiles } = useRequestedProfiles()
  const { t } = useAppTranslation()

  return (
    <Card>
      <CardContent>
        <Text type={TextTypes.HEADING_34}>{t('bidLimitRequests')}</Text>
        <Margin b={10} />
        <Table items={profiles} />
      </CardContent>
    </Card>
  )
}

import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Colors } from 'constants/colors'
import Grid from 'Components/Ui-Kits/Grid'
import { Controller, useForm } from 'react-hook-form'
import InputText from 'Components/Ui-Kits/Inputs/InputText'
import { Error } from 'Components/Ui-Kits/Text/Error'
import { Margin } from 'Components/Ui-Kits/Margin'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useAppTranslation } from 'i18n/hooks'
import { useAppHistory } from 'routes/dashboard'
import Button from 'Components/Ui-Kits/Buttons'
import styled from 'styled-components'
import { loginAdmin } from 'cloudFunctions'
import { signInWithCustomToken } from 'firebase/auth'
import { useState } from 'react'
import { auth } from 'data/firebase'

const loginSchema = t =>
  Yup.object().shape({
    email: Yup.string().required(t('required')).email(t('notValidEmail')),
    password: Yup.string().required(t('required')),
  })

function Login() {
  const { t } = useAppTranslation()
  const history = useAppHistory()
  const [loading, setLoading] = useState(false)

  const onSubmit = async data => {
    const { email, password } = data
    setLoading(true)
    try {
      const {
        data: { token },
      } = await loginAdmin({ email, password })

      await signInWithCustomToken(auth, token)
      history.push('/home')
    } catch (error) {
      setLoading(false)
      alert(t('wrongCredentials'))
    }
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(loginSchema(t)),
  })

  return (
    <StyledDiv>
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12}>
          <Text type={TextTypes.HEADING_40} color={Colors.BLACK} className={'center-align'}>
            {t('login')}
          </Text>
        </Grid>
        <Grid item xs={10} sm={8} md={6} lg={4} xl={3} mt={2}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur } }) => (
              <InputText
                label={t('email')}
                onChange={onChange}
                type={'email'}
                onBlur={onBlur}
                error={!!errors.email}
                disabled={loading}
              />
            )}
          />
          {errors.email && <Error>{errors.email.message}</Error>}
          <Margin t={5} />
          <Controller
            control={control}
            name="password"
            render={({ field: { onChange, onBlur } }) => (
              <InputText
                label={t('password')}
                onChange={onChange}
                type={'password'}
                onBlur={onBlur}
                error={!!errors.password}
                disabled={loading}
              />
            )}
          />
          {errors.password && <Error>{errors.password.message}</Error>}
          <Margin t={5} />
          <a onClick={() => history.push('/auth/forgot-password')}>{t('forgotPassword')}?</a>
        </Grid>
        <Grid item xs={12} className={'center-align'}>
          <Margin t={5} />
          <Button onClick={handleSubmit(onSubmit)} disabled={loading}>
            {t('login')}
          </Button>
        </Grid>
      </Grid>
    </StyledDiv>
  )
}

Login.propTypes = {}

export default Login

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 30px;

  .center-align {
    text-align: center;
  }
`

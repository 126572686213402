import { omit } from 'lodash'

export const receptionConventer = {
  toFirestore(data) {
    return omit(data, ['id'])
  },
  fromFirestore(doc, options) {
    const data = doc.data(options)

    return {
      ...data,
      createdAt: data?.createdAt?.toDate() || null,
      purchaseInvoiceCreatedAt: data?.purchaseInvoiceCreatedAt?.toDate() || null,
      id: doc.id,
    }
  },
}

import { useAuth } from 'data/auth'
import Grid from 'Components/Ui-Kits/Grid'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Colors } from 'constants/colors'
import { Controller, useForm } from 'react-hook-form'
import InputText from 'Components/Ui-Kits/Inputs/InputText'
import { Error } from 'Components/Ui-Kits/Text/Error'
import { Margin } from 'Components/Ui-Kits/Margin'
import Button from 'Components/Ui-Kits/Buttons'
import { useAppTranslation } from 'i18n/hooks'
import { useAppHistory } from 'routes/dashboard'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import styled from 'styled-components'

const forgotPasswordSchema = t =>
  Yup.object().shape({
    email: Yup.string().required(t('required')).email(t('notValidEmail')),
  })

function ResetPassword() {
  const { t } = useAppTranslation()
  const history = useAppHistory()
  const { sendResetPassLink } = useAuth()

  const onSubmit = data => {
    const { email } = data
    sendResetPassLink(email)
  }

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(forgotPasswordSchema(t)),
  })

  return (
    <StyledDiv>
      <Grid container justifyContent={'center'} spacing={2}>
        <Grid item xs={12}>
          <Text type={TextTypes.HEADING_40} color={Colors.BLACK} className={'center-align'}>
            {t('forgotPassword')}
          </Text>
          <Margin t={5} />
        </Grid>
        <Grid item xs={10} sm={8} md={6} lg={4} xl={3} mt={2}>
          <Controller
            control={control}
            name="email"
            render={({ field: { onChange, onBlur } }) => (
              <InputText label={t('email')} onChange={onChange} type={'email'} onBlur={onBlur} error={!!errors.email} />
            )}
          />
          {errors.email && <Error>{errors.email.message}</Error>}
          <Margin t={5} />
          <a onClick={() => history.push('/auth/login')}>{t('backToLogin')}</a>
        </Grid>
        <Grid item xs={12} className={'center-align'}>
          <Margin t={5} />
          <Button onClick={handleSubmit(onSubmit)}>{t('resetPassword')}</Button>
        </Grid>
      </Grid>
    </StyledDiv>
  )
}

ResetPassword.propTypes = {}

export default ResetPassword

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 30px;

  .center-align {
    text-align: center;
  }
`

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconButton, Tooltip } from '@mui/material'
import { deleteWinningBid } from 'cloudFunctions'
import ConfirmDialog from 'Components/Shared/ConfirmDialog'
import { Colors } from 'constants/colors'
import { useAppTranslation } from 'i18n/hooks'
import { any } from 'prop-types'
import { useState } from 'react'

export const Delete = ({ data }) => {
  const { t } = useAppTranslation()
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const deleteBid = async () => {
    setLoading(true)
    await deleteWinningBid({ bidId: data.id, auctionId: data.auctionId, itemId: data.itemId })
    setLoading(false)
    setOpen(false)
  }

  // Can't delete bids if it's not winning
  if (!data.isWinning) return null

  return (
    <>
      <ConfirmDialog
        onClose={() => setOpen(false)}
        onConfirm={deleteBid}
        open={open}
        message={t('areYouSure')}
        loading={loading}
      />
      <Tooltip title={t('delete')}>
        <IconButton onClick={() => setOpen(true)} size="small">
          <FontAwesomeIcon icon={['fas', 'trash']} color={Colors.MAROON} />
        </IconButton>
      </Tooltip>
    </>
  )
}

Delete.propTypes = {
  data: any,
}

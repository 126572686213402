import { ReceptionsTable } from 'Components/Shared/ReceptionsTable'
import { useOpenReceptionsForExperts } from 'data/receptions'
import { useAppTranslation } from 'i18n/hooks'
import { string } from 'prop-types'

export const ExpertReceptions = ({ uid }) => {
  const [receptions = [], loading] = useOpenReceptionsForExperts(uid)
  const { t } = useAppTranslation()

  return <ReceptionsTable receptions={receptions} loading={loading} title={t('openReceptionIAmExpert')} />
}

ExpertReceptions.propTypes = {
  uid: string,
}

import { any } from 'prop-types'
import { useState } from 'react'
import styled from 'styled-components'
import { getPublicUrl } from 'utils/getPublicUrl'
import noImage from 'assets/no-image.jpg'
import { ImageModal } from 'Components/Shared/ImageModal'

export const Image = ({ data }) => {
  const [open, setOpen] = useState(false)
  const [src, setSrc] = useState(() => getPublicUrl(`/legacy/${data.auctionId}/${data.tempId}.JPG`))

  return (
    <>
      <ImageModal src={src} open={open} onClose={() => setOpen(false)} />
      <StyledImage
        src={src}
        onClick={() => setOpen(true)}
        onError={() => {
          if (src.split('.').pop() === 'JPG') {
            return setSrc(getPublicUrl(`/legacy/${data.auctionId}/${data.tempId}.jpg`))
          }

          setSrc(noImage)
        }}
      />
    </>
  )
}

Image.propTypes = {
  data: any,
}

const StyledImage = styled.img`
  max-width: 49px;
  max-height: 40px;
  display: block;
`

import { createGlobalStyle } from 'styled-components'
import { Colors } from 'constants/colors'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${Colors.WhiteSmoke};
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  .grid_header {
    background-color: ${Colors.DIM_GRAY};
    color: ${Colors.WHITE};
  }
  
  .background_white {
    background-color: ${Colors.WHITE};
  }

  .ag-theme-alpine .ag-cell {
    padding-left: 5px!important;
    padding-right: 5px!important;
    .ag-invisible {
      display: none!important;
    }
  }
`

export default GlobalStyle

export const bidTypes = Object.freeze({
  PRE: 'pre',
  LIVE: 'live',
  AFTER: 'after',
})

export const biddedByValues = Object.freeze({
  USER: 'user',
  SYSTEM: 'system',
  FACILITATOR: 'facilitator',
})

export const getBidderTypeLabel = (type, t) => {
  switch (type) {
    case biddedByValues.SYSTEM:
      return t('system')
    case biddedByValues.USER:
      return t('online')
    default:
      return t('facilitator')
  }
}

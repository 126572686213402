import { any } from 'prop-types'
import { useAuth } from 'data/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { CircularProgress } from '@mui/material'
import styled from 'styled-components'
import { userRoles } from 'constants/users'
import { useUsers } from 'data/users'

function SuperAdminGuard(props) {
  const { auth } = useAuth()
  const [authUser, loading] = useAuthState(auth)
  const { user } = useUsers(authUser?.uid)

  return loading ? (
    <StyledDiv>
      <CircularProgress size={100} />
    </StyledDiv>
  ) : (
    user?.role === userRoles.SUPER_ADMIN && <>{props.children}</>
  )
}

SuperAdminGuard.propTypes = {
  children: any,
}

export default SuperAdminGuard

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`

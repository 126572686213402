import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import Button from 'Components/Ui-Kits/Buttons'
import InputText from 'Components/Ui-Kits/Inputs/InputText'
import { Margin } from 'Components/Ui-Kits/Margin'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Colors } from 'constants/colors'
import { creatingUserAuth } from 'data/firebase'
import { useToast } from 'decorators/Toast'
import { signInWithEmailAndPassword, signOut, updatePassword } from 'firebase/auth'
import { useAppTranslation } from 'i18n/hooks'
import { object } from 'prop-types'
import { useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useAppHistory } from 'routes/dashboard'
import * as Yup from 'yup'

const passwordSchema = t =>
  Yup.object().shape(
    {
      password: Yup.string().min(6, t('passwordShouldBemoreThen6Chars')).nullable().required(t('required')),
      confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], t('passwordsDoesntMatch')),
    },
    ['isEdit']
  )

export const Password = ({ user }) => {
  const { t } = useAppTranslation()
  const [loading, setLoading] = useState()
  const { toast } = useToast()

  const history = useAppHistory()

  const {
    handleSubmit,
    formState: { errors },
    register,
    reset,
    setError,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(passwordSchema(t)),
  })

  const registerInput = useCallback(
    name => {
      return {
        ...register(name),
        error: !!errors[name],
        helperText: errors?.[name]?.message,
      }
    },
    [register, errors]
  )

  const onSave = handleSubmit(async data => {
    setLoading(true)

    try {
      await signInWithEmailAndPassword(creatingUserAuth, user.email, data.oldPassword)
      await signOut(creatingUserAuth)

      await updatePassword(user, data.password)
      toast(t('passwordWasUpdated'))
      reset({ password: '', confirmPassword: '', oldPassword: '' })
    } catch (e) {
      reset({ password: '', confirmPassword: '' })
      setError('oldPassword', { message: t('wrongPassword') })
    }
    setLoading(false)
  })

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Text type={TextTypes.HEADING_40} color={Colors.BLACK}>
            {t('changePassword')}
          </Text>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} mt={2}>
          <InputText type="password" {...registerInput('oldPassword')} label={t('oldPassword')} />
          <Margin t={5} />
          <InputText type="password" {...registerInput('password')} label={t('password')} />
          <Margin t={5} />
          <InputText type="password" {...registerInput('confirmPassword')} label={t('confirmPassword')} />
          <Margin t={5} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Margin t={10} />
        <Button onClick={onSave} disabled={loading} isFetching={loading}>
          {t('changePassword')}
        </Button>
        <Margin display={'inline-block'} r={3} />
        <Button
          color={Colors.TRANSPARENT}
          textColor={Colors.DARK_GRAY}
          onClick={() => history.goBack()}
          disabled={loading}
        >
          {t('goBack')}
        </Button>
      </Grid>
    </>
  )
}

Password.propTypes = {
  user: object,
}

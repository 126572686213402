import { bool, number, string, oneOf, func, arrayOf, oneOfType, object } from 'prop-types'
import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  OutlinedInput,
  Select as MatSelect,
} from '@mui/material'
import Option from './Option'
import { useAppTranslation } from 'i18n/hooks'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react'
import Text, { TextTypes } from 'Components/Ui-Kits/Text'
import { Colors } from 'constants/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Select = forwardRef(
  (
    {
      fullWidth = true,
      options,
      id,
      label,
      error,
      displayNone = true,
      defaultValue,
      required,
      helperText,
      hasSearch,
      paginate,
      size,
      ...rest
    },
    ref
  ) => {
    const { t } = useAppTranslation()
    const innerRef = useRef(null)

    useImperativeHandle(ref, () => innerRef.current)

    const labelText = useMemo(() => {
      return required ? `${label} *` : label
    }, [label, required])
    const [searchText, setSearchText] = useState('')
    const [page, setPage] = useState(1)

    const filteredOptions = useMemo(
      () =>
        [...options]
          .filter(
            ({ title }) => !searchText || title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase().trim())
          )
          .slice(0, paginate ? page * 20 : undefined),
      [options, searchText, paginate, page]
    )

    const handleScrall = event => {
      if (!paginate || page * 20 >= options.length) return
      const leftScroll = event.target.scrollHeight - event.target.scrollTop - event.target.offsetHeight
      if (leftScroll <= 50) {
        setPage(page => page + 1)
      }
    }

    const renderValue = useCallback(
      value =>
        Array.isArray(value)
          ? value
              .map(
                value =>
                  options.find(opt => (/\d+/.test(value) ? opt.value == value : opt.value === value))?.title || ''
              )
              .join(', ')
          : options.find(opt => (/\d+/.test(value) ? opt.value == value : opt.value === value))?.title || '',
      [options]
    )

    return (
      <FormControl fullWidth error={error} size={size}>
        <InputLabel id={id}>{labelText}</InputLabel>
        <MatSelect
          label={labelText}
          labelId={id}
          className={'background_white'}
          fullWidth={fullWidth}
          MenuProps={{
            style: { zIndex: 10001, maxHeight: 400 },
            autoFocus: false,
            PaperProps: {
              onScroll: handleScrall,
            },
          }}
          defaultValue={defaultValue ?? ''}
          inputRef={innerRef}
          renderValue={renderValue}
          {...rest}
          value={rest.value === null ? '' : rest.value}
        >
          {hasSearch && (
            <ListSubheader>
              <OutlinedInput
                size="small"
                autoFocus
                startAdornment={
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon={['far', 'magnifying-glass']} />
                  </InputAdornment>
                }
                label={<></>}
                fullWidth
                placeholder="Search..."
                onChange={e => {
                  setSearchText(e.target.value)
                  setPage(1)
                }}
                onKeyDown={e => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation()
                  }
                }}
              />
            </ListSubheader>
          )}
          {displayNone && !searchText && <Option value={null}>{t('none')}</Option>}
          {filteredOptions.map(item => (
            <Option key={`${item.title}-${item.value}`} value={item.value}>
              {item.title}
            </Option>
          ))}
        </MatSelect>
        {!!helperText && (
          <Text type={TextTypes.BODY_13} color={error ? Colors.CRIMSON : Colors.BLACK}>
            {helperText}
          </Text>
        )}
      </FormControl>
    )
  }
)

export default Select
Select.displayName = 'Select'
Select.propTypes = {
  disabled: bool,
  id: string,
  label: string,
  value: oneOfType([string, number]),
  defaultValue: oneOfType([string, number]),
  fullWidth: bool,
  options: arrayOf(object),
  variant: oneOf(['filled', 'outlined', 'standard']),
  required: bool,
  onChange: func,
  onClose: func,
  onOpen: func,
  error: bool,
  displayNone: bool,
  helperText: string,
  hasSearch: bool,
  paginate: bool,
  size: string,
}

import { bool, func, string } from 'prop-types'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { debounce } from 'lodash'
import { Modal, Backdrop, Fade } from '@mui/material'
import styled from 'styled-components'

export const ImageModal = ({ src, onClose, open }) => {
  const [show, setShow] = useState(open)

  useEffect(() => {
    if (show === open) return

    if (!open) {
      const subscribe = debounce(() => setShow(false), 500)

      return () => subscribe.cancel()
    }

    setShow(open)
  }, [show, open])

  if (!show) return null

  return createPortal(
    <StyledModal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} timeout={500} style={{ outline: 'none' }}>
        <ItemsWrap>
          <img src={src} alt="" style={{ maxWidth: '90%', maxHeight: '90%', zIndex: 1 }} />
        </ItemsWrap>
      </Fade>
    </StyledModal>,
    document.body
  )
}

ImageModal.propTypes = {
  src: string.isRequired,
  open: bool.isRequired,
  onClose: func.isRequired,
}

const StyledModal = styled(Modal)`
  display: flex;
`
const ItemsWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

import { Grid } from '@mui/material'
import { useAuth } from 'data/auth'
import { useAuthState } from 'react-firebase-hooks/auth'
import { AssignedProducts } from './AssignedProducts'
import { AssignedReceptions } from './AssignedReceptions'
import { ExpertReceptions } from './ExpertReceptions'
import { ReceptionAssignedProducts } from './ReceptionAssignedProducts'

export const MyWork = () => {
  const { auth } = useAuth()
  const [user, loading] = useAuthState(auth)

  if (loading) return null

  return (
    <Grid container spacing={4}>
      <Grid xs={12} item>
        <AssignedReceptions email={user.email} />
      </Grid>
      <Grid xs={12} item>
        <ExpertReceptions uid={user.uid} />
      </Grid>
      <Grid xs={12} item>
        <ReceptionAssignedProducts email={user.email} />
      </Grid>
      <Grid xs={12} item>
        <AssignedProducts email={user.email} />
      </Grid>
    </Grid>
  )
}

import { useState, useEffect, useRef } from 'react'
import { collection, doc, onSnapshot, orderBy, query, serverTimestamp, setDoc, where } from 'firebase/firestore'

import { db } from './firebase'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { userConventer } from '../conventers/userConventer'
import { userStatus } from 'constants/users'
import { useAddChangelogs } from './changelogs'
import { changelogTypes } from 'constants/changelogs'

/**
 * Get product with id. Also the data keeps updating if it changes in Firestore.
 *
 * TODO: Add loading and error properties to the return value.
 *
 * @param {string} uid
 * @returns {object} Containing auction data
 */
export function useUsers(uid) {
  // Store auction data to local state
  const [users, setUsers] = useState([])
  const [user, setUser] = useState({})

  // Sync users data
  useEffect(() => {
    if (uid) return
    const q = query(collection(db, 'users'))

    // Subscribe to data from /users
    const unsubscribe = onSnapshot(
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })

        // Set auction data to local state in this hook.
        setUsers(data)
      },
      error => {
        console.error('Failed to sync User data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [setUser, uid])

  // Sync requested user data
  useEffect(() => {
    if (!uid) return
    const q = query(collection(db, 'users'), where('uid', '==', uid))

    // Subscribe to data from /users
    const unsubscribe = onSnapshot(
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })
        const [first] = data

        // Set products data to local state in this hook.
        setUser(first ?? {})
      },
      error => {
        console.error('Failed to sync User data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [setUser, uid])

  // These functions mutate data in Firestore.
  const mutationsRef = useRef(
    {
      // Update reception in Firestore.
      editUser: (data, id) => {
        return setDoc(
          doc(db, 'users', id),
          {
            ...data,
            updatedAt: serverTimestamp(),
          },
          { merge: true }
        )
      },
    },
    []
  )

  return {
    // auction data from Firebase
    users,
    user,
    // Spread Firestore mutation functions.
    ...mutationsRef.current,
  }
}

export const useUser = id => {
  // Store auction data to local state
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)
  const addChangelog = useAddChangelogs()

  // Sync requested user data
  useEffect(() => {
    if (!id) return
    setLoading(true)
    const docRef = doc(db, 'users', id)

    // Subscribe to data from /users
    const unsubscribe = onSnapshot(
      docRef,
      // This callback is called when data is initially fetched and when it changes.
      doc => {
        // Set products data to local state in this hook.
        setUser({ ...doc.data(), id: doc.id })
        setLoading(false)
      },
      error => {
        console.error('Failed to sync User data', error)
        setLoading(false)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [setUser, id])

  // These functions mutate data in Firestore.
  const mutationsRef = useRef(
    {
      // Update reception in Firestore.
      editUser: (data, id) => {
        delete data.createdAt
        const userDoc = doc(db, 'users', id)
        return addChangelog(userDoc, changelogTypes.UPDATE, () =>
          setDoc(
            userDoc,
            {
              ...data,
              updatedAt: serverTimestamp(),
            },
            { merge: true }
          )
        )
      },
    },
    []
  )

  return {
    // auction data from Firebase
    user,
    loading,
    // Spread Firestore mutation functions.
    ...mutationsRef.current,
  }
}

export const useActiveUsersByRoles = (...roles) => {
  const [users = [], loading, error] = useCollectionData(
    query(
      collection(db, 'users'),
      where('role', 'in', roles),
      where('status', '==', userStatus.ACTIVE),
      orderBy('firstName', 'asc'),
      orderBy('lastName', 'asc')
    ).withConverter(userConventer)
  )

  useEffect(() => {
    if (error) console.error('error')
  }, [error])

  return { users, loading }
}

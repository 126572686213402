import { number, oneOfType, string } from 'prop-types'
import { MenuItem } from '@mui/material'

const Option = ({ value, children, ...rest }) => {
  return (
    <MenuItem value={value} {...rest}>
      {children}
    </MenuItem>
  )
}

export default Option

Option.propTypes = {
  children: oneOfType([string, number]),
  value: oneOfType([string, number]),
}

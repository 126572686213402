const en = Object.freeze({
  hello: 'Hello',
  home: 'Home',
  receiptPosting: 'Receipt posting',
  products: 'Products',
  addProduct: 'Add product',
  addNewProduct: 'Add new product',
  auctions: 'Auctions',
  welcome: 'Welcome',
  reception: 'Reception',
  addReception: 'Add reception',
  seller: 'Seller',
  dateOfDropOff: 'Date of drop off',
  estimatedWorth: 'Estimated worth',
  associatedWith: 'Associated with',
  changelog: 'Changelog',
  save: 'Save',
  goBack: 'Go Back',
  UseOldProduct: 'Use old product',
  comment: 'Comment',
  description: 'Description',
  english: 'English',
  finnish: 'Finnish',
  swedish: 'Swedish',
  addToAuction: 'Add to auction',
  technicalInformation: 'Technical information',
  material: 'Material',
  fitnessRating: 'Fitness rating',
  reference: 'Reference',
  year: 'Year',
  country: 'Country',
  nominalValue: 'Nominal value',
  weight: 'Weight',
  size: 'Size',
  nextSteps: 'Next steps',
  nextStep: 'Next step',
  andThen: 'And then',
  publish: 'Publish',
  saveDraft: 'Save draft',
  editSelectedReceptions: 'Edit selected receptions',
  editReception: 'Edit reception',
  upload: 'Upload',
  uploading: 'Uploading',
  personnelAssigned: 'Personnel assigned',
  status: 'Status',
  productStatus: 'Product Status',
  featuredImage: 'Featured image',
  required: 'Required!',
  adminNote: 'Admin note',
  name: 'Name',
  condition: 'Condition',
  conditionDescription: 'Condition description',
  quantity: 'Quantity',
  ruler: 'Ruler',
  olympia: 'Olympia',
  designer: 'Designer',
  manufacturer: 'Manufacturer',
  taxableStatus: 'Taxable status',
  webShopPrice: 'Webshop price',
  purchasedPrice: 'Purchased price',
  AuctionMinimumPrice: 'Auction minimum price',
  estimatedPrice: 'Estimated price',
  productCategory: 'Product category',
  editNewProduct: 'Edit New Product',
  editProducts: 'Edit Products',
  editSelectedProducts: 'Edit selected products',
  filter: 'Filter',
  inAuction: 'In auction',
  inWebshop: 'In webshop',
  notSold: 'Not sold',
  bulkEdit: 'Bulk Edit',
  image: 'image',
  date: 'Date',
  edit: 'Edit',
  assignToWoocommerce: 'Assign to woocommerce',
  assignToAuction: 'Assign to auction',
  putIntoStorage: 'Put into storage',
  holmastoWillBuyIt: 'Holmasto will buy it',
  holmastoWillSellIt: 'Holmasto will sell it on behalf of seller',
  returnToSeller: 'Return to seller',
  auction: 'Auction',
  addAuction: 'Add auction',
  auctionId: 'Auction Id',
  startDate: 'Start date',
  estimatedValue: 'Estimated value',
  categories: 'Categories',
  uncategorizedProduct: 'Products, Added to auction but not categorised',
  login: 'Login',
  forgotPassword: 'Forgot password',
  resetPassword: 'Reset password',
  notValidEmail: 'Should be a valid email!',
  backToLogin: 'Back to Login',
  createdAt: 'Created at',
  email: 'Email',
  users: 'Users',
  addUser: 'Add user',
  firstName: 'First name',
  lastName: 'Last name',
  postalCode: 'Postal code',
  admin: 'Admin',
  shippingContainer: 'Shipping container',
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  requiresSpecialHandling: 'Requires special handling',
  owner: 'Owner',
  proposedPrice: 'Proposed price',
  proposalText: 'Proposal text',
  city: 'City',
  areYouSure: 'Are you sure?',
  cancel: 'Cancel',
  delete: 'Delete',
  thisActionCanNotBeUndone: 'This action can not be undone!',
  warehouseId: 'warehouse ID',
  lengthShouldBe1: 'Length should Be 1',
  onlyCapitalAlphabeticChars: 'Only capital alphabetic character',
  addClient: 'Add Client',
  active: 'Active',
  inactive: 'Inactive',
  sentForApproval: 'Send for approval',
  draft: 'Draft',
  rejected: 'Rejected',
  accepted: 'Accepted',
  pending: 'Pending',
  holmasto: 'Holmasto',
  customer: 'Customer',
  itemStatus: 'Item Status',
  noImage: 'NO IMAGE',
  noInformation: 'NO INFORMATION',
  createCategory: 'Create Category',
  editCategory: 'Edit Category',
  label: 'Label',
  categoryImage: 'Category Image',
  chooseImage: 'Choose Image',
  choose: 'Choose',
  category: 'Category',
  productsYoureEditing: "Products you're editing",
  productsWhereUpdated: 'Products where updated',
  changeFieldsToUpdate: 'Change fields to update',
  updateAll: 'Update All',
  auctionCategory: 'Auction Category',
  productCategories: 'Product Categories',
  sku: 'SKU',
  maxLengthIsThree: 'Field contain max 3 characters',
  editAuction: 'Edit Auction',
  generatePdf: 'Generate PDF',
  markAsComplete: 'Mark as Complete',
  experts: 'Experts',
  completed: 'Completed',
  incomplete: 'Incomplete',
  canBeSentByAssignedPersona: 'Proposal can be sent by Assigned user only',
  sendProposal: 'Send proposal',
  publishForPrebid: 'Publish for Prebid',
  editUser: 'Edit User',
  tags: 'Tags',
  tag: 'Tag',
  addTag: 'Add Tag',
  editTag: 'Edit Tag',
  from: 'From',
  to: 'To',
  images: 'Images',
  superAdmin: 'Super Admim',
  role: 'Role',
  client: 'Client',
  disable: 'Disable',
  enable: 'Enable',
  parent: 'Parent',
  newPasswordWasSentToUser: 'New password was sent to the user',
  changePassword: 'Change Password',
  profile: 'Profile',
  logOut: 'Log out',
  password: 'Password',
  confirmPassword: 'Confirm Password',
  passwordsDoesntMatch: "Passwords doesn't match",
  passwordWasUpdated: 'Password was updated',
  profileWasUpdated: 'Profile was updated',
  passwordShouldBemoreThen6Chars: 'Password should be more then 6 characters',
  wrongPassword: 'Wrong password',
  currentPassword: 'Current Password',
  mightBuyLabel: 'Includes products Holmasto might buy',
  soldOnBehalfLabel: 'Includes products sold on behalf of the customers',
  internalNote: 'Internal Note',
  startDatePreBid: 'Prebid Start',
  createTag: 'Create tag',
  endDatePreBid: 'Pre-Bid End Date',
  auctionIdShouldBeUnique: 'Auction id should be unique',
  more: 'More',
  less: 'Less',
  itemNumber: 'Item Number',
  dateTime: 'Datetime',
  user: 'User',
  userId: 'User Id',
  bid: 'Bid',

  bids: 'Bids',
  amount: 'Amount',
  addBid: 'Add Bid',
  minimumAmountIs: 'Minimum amount is',
  increase: 'Increase',
  timeToMakeAnOffer: 'Aikaa tehdä tarjous (en)',
  nSeconds: '{{ n }} sek (en)',
  timerLength: 'Tarjousajan pituus (en)',
  startAgain: 'ALOITA UUDESTAAN (en)',
  pause: 'PYSÄYTÄ (en)',
  next: 'SEURAAVA (en)',
  liveAuctionHasEndedAt: 'Live auction has ended at {{ date }}',
  paletteNumber: 'Palette number',
  fieldShouldBeUnique: 'This field should be unique',
  liveAuction: 'Live auction',
  shouldBeInteger: 'This field should be integer',
  attachUserOnBid: 'Attach user on bid',
  complete: 'Complete',
  bidding: 'Bidding',
  bidder: 'Bidder',
  nextItem: 'Seuraava kohde',
  itemNumberNofT: 'Kohdenumero {{ n }} / {{ t }}',
  raise: 'RISE',
  minimumRiseN: 'Rise (minimum {{ n }} €)',
  closeCurrentItem: 'CLOSE CURRENT ITEM',
  orders: 'Orders',
  items: 'Items',
  phoneNumber: 'Phone Number',
  bidCount: 'Bid count',
  myWork: 'My work',
  clients: 'Clients',
  holmastoId: 'Holmasto ID',
  verificationStatus: 'Verification Status',
  isVerified: 'Verified',
  editClientN: 'Edit client {{ holmastoId }}',
  userDocuments: 'User Documents',
  note: 'Note',
  notes: 'Notes',
  inWarehouse: 'In warehouse',
  auctionStatus: 'Auction status',
  maxBiddingLevel: 'Max bidding level',
  copyProduct: 'Copy Product',
  productWasSaved: 'Product was saved',
  productWasCreated: 'Product was created',
  publishOnline: 'Publish Online',
  markAsFeatured: 'Mark as featured',
  itemsCountN: 'Items count: {{ n }}',
  bidsCountN: 'Bids count: {{ n }}',
  auctionN: 'Auction {{ n }}',
  itemsSoldN: 'Sold items: {{ n }}',
  trendingItems: 'Trending Items',
  trendingCategories: 'Trending Categories',
  price: 'Price',
  unpublish: 'Unpublish',
  compensate: 'Compensate',
  undoCompensation: 'Undo Compensation',
  compensated: 'Compensated',
  yes: 'Yes',
  no: 'No',
  sum: 'Sum',
  commission: 'Commission',
  sellerReceives: 'Seller receives',
  sellerCommissions: 'Seller Commissions',
  sellerProducts: 'Seller products',
  startAmount: 'Start amount',
  soldAmount: 'Sold amount',
  sellerCommissionWasUpdated: 'Seller commission was updated',
  update: 'Update',
  auctionHasUnpublishedProducts: 'Auction has unpublished product, would you like to continue?',
  editSeller: 'Edit Seller',
  sellers: 'Sellers',
  accountNumber: 'Bank account number',
  countries: 'Countries',
  none: 'None',
  receptionWasSaved: 'Reception was saved',
  attachedCard: 'Attached Card',
  lastDigits: 'Last Digits',
  expirationDate: 'Expiration Date',
  verified: 'Is verified',
  isSeller: 'Is Seller',
  accountType: 'Account Type',
  personal: 'Personal',
  business: 'Business',
  shippingOrderStatus: 'Shipping order status',
  manageOrder: 'Manage order',
  manageShippingOrder: 'Manage shipping order',
  serialNumber: 'Serial Number',
  zipCode: 'Zip code',
  liveAuctionStarted: 'Live auction started',
  liveAuctionEnded: 'Live auction ended',
  shippingIsSameAsBilling: 'Shipping address is same as billing address',
  shippingAddress: 'Shipping Address',
  billingAddress: 'Billing Address',
  nationality: 'Nationality',
  bidLimitRequests: 'Bid Limit requests',
  disabled: 'Disabled',
  editBid: 'Edit bid',
  notifyWhenBidIsWinning: 'Notify when bid is winning',
  notifyWhenBidIsntWinning: "Notify when bid isn't winning",
  notifications: 'Notifications',
  wrongCredentials: 'Wrong Credentials',
  generateExcel: 'Generate Excel',
  alreadyCompensated: 'Already compensated',
  sellerGets: 'Seller gets',
  invoice: 'Invoice',
  approvedReceptions: 'Approved Receptions',

  passwordConfirmation: 'Password confirmation',
  passwordShouldBeMinimum: 'Password should be kinimum {{ minimum }}',
  passwordsMustMatch: 'Passwords must match',
  userCantBeDeletedBecauseHeHasReceptions: "User can't be deleted because he/she has receptions",
  userCantBeDeletedBecauseHeHasBids: "User can't be deleted because he/she has bids",
  unknownError: 'Unknknown error',
  userWasDeleted: 'User was deleted',
  deleteUser: 'Delete user',
  areYouSureCantUndone: "Are you sure? Action can't be undone",
  confirmDeleteReception: "Do you really want to delete the reception? This action can't be undone",
  isWinning: 'Is winning',
  userName: 'User Name',
  lotNumber: 'Lot number',
  notVerified: 'Not verified',
  marginalRate: 'Marginal rate',
  vatPercent: 'Vat percent',
  openOrders: 'Open orders',
  createWebshopOrder: 'Create webshop order',
  sellerCommission: 'Seller Commission',
  sellerFee: 'Seller Fee',
  generateSoldProductExcel: 'Generate sold product excel',
  system: 'System',
  online: 'Online',
  facilitator: 'Facilitator',
  wonItems: 'Won items',
  wonPrice: 'Won price',
  receptions: 'Receptions',
  soldProducts: 'Sold products',
  paidWith: 'Paid with',
  shipment: 'Shipment',
  total: 'Total',
  view: 'View',
  state: 'State',
  open: 'Open',
  closed: 'Closed',
  deadline: 'Deadline',
  openReceptionIAmPersonalAssigned: 'Open receptions where I am personnel assigned',
  openReceptionIAmExpert: 'Open receptions where I am expert',
  id: 'ID',
  daysBeforeDeadline: 'Days before deadline',
  openItemAcceptancesWhereIAmAssignedReception: 'Open item acceptances where I am personnel assigned in reception',
  openItemAcceptancesWhereIAmAssigned: 'Open item acceptances where I am personnel assigned',
  receptionId: 'Reception ID',
  receptionPersonell: 'Reception Personell',
  customerName: 'Customer name',
  sellerName: 'Seller name',
  bidsTotal: 'Bids total',
  uniqueCustomersWithBids: 'Unique customers with bids',
  itemsWithBids: 'Items with bids',
  sumOfWinningPreBids: 'Sum of winning pre bids',
  sumOfWinningLiveBids: 'Sum of winning live bids',
  itemsWithMostBids: 'Items with most bids',
  categoriesWithMostBids: 'Categories with most bids',
  kpi: 'KPI',
  auctionTotals: 'Auction totals',
  openReceptions: 'Open receptions',
  openItems: 'Open items',
  newCustomers: 'New customers',
  purchaseInvoiceCreated: 'Purchase invoice created',
  requestedAt: 'Requested at',
  payments: 'Payments',
  totalSum: 'Total sum',
  completedOrders: 'Completed orders',
  shippingMethod: 'Shipping Method',
  buyerPays: 'Buyer pays',
  orderItems: 'Order items',
  acceptedReceptionProposals: 'Accepted reception proposals',
  notCompensated: 'Not compensated',
  numberOfItems: 'Number of items',
  paymentStatus: 'Payment status',
  compensationStatus: 'Compensation status',
  fee: 'Fee',
  sellerItems: 'Seller Items',
  sellerSoldItems: 'Seller sold items',
  compensatedWithoutPurchaseInvoice: 'Compensated without purchase invoice',
  compensateSeller: 'Compensate Seller',
  createPurchaseInvoices: 'Create purchase invoice for selected',
  markPaidInStore: 'Mark paid in store',
  language: 'Language',
  generateContract: 'Generate contract',
  verificationMethod: 'Verification method',
  basic_info_filled: 'Basic info filled',
  copyProductToNewAuction: 'Copy product to new auction',
  copyToAuction: 'Copy to auction',
  totalCommission: 'Total Commission',
  boughtBy: 'Bought by',
  itemAcceptance: 'Item acceptance',
  unsavedChangesPrompt: 'There are some unsaved changes',
  clientWasUpdated: 'Client was updated',
  clientWasCreated: 'Client was created',
  categoryWasUpdated: 'Category was updated',
  categoryWasCreated: 'Category was created',
  tagWasUpdated: 'Tag was updated',
  tagWasCreated: 'Tag was created',
  receptionWasUpdated: 'Reception was updated',
  receptionWasCreated: 'Reception was created',
  timerIsStillActive: 'Timer is still active',
  onlineCustomers: 'Online Customers',
  otherCustomers: 'Other Customers',
  evaluation: 'Evaluation',
  toSell: 'To sell',
  estateInventory: 'Estate inventory',
  insuranceEvaluation: 'Insurance evaluation',
  gemmologicalExamination: 'Gemmological examination',
  purposeOfSale: 'Purpose of reception',
  toAuction: 'To auction',
  salesOrder: 'Sales order',
  notifySeller: 'Notify seller',
  oneTimeCustomers: 'One Time customers',
  paidInStore: 'Paid in store',
  before: 'Before',
  after: 'After',
  verifyUsers: 'Verify users',
  internalError: 'Internal Error',
  notificationsWhereScheduled: 'Email notifications where scheduled',
  proposal: 'Proposal',
  proposalPdf: 'Proposal PDF',
  printTheReceptionReceipt: 'Print reception receipt',
  enableEdit: 'Enable',
  disableEdit: 'Ota pois käytöstä (en)',
  approved: 'Approved',
  created: 'Created',
  readyToSend: 'Ready to send',
  published: 'Published',
  declined: 'Rejected',
  auctionCountdown:
    'Auction should start in {{ m }} months, {{ d }} days, {{ h }} hours , {{ mi }} minutes and {{ s }} seconds',
  notifyUsersToPay: 'Notify users to pay',
  sentWaitingForAnswer: 'Sent, waiting for answer',
  liveAuctionWillStartIn: 'Live auction will start in',
  other: 'Other',
  pendingEmails: 'Email address change log',
  resendEmail: 'Resend Email',
  yourRequestHasCompleted: 'Your request has been completed',
  userWasAlreadyNotifiedNotifyAgain: 'Email was already send. Do you want to send again?',
  companyName: 'Company name',
  proposalSent: 'Proposal sent',
  tools: 'Tools',
  notifiSellerReceptionReceived: 'Send email: We have received your items for evaluation',
  printTheQrCode: 'Print QR code',
  directPurchase: 'Direct purchase',
  notifyReceptionEvaluationHasCompleted: 'Send email: The evaluation is completed!',
  printSalesReceipt: 'Print sales contract',
  createPurchaseInvoice: 'Create purchase invoice',
  purchaseInvoiceWasCreated: 'Purchase invoice was created',
  sendSalesContract: 'Send email: Sales contract',
  printSalesContract: 'Print sales contract',
  notificationWasSent: 'Notification was sent',
  auctionHasNoProducts: 'Please add products to auction before you start live auction',
  unPublished: 'Un-published',
  saveChanges: 'Save changes',
  fastTrackReceptions: 'Fast track deals',
  addFastTrackReception: 'Add deal',
  socialSecurityNumber: 'Date of birth or/+ PIN',
  paymentMethod: 'Payment Method',
  noData: "You haven't added items yet",
  taxClass: 'Tax class',
  fastBuyItems: 'Merchandise',
  updateFastTrackReception: 'Update fast deal',
  markAsCompensated: 'Mark as compensated in store',
  address: 'Address',
  phone: 'Phone',
  fastTrackReceptionWasUpdated: 'Fast track deal was updated',
  fastTrackReceptionWasCreated: 'Fast track deal was created',
  add: 'Add',
  history: 'History',
  cash: 'Cash',
  netvisor: 'Netvisor',
  productCantBePublished: "Productb {{ sku }} can't be published",
  setStatus: 'Update the status of selected products',
  accountNumberIsRequired: 'In order to create purchase invoice fill account number',
  addRow: 'Add row',
  receipts: 'Receipts',
  form: 'Form',
  createSalesContract: 'Create sales contract',
  runningNumber: 'Running number',
  productWasUnsold: 'Product was unsold',
  sellToUser: 'Sell to user',
  youReAboutToChangeAssignedAuctionId: "You're about to change assigned auction",
  youCantChangeSoldProductsAuction: "You can't change assigned auction for sold product",
  itemWasSoldToTheUser: 'Items as sold to user',
  printActions: 'Print actions',
  editProduct: 'Edit product',
  unlockForEditing: 'Unlock for editing',
  lockForEditing: 'Lock for editing',
  afterAuctionTools: 'After auction tools',
  unsell: 'Unsell',
  purchaseItemToHolmasto: 'Purchase item to holmasto',
  moveToAnotherAuction: 'Move to another auction',
  previewProductInCatalogue: 'Preview auction in catalogue',
  returnedToSeller: 'Returned to seller',
  sold: 'Sold',
  current: 'Current',
  waiting: 'Waiting',
  biddingEnded: 'Bidding ended',
  readyForCatalogue: 'Ready for catalogue',
  actions: 'Actions',
  soldToHolmasto: 'Sold to holmasto',
  movedFromEarlierAuction: 'Moved from earlier auction',
  itemCreated: 'Created',
  updated: 'Updated',
  grouped: 'Grouped',
  list: 'List',
  type: 'Type',
  verfictionStatus: 'Verification status',
  disableFromCatalogue: 'Disable from catalogue',
  enableInCatalogue: 'Enable in catalogue',
  featuredImages: 'Featured Images',
  startPrice: 'Start price',
  filterItemDescription: 'Filter item description',
  copy: 'Copy',
  historyItems: 'History items',
  itemWasCompied: 'Item was copied',
  createWebshopProduct: 'Create webshop product',
  webshopProduct: 'Webshop product',
  editWebshopProduct: 'Edit webshop product ({{ lang }})',
  showFullTable: 'Show full table',
  tempId: 'Temporary id',
  archived: 'Archived',
  archive: 'Archive',
  archiveLabel: 'Archive',
  archiveReception: 'Archive',
  unarchive: 'Unarchive',
  proposalWasSent: 'Sales contract was sent',
})

export default en

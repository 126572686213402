const sv = Object.freeze({
  hello: 'Hej',
  home: 'Home (sv)',
  receiptPosting: 'Receipt Posting (sv)',
  products: 'Products (sv)',
  addProduct: 'Add product (sv)',
  addNewProduct: 'Add new product (sv)',
  auctions: 'Auctions (sv)',
  welcome: 'welcome (sv)',
  reception: 'Reception',
  addReception: 'Add reception (sv)',
  seller: 'Seller (sv)',
  dateOfDropOff: 'Date of drop off (sv)',
  estimatedWorth: 'Estimated worth (sv)',
  associatedWith: 'Associated with (sv)',
  changelog: 'Changelog (sv)',
  save: 'Save (sv)',
  goBack: 'Go Back (sv)',
  UseOldProduct: 'Use old product (sv)',
  comment: 'Comment (sv)',
  description: 'Beskrivning',
  english: 'English (sv)',
  finnish: 'Finnish (sv)',
  swedish: 'Swedish (sv)',
  addToAuction: 'Add to auction (sv)',
  technicalInformation: 'Technical information (sv)',
  material: 'Material (sv)',
  fitnessRating: 'Fitness rating (sv)',
  reference: 'Reference (sv)',
  year: 'Year (sv)',
  country: 'Country (sv)',
  nominalValue: 'Nominal value (sv)',
  weight: 'Weight (sv)',
  size: 'Size (sv)',
  nextSteps: 'Next steps (sv)',
  nextStep: 'Next step (sv)',
  andThen: 'And then (sv)',
  publish: 'Publish (sv)',
  saveDraft: 'Save Draft (sv)',
  editSelectedReceptions: 'Edit selected receptions (sv)',
  editReception: 'Edit reception (sv)',
  upload: 'Upload (sv)',
  uploading: 'Uploading (sv)',
  personnelAssigned: 'Personnel assigned (sv)',
  status: 'Status',
  productStatus: 'Product Status (sv)',
  featuredImage: 'Featured image (sv)',
  required: 'Required! (sv)',
  adminNote: 'Admin note (sv)',
  name: 'Name (sv)',
  condition: 'Condition (sv)',
  conditionDescription: 'Condition description (sv)',
  quantity: 'Quantity (sv)',
  ruler: 'Ruler (sv)',
  olympia: 'Olympia (sv)',
  designer: 'Designer (sv)',
  manufacturer: 'Manufacturer (sv)',
  taxableStatus: 'Taxable status (sv)',
  webShopPrice: 'Webshop price (sv)',
  purchasedPrice: 'Purchased price (sv)',
  AuctionMinimumPrice: 'Auction minimum price (sv)',
  estimatedPrice: 'Estimated price (sv)',
  productCategory: 'Product category (sv)',
  editNewProduct: 'Edit New Product (sv)',
  editProducts: 'Edit Products (sv)',
  editSelectedProducts: 'Redigera valda produkter',
  filter: 'Filter (sv)',
  inAuction: 'In auction (sv)',
  inWebshop: 'In webshop (sv)',
  notSold: 'Not sold (sv)',
  bulkEdit: 'Bulk Edit (sv)',
  image: 'image (sv)',
  date: 'Date (sv)',
  edit: 'Edit (sv)',
  assignToWoocommerce: 'Assign to woocommerce (sv)',
  AssignToAuction: 'Huutokauppaan',
  PutIntoStorage: 'Put into storage (sv)',
  holmastoWillBuyIt: 'Holmasto will buy it (sv)',
  holmastoWillSellIt: 'Holmasto will sell it on behalf of seller (sv)',
  returnToSeller: 'Return to seller (sv)',
  auction: 'Auction (sv)',
  addAuction: 'Add auction (sv)',
  auctionId: 'Auction Id (sv)',
  startDate: 'Start date (sv)',
  estimatedValue: 'Estimated value (sv)',
  categories: 'Categories (sv)',
  uncategorizedProduct: 'Products, Added to auction but not categorised (sv)',
  login: 'Login (sv)',
  forgotPassword: 'Forgot password (sv)',
  resetPassword: 'Reset password (sv)',
  notValidEmail: 'Should be a valid email! (sv)',
  backToLogin: 'Back to Login (sv)',
  createdAt: 'Created at (sv)',
  email: 'Email',
  users: 'Users (sv)',
  addUser: 'Add user (sv)',
  firstName: 'Förnamn',
  lastName: 'Efternamn',
  postalCode: 'Postal code (sv)',
  admin: 'Admin (sv)',
  shippingContainer: 'Shipping container (sv)',
  small: 'Small (sv)',
  medium: 'Medium (sv)',
  large: 'Large (sv)',
  requiresSpecialHandling: 'Requires special handling (sv)',
  owner: 'Owner (sv)',
  proposedPrice: 'Proposed price (sv)',
  proposalText: 'Proposal text (sv)',
  city: 'City',
  areYouSure: 'Are you sure? (sv)',
  cancel: 'Cancel (sv)',
  delete: 'Delete (sv)',
  thisActionCanNotBeUndone: 'This action can not be undone! (sv)',
  warehouseId: 'warehouse ID (sv)',
  lengthShouldBe1: 'Length should Be 1 (sv)',
  onlyCapitalAlphabeticChars: 'Only capital alphabetic character (sv)',
  addClient: 'Add Client (sv)',
  active: 'Active (sv)',
  inactive: 'Inactive (sv)',
  pending: 'Pending (sv)',
  holmasto: 'Holmasto (sv)',
  customer: 'Customer (sv)',
  itemStatus: 'Item Status (sv)',
  noImage: 'NO IMAGE (sv)',
  noInformation: 'NO INFORMATION (sv)',
  createCategory: 'Create Category (sv)',
  editCategory: 'Edit Category (sv)',
  label: 'Label (sv)',
  categoryImage: 'Category Image (sv)',
  chooseImage: 'Choose Image (sv)',
  choose: 'Choose (sv)',
  category: 'Category (sv)',
  productsYoureEditing: "Products you're editing (sv)",
  changeFieldsToUpdate: 'Change fields to update (sv)',
  updateAll: 'Update All (sv)',
  auctionCategory: 'Auction Category (sv)',
  productCategories: 'Product Categories (sv)',
  sku: 'SKU',
  maxLengthIsThree: 'Field contain max 3 characters (sv)',
  editAuction: 'Edit Auction (sv)',
  generatePdf: 'Generate PDF (sv)',
  markAsComplete: 'Mark as Complete (sv)',
  experts: 'Experts (sv)',
  completed: 'Completed (sv)',
  incomplete: 'Incomplete (sv)',
  canBeSentByAssignedPersona: 'Proposal can be sent by Assigned user only (sv)',
  sendProposal: 'Send proposal (sv)',
  publishForPrebid: 'Publish for Prebid (sv)',
  editUser: 'Edit User (sv)',
  from: 'From (sv)',
  to: 'To (sv)',
  images: 'Images (sv)',
  superAdmin: 'Super Admim (sv)',
  role: 'Role (sv)',
  client: 'Client (sv)',
  disable: 'Disable (sv)',
  enable: 'Enable (sv)',
  parent: 'Parent (sv)',
  newPasswordWasSentToUser: 'New password was sent to the user (sv)',
  changePassword: 'Change Password (sv)',
  profile: 'Profile (sv)',
  logOut: 'Log out (sv)',
  password: 'Password (sv)',
  confirmPassword: 'Confirm Password (sv)',
  passwordsDoesntMatch: "Passwords doesn't match (sv)",
  passwordWasUpdated: 'Password was updated (sv)',
  profileWasUpdated: 'Profile was updated (sv)',
  passwordShouldBemoreThen6Chars: 'Password should be more then 6 characters (sv)',
  wrongPassword: 'Wrong password (sv)',
  currentPassword: 'Current Password (sv)',
  mightBuyLabel: 'Includes products Holmasto might buy (sv)',
  soldOnBehalfLabel: 'Includes products sold on behalf of the customers (sv)',
  internalNote: 'Internal Note (sv)',
  startDatePreBid: 'Prebid Start (sv)',
  createTag: 'Create tag (sv)',
  endDatePreBid: 'Pre-Bid End Date (sv)',
  auctionIdShouldBeUnique: 'Auction id should be unique (sv)',
  more: 'More (sv)',
  less: 'Less (sv)',
  itemNumber: 'Item Number (sv)',
  dateTime: 'Datetime (sv)',
  user: 'User (sv)',
  userId: 'User Id (sv)',
  bid: 'Bid (sv)',
  bids: 'Bids (sv)',
  amount: 'Amount (sv)',
  addBid: 'Add Bid (sv)',
  minimumAmountIs: 'Minimum amount is (sv)',
  increase: 'KOROTA (sv)',
  timeToMakeAnOffer: 'Aikaa tehdä tarjous (sv)',
  nSeconds: '{{ n }} sek (sv)',
  timerLength: 'Tarjousajan pituus (sv)',
  startAgain: 'ALOITA UUDESTAAN (sv)',
  pause: 'PYSÄYTÄ (sv)',
  next: 'SEURAAVA (sv)',
  liveAuctionHasEndedAt: 'Live auction has ended at {{ date }} (sv)',
  paletteNumber: 'Palette number (sv)',
  fieldShouldBeUnique: 'This field should be unique (sv)',
  liveAuction: 'Live auction (sv)',
  shouldBeInteger: 'This field should be integer',
  attachUserOnBid: 'Attach user on bid (sv)',
  complete: 'Complete (sv)',
  bidding: 'Bidding (sv)',
  bidder: 'Bidder (sv)',
  nextItem: 'Seuraava kohde',
  itemNumberNofT: 'Kohdenumero {{ n }} / {{ t }}',
  raise: 'KOROTUS',
  minimumRiseN: 'Korotus (minimi {{ n }} €)',
  closeCurrentItem: 'MERKKAA JA SULJE KOHDE',
  orders: 'Orders (sv)',
  items: 'Items (sv)',
  phoneNumber: 'Phone Number (sv)',
  bidCount: 'Bid count (sv)',
  myWork: 'My work (sv)',
  clients: 'Clients (sv)',
  editClientN: 'Edit client {{ holmastoId }} (sv)',
  userDocuments: 'User Documents (sv)',
  note: 'Note (sv)',
  notes: 'Notes (sv)',
  inWarehouse: 'In warehouse (sv)',
  auctionStatus: 'Auction status (sv)',
  maxBiddingLevel: 'Max bidding level (sv)',
  copyProduct: 'Copy Product (sv)',
  productWasSaved: 'Product was saved (sv)',
  productWasCreated: 'Product was created (sv)',
  publishOnline: 'Publish Online (sv)',
  markAsFeatured: 'Mark as featured (sv)',
  itemsCountN: 'Items count: {{ n }} (sv)',
  bidsCountN: 'Bids count: {{ n }} (sv)',
  auctionN: 'Auction {{ n }} (sv)',
  itemsSoldN: 'Sold items: {{ n }} (sv)',
  trendingItems: 'Trending Items (sv)',
  trendingCategories: 'Trending Categories (sv)',
  price: 'Pris',
  unpublish: 'Unpublish (sv)',
  compensate: 'Compensate (sv)',
  undoCompensation: 'Undo Compensation (sv)',
  compensated: 'Compensated (sv)',
  yes: 'Ja',
  no: 'Nej',
  sum: 'Sum (sv)',
  commission: 'Commission (sv)',
  sellerReceives: 'Seller receives (sv)',
  sellerCommissions: 'Seller Commissions (sv)',
  sellerCommissionWasUpdated: 'Seller commission was updated (sv)',
  update: 'Uppdatera',
  auctionHasUnpublishedProducts: 'Auction has unpublished product, would you like to continue? (sv)',
  editSeller: 'Edit Seller',
  sellers: 'Sellers (sv)',
  accountNumber: 'Bank account number (sv)',
  countries: 'Countries (sv)',
  none: 'None (sv)',
  receptionWasSaved: 'Reception was saved (sv)',
  attachedCard: 'Attached Card',
  lastDigits: 'Last Digits',
  expirationDate: 'Expiration Date',
  accountType: 'Account Type (sv)',
  personal: 'Personal (sv)',
  business: 'Business (sv)',
  shippingOrderStatus: 'Shipping order status (sv)',
  manageOrder: 'Manage order (sv)',
  manageShippingOrder: 'Manage shipping order (sv)',
  verified: 'Is verified (sv)',
  serialNumber: 'Serial Number (sv)',
  zipCode: 'Zip code (sv)',
  liveAuctionStarted: 'Live auction started (sv)',
  liveAuctionEnded: 'Live auction ended (sv)',
  shippingIsSameAsBilling: 'Shipping address is same as billing address (sv)',
  shippingAddress: 'Shipping Address (sv)',
  billingAddress: 'Billing Address (sv)',
  nationality: 'Nationality (sv)',
  bidLimitRequests: 'Bid Limit requests (sv)',
  disabled: 'Disabled (sv)',
  editBid: 'Edit bid (sv)',
  notifyWhenBidIsWinning: 'Notify when bid is winning (sv)',
  notifyWhenBidIsntWinning: "Notify when bid isn't winning (sv)",
  notifications: 'Notifications (sv)',
  wrongCredentials: 'Wrong Credentials (sv)',
  generateExcel: 'Generate Excel (sv)',
  alreadyCompensated: 'Already compensated (sv)',
  sellerGets: 'Seller gets (sv)',
  invoice: 'Invoice (sv)',
  approvedReceptions: 'Approved Receptions (sv)',
  passwordConfirmation: 'Password confirmation (sv)',
  passwordShouldBeMinimum: 'Password should be kinimum {{ minimum }} (sv)',
  passwordsMustMatch: 'Passwords must match (sv)',
  userCantBeDeletedBecauseHeHasReceptions: "User can't be deleted because he/she has receptions (sv)",
  userCantBeDeletedBecauseHeHasBids: "User can't be deleted because he/she has bids (sv)",
  unknownError: 'Unknknown error (sv)',
  userWasDeleted: 'User was deleted (sv)',
  deleteUser: 'Delete user (sv)',
  areYouSureCantUndone: "Are you sure? Action can't be undone (sv)",
  isWinning: 'Is winning (sv)',
  userName: 'User Name (sv)',
  lotNumber: 'Lot number (sv)',
  marginalRate: 'Marginal rate (sv)',
  vatPercent: 'Vat percent (sv)',
  openOrders: 'Open orders (sv)',
  createWebshopOrder: 'Create webshop order (sv)',
  generateSoldProductExcel: 'Generate sold product excel (sv)',
  system: 'Ennakkotarjous (sv)',
  online: 'Online (sv)',
  facilitator: 'Sali (sv)',
  wonItems: 'Won items (sv)',
  wonPrice: 'Won price (sv)',
  receptions: 'Receptions (sv)',
  soldProducts: 'Sold products (sv)',
  paidWith: 'Paid with (sv)',
  shipment: 'Shipment (sv)',
  total: 'Total (sv)',
  view: 'View (sv)',
  open: 'Open (sv)',
  closed: 'Sluten',
  deadline: 'Deadline (sv)',
  openReceptionIAmPersonalAssigned: 'Open receptions where I am personnel assigned (sv)',
  openReceptionIAmExpert: 'Open receptions where I am expert (sv)',
  id: 'ID (sv)',
  daysBeforeDeadline: 'Days before deadline (sv)',
  openItemAcceptancesWhereIAmAssignedReception: 'Open item acceptances where I am personnel assigned in reception (sv)',
  openItemAcceptancesWhereIAmAssigned: 'Open item acceptances where I am personnel assigned (sv)',
  receptionId: 'Reception ID (sv)',
  receptionPersonell: 'Reception Personell (sv)',
  customerName: 'Customer name (sv)',
  sellerName: 'Seller name (sv)',
  bidsTotal: 'Bids total (sv)',
  uniqueCustomersWithBids: 'Unique customers with bids (sv)',
  itemsWithBids: 'Items with bids (sv)',
  sumOfWinningPreBids: 'Sum of winning pre bids (sv)',
  sumOfWinningLiveBids: 'Sum of winning live bids (sv)',
  itemsWithMostBids: 'Items with most bids (sv)',
  categoriesWithMostBids: 'Categories with most bids (sv)',
  kpi: 'KPI (sv)',
  auctionTotals: 'Auction totals (sv)',
  openReceptions: 'Open receptions (sv)',
  openItems: 'Open items (sv)',
  newCustomers: 'New customers (sv)',
  requestedAt: 'Requested at (sv)',
  payments: 'Payments (sv)',
  totalSum: 'Total sum (sv)',
  completedOrders: 'Completed orders (sv)',
  shippingMethod: 'Shipping Method (sv)',
  buyerPays: 'Buyer pays (sv)',
  orderItems: 'Order items (sv)',
  acceptedReceptionProposals: 'Accepted reception proposals (sv)',
  notCompensated: 'Not compensated (sv)',
  numberOfItems: 'Number of items (sv)',
  paymentStatus: 'Payment status (sv)',
  compensationStatus: 'Compensation status (sv)',
  fee: 'Fee (sv)',
  sellerItems: 'Seller Items (sv)',
  sellerSoldItems: 'Seller sold items (sv)',
  compensatedWithoutPurchaseInvoice: 'Compensated without purchase invoice (sv)',
  compensateSeller: 'Compensate Seller (sv)',
  createPurchaseInvoices: 'Create purchase invoice for selected (sv)',
  markPaidInStore: 'Mark paid in store (sv)',
  language: 'Language (sv)',
  generateContract: 'Generate contract (sv)',
  verificationMethod: 'Verification method (sv)',
  basic_info_filled: 'Basic info filled (sv)',
  copyProductToNewAuction: 'Copy product to new auction ((SV)',
  copyToAuction: 'Copy to auction ((SV)',
  totalCommission: 'Total Commission (sv)',
  boughtBy: 'Bought by (sv)',
  unsavedChangesPrompt: 'There are some unsaved changes',

  clientWasUpdated: 'Client was updated (sv)',
  clientWasCreated: 'Client was created (sv)',
  categoryWasUpdated: 'Category was updated (sv)',
  categoryWasCreated: 'Category was created (sv)',
  tagWasUpdated: 'Tag was updated (sv)',
  tagWasCreated: 'Tag was created (sv)',
  receptionWasUpdated: 'Reception was updated (sv)',
  receptionWasCreated: 'Reception was created (sv)',
  timerIsStillActive: 'Timer is still active (sv)',
  onlineCustomers: 'Online Customers (sv)',
  otherCustomers: 'Other Customers (sv)',
  evaluation: 'Värdering',
  toSell: 'Försäljning',
  estateInventory: 'Boupptäckning',
  insuranceEvaluation: 'Försäkringvärdering',
  gemmologicalExamination: 'Gemmologisk undersökning',
  purposeOfSale: 'Mottagningens syfte ',
  toAuction: 'Till auktion',
  salesOrder: 'Komissförsäljning',
  notifySeller: 'Notify seller (sv)',
  oneTimeCustomers: 'Engångskunder',
  paidInStore: 'Paid in store (sv)',
  before: 'Före',
  after: 'Efter',
  verifyUsers: 'Verify users (sv)',
  internalError: 'Internal Error (sv)',
  notificationsWhereScheduled: 'Email notifications where scheduled (sv)',
  proposal: 'Proposal',
  proposalPdf: 'Proposal PDF (sv)',
  printTheReceptionReceipt: 'Skriv ut reception kvitto',
  enableEdit: 'Enable edit (sv)',
  disableEdit: 'Ota pois käytöstä (sv)',
  approved: 'Approved (sv)',
  created: 'Påbörjad',
  readyToSend: 'Färdig att skickas',
  published: 'Publiserad',
  declined: 'Icke godkänd',
  auctionCountdown: 'Auction should start in {{ m }} Months, {{ d }} days, {{ h }} hours, {{ s }} seconds (sv)',
  notifyUsersToPay: 'Notify users to pay (sv)',
  sentWaitingForAnswer: 'Skickad, väntar på svar',
  liveAuctionWillStartIn: 'Live-auktionen startar om',
  other: 'Annan',
  pendingEmails: 'Ändringslogg för e-postadress',
  resendEmail: 'Skicka email igen',
  yourRequestHasCompleted: 'Skicka ett email:  Ditt uppdrag har slutförts',
  userWasAlreadyNotifiedNotifyAgain: 'Email was already send. Do you want to send again? (sv)',
  companyName: 'Företagsnamn',
  proposalSent: 'Erbjudande skickat',
  tools: 'Tools (fi)',
  notifiSellerReceptionReceived: 'Skicka ett email: Vi har tagit emot dina föremål för utvärdering',
  printTheQrCode: 'Skriv ut QR coden',
  directPurchase: 'Direktköp',
  notifyReceptionEvaluationHasCompleted: 'Skicka ett email: Värderingen är färdig!',
  printSalesReceipt: 'Skriv ut köpeavtal försäljningskontrakt',
  createPurchaseInvoice: 'Skapa inköpsfaktura',
  purchaseInvoiceWasCreated: 'Inköpsfaktura skapades',
  sendSalesContract: 'Skicka ett email: Försäljningskontrakt',
  printSalesContract: 'Skriv ut  försäljningskontrakt',
  notificationWasSent: 'Avisering skickades',
  auctionHasNoProducts: 'Vänligen lägg till produkter på auktionen innan du börjar live auktionen',
  updateFastTrackReception: 'Uppdatera snabbköpet',
  saveChanges: 'Spara ändringarna',
  unPublished: 'Opublicerad',
  markAsCompensated: 'Märk som betald i butiken',
  address: 'Adress',
  phone: 'Telefonnummer',
  fastTrackReceptionWasUpdated: 'Snabbköp updaterad',
  fastTrackReceptionWasCreated: 'Fast track reception was created (sv)',
  add: 'Add (sv)',
  history: 'History (sv)',
  cash: 'Kontant',
  netvisor: 'Netvisor',
  paymentMethod: 'Betalningsmetod',
  taxClass: 'Skatteklass',
  fastBuyItems: 'Handelsprodukter',
  productCantBePublished: "Productb {{ sku }} can't be published",
  setStatus: 'Uppdatera status för valda produkter',
  accountNumberIsRequired: 'In order to create purchase invoice fill account number',
  fastTrackReceptions: 'Snabbköp',
  addRow: 'Ny rad',
  receipts: 'Verifikat',
  addFastTrackReception: 'Fyll i nytt köp',
  socialSecurityNumber: 'Födelsedatum samt signum',
  form: 'Blankett',
  createSalesContract: 'Gör försäljningsavtal',
  runningNumber: 'Running number',
  productWasUnsold: 'Product was unsold (sv)',
  sellToUser: 'Sell to user (sv)',
  youReAboutToChangeAssignedAuctionId: "You're about to change assigned auction (sv)",
  youCantChangeSoldProductsAuction: "You can't change assigned auction for sold product (sv)",
  itemWasSoldToTheUser: 'Items as sold to user (sv)',
  printActions: 'Print actions',
  editProduct: 'Edit product',
  unlockForEditing: 'Unlock for editing',
  lockForEditing: 'Lock for editing',
  afterAuctionTools: 'After auction tools',
  unsell: 'Unsell',
  purchaseItemToHolmasto: 'Purchase item to holmasto',
  moveToAnotherAuction: 'Move to another auction',
  previewProductInCatalogue: 'Preview auction in catalogue',
  returnedToSeller: 'Returned to seller (sv)',
  sold: 'Sold (sv)',
  current: 'Current (sv)',
  waiting: 'Waiting (sv)',
  biddingEnded: 'Bidding ended (sv)',
  readyForCatalogue: 'Färdig för katalog',
  accepted: 'Godkänd',
  actions: 'Actions (sv)',
  soldToHolmasto: 'Sold to holmasto (sv)',
  movedFromEarlierAuction: 'Moved from earlier auction (sv)',
  itemCreated: 'Skapad',
  updated: 'Uppdaterad',
  grouped: 'Objekt',
  list: 'Senaste bud',
  type: 'Typ',
  verfictionStatus: 'Verifieringsstatus',
  disableFromCatalogue: 'Disable from catalogue (sv)',
  enableInCatalogue: 'Enable in catalogue (sv)',
  featuredImages: 'Featured Images (sv)',
  startPrice: 'Start price',
  filterItemDescription: 'Filter item description',
  copy: 'Copy',
  historyItems: 'History items (sv)',
  itemWasCompied: 'Item was copied',
  createWebshopProduct: 'Create webshop product',
  webshopProduct: 'Webshop product',
  editWebshopProduct: 'Edit webshop product ({{ lang }})',
  showFullTable: 'Show full table (sv)',
  tempId: 'Tillfälligt id',
  archived: 'Arkistoitu (sv)',
  archive: 'Archive',
  unarchive: 'Unarchive',
  proposalWasSent: 'Sales contract was sent',
})

export default sv

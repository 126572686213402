import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from 'Components/Home'
import { Redirect, useHistory } from 'react-router'
import SuperAdminGuard from './guards/superAdminGuard'
import Tag from 'Components/Tags'
import RrofileRoutes from './profile'
import { HistoryItems } from 'Components/HistoryItems'

const AuctionRoutes = React.lazy(() => import('./auction'))
const Products = React.lazy(() => import('Components/Products/Show'))
const ReceptionRoutes = React.lazy(() => import('./reception'))
const ProductRoutes = React.lazy(() => import('./products'))
const UsersRoutes = React.lazy(() => import('./users'))
const Category = React.lazy(() => import('../Components/Category'))
const ClientRoutes = React.lazy(() => import('./clients'))
const PaymentsRoutes = React.lazy(() => import('./payments'))
const FastTrackReceptionRoutes = React.lazy(() => import('./fastTrackReceptions'))

const DashboardRoutes = () => {
  return (
    <Switch>
      <React.Suspense fallback={<>Loading...</>}>
        <Route index exact path="/home">
          <Home />
        </Route>
        <Route index exact path="/history-items">
          <HistoryItems />
        </Route>
        <Route path="/reception">
          <ReceptionRoutes />
        </Route>
        <Route path="/product">
          <ProductRoutes />
        </Route>
        <Route exact path="/products">
          <Products />
        </Route>
        <Route path="/auctions">
          <AuctionRoutes />
        </Route>
        <Route path="/category">
          <Category />
        </Route>
        <Route path="/tag">
          <Tag />
        </Route>
        <SuperAdminGuard>
          <Route path="/users">
            <UsersRoutes />
          </Route>
        </SuperAdminGuard>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route exact path="/profile">
          <RrofileRoutes />
        </Route>
        <Route path="/clients">
          <ClientRoutes />
        </Route>
        <Route path="/payments">
          <PaymentsRoutes />
        </Route>
        <Route path="/fast-track-receptions">
          <FastTrackReceptionRoutes />
        </Route>
      </React.Suspense>
    </Switch>
  )
}

export default DashboardRoutes

export const useAppHistory = useHistory

import { Alert } from '@mui/material'
import { any, func } from 'prop-types'
import styled from 'styled-components'

export const Status = props => {
  return <StyledAlert {...props} />
}

export const GridStatus = ({ value, getColor, getLabel }) => {
  const label = (getLabel && getLabel(value)) || value
  if (!value) return null
  return <Status severity={getColor && getColor(value)}>{label}</Status>
}

GridStatus.propTypes = {
  value: any,
  getColor: func,
  getLabel: func,
}

Status.propTypes = Alert.propTypes

const StyledAlert = styled(Alert)`
  display: inline-block !important;
  padding: 0 1rem !important;
  border-radius: 18px !important;
`
StyledAlert.defaultProps = {
  icon: false,
}

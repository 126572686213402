import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useAppTranslation } from 'i18n/hooks'
import { bool, func, number, string } from 'prop-types'
import InputText from 'Components/Ui-Kits/Inputs/InputText'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { adminCreateBid } from 'cloudFunctions'
import { useEffect, useState } from 'react'
import Select from 'Components/Ui-Kits/Inputs/Select'
import { useAllProfiles } from 'data/profiles'
import { Margin } from 'Components/Ui-Kits/Margin'

const bidSchema = t =>
  Yup.object({
    amount: Yup.number().nullable().min(Yup.ref('$minAmount')).required(t('required')),
    userId: Yup.string().required(t('required')),
  })

export const AddBidModal = ({ onClose, auctionId, productId, open, minAmount }) => {
  const { t } = useAppTranslation()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(bidSchema(t), { abortEarly: false, context: { minAmount } }),
    context: { minAmount },
  })
  const [loading, setLoading] = useState(false)
  const { profiles } = useAllProfiles()

  const onConfirm = handleSubmit(async ({ amount, userId }) => {
    setLoading(true)
    await adminCreateBid({ auctionId, itemId: productId, amount, userId })
    setLoading(false)
    onClose()
  })

  useEffect(() => {
    reset({})
  }, [open, reset])

  return (
    <Dialog open={open} fullWidth={true}>
      <DialogTitle>{t('addBid')}</DialogTitle>
      <DialogContent>
        <Typography>
          {t('minimumAmountIs')} {minAmount}
        </Typography>
        <Select
          label={t('user')}
          {...register('userId')}
          helperText={errors.userId?.message}
          error={!!errors.userId}
          options={profiles.map(({ id, firstName, lastName, holmastoId }) => ({
            value: id,
            title: [holmastoId, [firstName, lastName].join(' ')].join(' - '),
          }))}
          hasSearch
          id="userId"
        />
        <Margin b={5} />
        <InputText
          type="number"
          {...register('amount')}
          helperText={errors.amount?.message}
          error={!!errors.amount}
          label={t('amount')}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus disabled={loading}>
          {t('cancel')}
        </Button>
        <Button onClick={onConfirm} color="success" disabled={loading}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddBidModal.propTypes = {
  onClose: func.isRequired,
  open: bool.isRequired,
  auctionId: string.isRequired,
  productId: string,
  minAmount: number,
}

import { ReceptionsTable } from 'Components/Shared/ReceptionsTable'
import { useOpenReceptions } from 'data/receptions'
import { useAppTranslation } from 'i18n/hooks'

export const OpenReceptions = () => {
  const [receptions = [], loading] = useOpenReceptions()
  const { t } = useAppTranslation()

  return <ReceptionsTable receptions={receptions} loading={loading} title={t('openReceptions')} />
}

import { Timestamp } from 'firebase/firestore'

/**
 * Converts Timestamps in object to Dates
 *
 * @param {object} obj
 * @returns {object}
 */
export const objectTimestampsToDate = obj => {
  // Clonning object not to mutate actual data
  const clonedObj = Array.isArray(obj) ? [...obj] : { ...obj }

  for (const key in clonedObj) {
    const val = clonedObj[key]

    if (val instanceof Timestamp) {
      clonedObj[key] = val.toDate()
      continue
    }

    if (val && typeof val === 'object') {
      clonedObj[key] = objectTimestampsToDate(val)
    }
  }

  return clonedObj
}

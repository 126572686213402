import React from 'react'
import PropTypes from 'prop-types'
import { TextBody13, TextBody16, TextBody20, TextHeading20, TextHeading34, TextHeading40 } from './Types'
import { Colors } from '../../../constants/colors'

export const TextTypes = Object.freeze({
  HEADING_40: 'heading_40',
  HEADING_34: 'heading_34',
  HEADING_20: 'heading_20',
  BODY_20: 'body_20',
  BODY_16: 'body_16',
  BODY_13: 'body_13',
})

const Text = ({ type, color, style, className, children, ...props }) => {
  switch (type) {
    case TextTypes.HEADING_40:
      return (
        <TextHeading40 style={style} className={className} color={color} {...props}>
          {children}
        </TextHeading40>
      )
    case TextTypes.HEADING_34:
      return (
        <TextHeading34 style={style} className={className} color={color} {...props}>
          {children}
        </TextHeading34>
      )
    case TextTypes.HEADING_20:
      return (
        <TextHeading20 style={style} className={className} color={color} {...props}>
          {children}
        </TextHeading20>
      )
    case TextTypes.BODY_20:
      return (
        <TextBody20 style={style} className={className} color={color} {...props}>
          {children}
        </TextBody20>
      )
    case TextTypes.BODY_16:
      return (
        <TextBody16 style={style} className={className} color={color} {...props}>
          {children}
        </TextBody16>
      )
    case TextTypes.BODY_13:
      return (
        <TextBody13 style={style} className={className} color={color} {...props}>
          {children}
        </TextBody13>
      )
    default:
      return (
        <TextBody16 style={style} className={className} color={color} {...props}>
          {children}
        </TextBody16>
      )
  }
}

export default Text

Text.propTypes = {
  children: PropTypes.any.isRequired,
  type: PropTypes.oneOf(Object.values(TextTypes)),
  color: PropTypes.oneOf(Object.values(Colors)),
  style: PropTypes.object,
  className: PropTypes.string,
}

Text.defaultProps = {
  type: TextTypes.TextBody16,
}

import { Route, Switch } from 'react-router-dom'
import { useRouteMatch } from 'react-router'
import { Profile } from 'Components/Users/Profile'

const RrofileRoutes = () => {
  let { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path}>
        <Profile />
      </Route>
    </Switch>
  )
}

export default RrofileRoutes

import { dateTimeFormat } from 'constants/index'
import dayjs from 'dayjs'

export const filterByDate = (val1, val2) => {
  const date1 = dayjs(val1, dateTimeFormat)
  const date2 = dayjs(val2, dateTimeFormat)

  if (!date1.isValid()) {
    return -1
  }
  if (!date2.isValid()) {
    return 1
  }

  return date1.diff(date2)
}

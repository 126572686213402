import { yupResolver } from '@hookform/resolvers/yup'
import { Grid } from '@mui/material'
import { Prompt } from 'Components/Shared/Prompt'
import Button from 'Components/Ui-Kits/Buttons'
import InputText from 'Components/Ui-Kits/Inputs/InputText'
import Select from 'Components/Ui-Kits/Inputs/Select'
import { Margin } from 'Components/Ui-Kits/Margin'
import { Taxonomies } from 'constants/categories'
import { Colors } from 'constants/colors'
import { userRoles, userRolesOptions, userStatus } from 'constants/users'
import { auth } from 'data/firebase'
import { TaxonomiesContext } from 'decorators/Taxonomies'
import { fetchSignInMethodsForEmail } from 'firebase/auth'
import { useAppTranslation } from 'i18n/hooks'
import { bool, func, oneOf, shape, string } from 'prop-types'
import { useCallback, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useAppHistory } from 'routes/dashboard'
import { extractCategory, makeSelectOptionDS } from 'utils/categoryExtractor'
import * as Yup from 'yup'

const userSchema = (t, isEdit) =>
  Yup.object().shape(
    {
      email: Yup.string()
        .nullable()
        .when({
          is: () => isEdit === false,
          then: schema =>
            schema
              .required(t('required'))
              .email(t('notValidEmail'))
              .test('unique', 'Email should be unique', async value => {
                if (!value) return false
                try {
                  const providerList = await fetchSignInMethodsForEmail(auth, value)
                  return !providerList.length
                } catch (error) {
                  return true
                }
              }),
        }),
      password: Yup.string()
        .nullable()
        .when({
          is: () => isEdit === false,
          then: schema => schema.required(t('required')),
        })
        .min(6, t('passwordShouldBeMinimum', { minimum: 6 })),
      firstName: Yup.string().required(t('required')),
      lastName: Yup.string().required(t('required')),
      postalCode: Yup.string().required(t('required')),
      city: Yup.string().required(t('required')),
      country: Yup.string().nullable().required(t('required')),
      role: Yup.string().nullable().required(t('required')),
    },
    ['isEdit']
  )

export const Form = ({ defaultValues, onSubmit, loading, sameUser = false }) => {
  const { t, i18n } = useAppTranslation()

  const taxonomies = useContext(TaxonomiesContext)
  const history = useAppHistory()

  const {
    handleSubmit,
    formState: { errors, isDirty },
    register,
    reset,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: defaultValues,
    resolver: yupResolver(userSchema(t, !!defaultValues)),
  })

  const registerInput = useCallback(
    name => {
      return {
        ...register(name),
        error: !!errors[name],
        helperText: errors?.[name]?.message,
        defaultValue: defaultValues?.[name],
        disabled: loading,
      }
    },
    [register, errors, defaultValues, loading]
  )

  const onSave = handleSubmit(data => {
    delete data.isEdit
    reset({ ...data })
    onSubmit(data)
  })

  return (
    <>
      <Prompt when={isDirty} />
      <Grid container spacing={2}>
        <Grid item xs={6} mt={2}>
          {!defaultValues && (
            <>
              <InputText label={t('email')} type="email" {...registerInput('email')} />
              <Margin t={5} />
              <InputText label={t('password')} type="password" {...registerInput('password')} />
            </>
          )}
          {!!defaultValues && <InputText label={t('email')} type="email" value={defaultValues.email} disabled />}
          <Margin t={5} />
          <InputText label={t('firstName')} {...registerInput('firstName')} />
          <Margin t={5} />
          <InputText label={t('lastName')} {...registerInput('lastName')} />
          <Margin t={5} />
          <InputText label={t('postalCode')} type={'number'} {...registerInput('postalCode')} />
          <Margin t={5} />
          <Select
            id="user_country"
            label={t('country')}
            options={makeSelectOptionDS(extractCategory(taxonomies, Taxonomies.COUNTRY), i18n.language).sort(
              ({ title: title1 }, { title: title2 }) => +(title1 >= title2) - (title1 <= title2)
            )}
            {...registerInput('country')}
          />
          <Margin t={5} />
          <InputText label={t('city')} {...registerInput('city')} />
          <Margin t={5} />
          <Select
            id="user_role"
            label={t('role')}
            options={userRolesOptions(t)}
            {...registerInput('role')}
            disabled={loading || sameUser}
          />
          <Margin t={5} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Margin t={10} />
        <Button onClick={onSave} disabled={loading} isFetching={loading}>
          {t('save')}
        </Button>
        <Margin display={'inline-block'} r={3} />
        <Button
          color={Colors.TRANSPARENT}
          textColor={Colors.DARK_GRAY}
          onClick={() => history.goBack()}
          disabled={loading}
        >
          {t('goBack')}
        </Button>
      </Grid>
    </>
  )
}

Form.propTypes = {
  defaultValues: shape({
    email: string,
    firstName: string,
    lastName: string,
    postalCode: string,
    city: string,
    country: string,
    status: oneOf(Object.values(userStatus)),
    role: oneOf(Object.values(userRoles)),
    emailNotificationAllowed: bool,
    smsNotificationAllowed: bool,
    marketingInformationAllowed: bool,
  }),
  onSubmit: func.isRequired,
  loading: bool,
  sameUser: bool,
}

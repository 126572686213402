import { functions } from 'data/firebase'
import { httpsCallable } from 'firebase/functions'

export const resetUserPassword = httpsCallable(functions, 'resetUserPassword')
export const updateProductStatuses = httpsCallable(functions, 'updateProductStatuses')
export const updateBid = httpsCallable(functions, 'callUpdateBid')
export const generateProductsPDF = httpsCallable(functions, 'callGenerateProductsPDF')
export const adminCreateBid = httpsCallable(functions, 'callAdminCreateBid')
export const startLiveAuction = httpsCallable(functions, 'callStartAuction')
export const createFacilitatorLiveBid = httpsCallable(functions, 'callCreateFacilitatorLiveBid')
export const completeCurrentLiveProduct = httpsCallable(functions, 'callCompleteCurrentLiveProduct')
export const setNextProductAsCurrentLiveProduct = httpsCallable(functions, 'callSetNextProductAsCurrentLiveProduct')
export const resetAuctionItem = httpsCallable(functions, 'callResetAuctionItem')
export const endLiveAuction = httpsCallable(functions, 'callEndLiveAuction')
export const attachUserOnPalette = httpsCallable(functions, 'callAttachUserOnPalette')
export const deleteWinningBid = httpsCallable(functions, 'callDeleteWinningBid')
export const attachUserToBid = httpsCallable(functions, 'callAttachUserToBid')
export const adminGetWebshopOrders = httpsCallable(functions, 'callAdminGetWebshopOrders')
export const adminAddUser = httpsCallable(functions, 'callAdminAddUser')
export const sortCategories = httpsCallable(functions, 'callSortCategories')
export const getShippingStatuses = httpsCallable(functions, 'callGetShippingStatuses')
export const disableAuctionProduct = httpsCallable(functions, 'callDisableAuctionProduct')
export const loginAdmin = httpsCallable(functions, 'callLoginAdmin')
export const adminCreatePurchaseInvoice = httpsCallable(functions, 'callAdminCreatePurchaseInvoice')
export const adminCreateReceptionPurchaseInvoice = httpsCallable(functions, 'callAdminCreateReceptionPurchaseInvoice')
export const adminChangeClientPassword = httpsCallable(functions, 'callAdminChangeClientPassword')
export const adminDeleteClient = httpsCallable(functions, 'callAdminDeleteClient')
export const getWebshopOrderPdf = httpsCallable(functions, 'getWebshopOrderPdf')
export const callAdminCreateWebshopOrder = httpsCallable(functions, 'callAdminCreateWebshopOrder')
export const adminGetSellerProductPurchaseInvoice = httpsCallable(functions, 'callAdminGetSellerProductPurchaseInvoice')
export const adminGetUserWebshopOrders = httpsCallable(functions, 'callAdminGetUserWebshopOrders')
export const adminGetSellerReceptionPurchaseInvoice = httpsCallable(
  functions,
  'callAdminGetSellerReceptionPurchaseInvoice'
)
export const syncAuctionOrders = httpsCallable(functions, 'callSyncAuctionOrders')
export const adminGenerateSellerProductsPdfForAuction = httpsCallable(
  functions,
  'callAdminGenerateSellerProductsPdfForAuction'
)
export const adminMarkSellerProductsAsCompensated = httpsCallable(functions, 'callAdminMarkSellerProductsAsCompensated')
export const adminGenerateSellerContract = httpsCallable(functions, 'callAdminGenerateSellerContract')
export const adminCreateWonItemsHandler = httpsCallable(functions, 'callAdminCreateWonItemsHandler')
export const adminNotifyReceptionWasAdded = httpsCallable(functions, 'callAdminNotifyReceptionWasAdded')
export const generateFinishedAuctionPdf = httpsCallable(functions, 'callGenerateFinishedAuctionPdf')
export const adminMarkReceptionAsCompensated = httpsCallable(functions, 'callAdminMarkReceptionAsCompensated')
export const adminNotifyAuctionPuublished = httpsCallable(functions, 'callAdminNotifyAuctionPuublished')
export const adminGetReceptionProposalFile = httpsCallable(functions, 'callAdminGetReceptionProposalFile')
export const adminGetReceptionReceivedFile = httpsCallable(functions, 'callAdminGetReceptionReceivedFile')
export const adminNorifyUserPandingOrders = httpsCallable(functions, 'callAdminNorifyUserPandingOrders')
export const adminUpdateUserEmail = httpsCallable(functions, 'callAdminUpdateUserEmail')
export const adminUpdateResendUserEmail = httpsCallable(functions, 'callAdminUpdateResendUserEmail')

export const adminSendReceptionEvaluationFinished = httpsCallable(functions, 'callAdminSendReceptionEvaluationFinished')

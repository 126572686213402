import { auctionStatuses } from 'constants/publishedProduct'
import { collection, getDocs, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { db } from './firebase'

export function usePublishedProducts(auctionId) {
  // Store product data to local state
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true)

  // Sync product data
  useEffect(() => {
    setLoading(true)
    const q = query(collection(db, `auctions/${auctionId}/products`), orderBy('sort', 'asc'))

    // Subscribe to data from /products
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })

        // Set products data to local state in this hook.
        setProducts(data)
        setLoading(false)
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [auctionId])

  // These functions mutate data in Firestore.

  return {
    // Products data from Firebase
    products,
    loading,
  }
}

export function usePublishedProductsOnce(auctionId) {
  // Store product data to local state
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(true)

  // Sync product data
  useEffect(() => {
    setLoading(true)
    const q = query(collection(db, `auctions/${auctionId}/products`), orderBy('sort', 'asc'))

    getDocs(q)
      .then(querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => {
          data.push({ ...doc.data(), id: doc.id })
        })

        // Set products data to local state in this hook.
        setProducts(data)
        setLoading(false)
      })
      .catch(error => {
        console.error('Failed to sync Product data', error)
      })
      .finally(() => setLoading(false))
  }, [auctionId])

  // These functions mutate data in Firestore.

  return {
    // Products data from Firebase
    products,
    loading,
  }
}

export function useCurrentPublishedProduct(auctionId) {
  // Store product data to local state
  const [product, setProduct] = useState(null)
  const [loading, setLoading] = useState(true)

  // Sync product data
  useEffect(() => {
    setLoading(true)
    const q = query(
      collection(db, `auctions/${auctionId}/products`),
      where('auctionStatus', '==', auctionStatuses.CURRENT),
      orderBy('sort', 'asc'),
      limit(1)
    )

    // Subscribe to data from /products
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        setLoading(false)
        const [doc] = querySnapshot.docs

        if (!doc?.exists()) return setProduct(null)

        // Set products data to local state in this hook.
        setProduct({ ...doc.data(), id: doc.id })
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [auctionId])

  // These functions mutate data in Firestore.

  return {
    // Products data from Firebase
    loading,
    product,
  }
}

export function usePreviousPublishedProduct(auctionId) {
  // Store product data to local state
  const [product, setProduct] = useState(null)
  const [loading, setLoading] = useState(true)

  // Sync product data
  useEffect(() => {
    setLoading(true)
    const q = query(
      collection(db, `auctions/${auctionId}/products`),
      where('auctionStatus', 'in', [auctionStatuses.NOT_SOLD, auctionStatuses.SOLD, auctionStatuses.BIDDING_ENDED]),
      orderBy('sort', 'desc'),
      limit(1)
    )

    // Subscribe to data from /products
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        setLoading(false)
        const [doc] = querySnapshot.docs

        if (!doc?.exists()) return setProduct(null)

        // Set products data to local state in this hook.
        setProduct({ ...doc.data(), id: doc.id })
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [auctionId])

  // These functions mutate data in Firestore.

  return {
    // Products data from Firebase
    loading,
    product,
  }
}

export function useLastPublishedProduct(auctionId) {
  // Store product data to local state
  const [product, setProduct] = useState(null)
  const [loading, setLoading] = useState(true)

  // Sync product data
  useEffect(() => {
    setLoading(true)
    const q = query(collection(db, `auctions/${auctionId}/products`), orderBy('sort', 'desc'), limit(1))

    // Subscribe to data from /products
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        setLoading(false)
        const [doc] = querySnapshot.docs

        if (!doc?.exists()) return setProduct(null)

        // Set products data to local state in this hook.
        setProduct({ ...doc.data(), id: doc.id })
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [auctionId])

  // These functions mutate data in Firestore.

  return {
    // Products data from Firebase
    loading,
    product,
  }
}

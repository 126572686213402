import { useAppTranslation } from 'i18n/hooks'
import { bool } from 'prop-types'
import { useEffect } from 'react'
import { Prompt as RouterPrompt } from 'react-router'

export const Prompt = ({ when }) => {
  const { t } = useAppTranslation()

  useEffect(() => {
    const handler = e => {
      if (!when) return
      e.preventDefault()
      e.returnValue = 'unsaved changws'
    }

    window.addEventListener('beforeunload', handler)

    return () => window.removeEventListener('beforeunload', handler)
  }, [when])

  return <RouterPrompt message={t('unsavedChangesPrompt')} when={when} />
}

Prompt.propTypes = {
  when: bool.isRequired,
}

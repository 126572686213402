import { any } from 'prop-types'
import { useState, useEffect, createContext, useMemo, useContext } from 'react'
import { useLocation } from 'react-router'

const IsBackUrlContext = createContext(false)

export const IsBackUrl = ({ children }) => {
  const location = useLocation()
  const [locationObject, setLocationObject] = useState({
    prev: undefined,
    check: undefined,
  })

  useEffect(() => {
    return () => {
      setLocationObject(obj => ({
        check: obj.prev,
        prev: location.pathname,
      }))
    }
  }, [location.pathname])

  const isBackUrl = useMemo(() => location.pathname === locationObject.check, [location.pathname, locationObject.check])

  return <IsBackUrlContext.Provider value={isBackUrl}>{children}</IsBackUrlContext.Provider>
}

IsBackUrl.propTypes = {
  children: any,
}

export const useIsBackUrl = () => useContext(IsBackUrlContext)

import { useState, useEffect, useRef, useCallback } from 'react'
import { collection, onSnapshot, query, addDoc, serverTimestamp, doc, orderBy, getDoc } from 'firebase/firestore'
import { useAuth } from './auth'
import { auth, db } from './firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import { getDifferentKeys } from 'utils/getDifferentKeys'
import { objectTimestampsToDate } from 'utils/objectTimestampsToDate'
import { pick } from 'lodash'
import { changelogTypes } from 'constants/changelogs'
/**
 * Get product with id. Also the data keeps updating if it changes in Firestore.
 *
 * TODO: Add loading and error properties to the return value.
 *
 * @arg {object} contains changelogId
 * @returns {object} Containing changelog data
 */
export function useChangelogs(arg = {}) {
  const { changelogId } = arg
  // Store changelogs data to local state
  const [changelogs, setChangelogs] = useState([])
  const { auth } = useAuth()
  const [user] = useAuthState(auth)

  // Sync changelog data
  useEffect(() => {
    if (!changelogId) return
    const q = query(
      // Reference (or path) to the data in Firestore.
      collection(doc(db, 'changelogs', changelogId), 'logs'),
      orderBy('createdAt', 'desc')
    )

    // Subscribe to data from /changelogs
    const unsubscribe = onSnapshot(
      // Refetence (or path) to the data in Firestore.
      q,
      // This callback is called when data is initially fetched and when it changes.
      querySnapshot => {
        const data = []
        querySnapshot.forEach(doc => data.push(doc.data()))
        // Set receptions data to local state in this hook.
        setChangelogs(data)
      },
      error => {
        console.error('Failed to sync Product data', error)
      }
    )

    return () => {
      // Unsubscribe to change callbacks when the hook unmounts
      unsubscribe()
    }
  }, [changelogId])

  // These functions mutate data in Firestore.
  const mutationsRef = useRef(
    {
      addChangelog: (actionType, referenceId, data) => {
        return addDoc(collection(db, 'changelogs', referenceId, 'logs'), {
          createdAt: serverTimestamp(),
          actionType: actionType,
          snapShot: data,
          user: { email: user?.email, uid: user?.uid },
        })
      },
    },
    []
  )

  return {
    // changelogs data from Firebase
    changelogs,
    // Spread Firestore mutation functions.
    ...mutationsRef.current,
  }
}

export const useAddChangelogs = () => {
  const [user] = useAuthState(auth)

  return useCallback(
    async (doc, actionType, callback) => {
      const oldData =
        actionType !== changelogTypes.CREATE ? objectTimestampsToDate((await getDoc(doc))?.data() || {}) : {}

      const returnValue = callback && (await callback())
      const newData = objectTimestampsToDate((await getDoc(doc))?.data() || {})
      const differentKeys = getDifferentKeys(oldData, newData)

      if (!differentKeys.length) return returnValue

      await addDoc(collection(db, 'changelogs', doc.id, 'logs'), {
        createdAt: serverTimestamp(),
        actionType: actionType,
        before: pick(oldData, ...differentKeys),
        after: pick(newData, ...differentKeys),
        user: { email: user?.email, uid: user?.uid },
      })

      return returnValue
    },
    [user]
  )
}

import { Table as MuiTable, TableCell, TableHead, TableRow } from '@mui/material'
import { useAppTranslation } from 'i18n/hooks'
import { array, string } from 'prop-types'
import { useMemo, useState } from 'react'
import { getMinimumRise } from 'utils/getMinimumRise'
import { AddBidModal } from './AddBidModal'
import { Row } from './Row'

export const Table = ({ auction, products, profiles }) => {
  const {
    t,
    i18n: { language: lang },
  } = useAppTranslation()

  const [itemId, setItemId] = useState(null)
  const clearItem = () => setItemId(null)
  const minAmount = useMemo(() => {
    if (!itemId) return null

    const product = products.find(({ id }) => id === itemId)

    if (!product?.secondHighestBid) {
      return product?.technicalDetails.AuctionMinimumPrice || null
    }

    return product?.secondHighestBid + getMinimumRise(product?.secondHighestBid)
  }, [itemId, products])

  return (
    <>
      <AddBidModal auctionId={auction} productId={itemId} open={!!itemId} onClose={clearItem} minAmount={minAmount} />
      <MuiTable size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('itemNumber')}</TableCell>
            <TableCell>{t('description')}</TableCell>
            <TableCell>{t('bidCount')}</TableCell>
            <TableCell>{t('dateTime')}</TableCell>
            <TableCell>{t('user')}</TableCell>
            <TableCell>{t('userId')}</TableCell>
            <TableCell>{t('bid')}</TableCell>
            <TableCell>{t('type')}</TableCell>
            <TableCell colSpan={2}>{t('edit')}</TableCell>
          </TableRow>
          {products.map(({ id, sort, name, bids }) => (
            <Row
              key={id}
              sort={sort}
              name={name?.[lang] || name?.en}
              auctionId={auction}
              id={id}
              setItemId={setItemId}
              allBids={bids}
              profiles={profiles}
            />
          ))}
        </TableHead>
      </MuiTable>
    </>
  )
}

Table.propTypes = {
  auction: string,
  products: array,
  profiles: array,
}

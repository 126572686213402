import { Link } from '@mui/material'
import { any, string } from 'prop-types'
import { useMemo } from 'react'
import { get } from 'lodash'

export const AgLink = ({ value, data, to }) => {
  const href = useMemo(
    () =>
      (to.match(/\{[^{]+\}/g) || [])
        .filter((item, index, list) => list.indexOf(item) === index)
        .reduce((href, item) => {
          const key = item.replace(/[{|}]/g, '')
          const value = get(data, key)
          if (value === undefined) return href

          return href.replace(new RegExp(item, 'g'), value)
        }, to),
    [data, to]
  )
  return (
    <Link href={href} target="_blank">
      {value}
    </Link>
  )
}

AgLink.propTypes = {
  value: any,
  data: any,
  to: string,
}

export const IS_PRODUCTION = process.env.NODE_ENV === 'production'
export const BASE_URL = IS_PRODUCTION ? 'https://holmasto-auction.web.app/' : 'http://localhost:3000/'
export const FRONTEND_BASE_URL = process.env.REACT_APP_FRONTEND_BASE_URL || 'http://localhost:3001/'
export const WEBSHOP_BASE_URL = process.env.REACT_APP_WEBSHOP_BASE_URL || 'https://holmasto.ddev.site'
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID || 'holmasto-auction'
export const FUNCTIONS_API_BASE_URL =
  process.env.REACT_APP_FUNCTIONS_API_BASE_URL || `http://127.0.0.1:5001/${FIREBASE_PROJECT_ID}/europe-west3/adminApi`
export const STORAGE_BASE_URL =
  process.env.REACT_APP_STORAGE_BASE_URL || `http://localhost:9199/${FIREBASE_PROJECT_ID}.appspot.com`
export const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE
export const SENTRY_DSN = process.env.REACT_APP_ADMIN_PANEL_SENTRY_DSN
export const ENV = process.env.REACT_APP_ENV

export const minimumRises = Object.freeze([
  { range: [0, 50], rise: 5 },
  { range: [50, 200], rise: 10 },
  { range: [200, 400], rise: 20 },
  { range: [400, 1000], rise: 50 },
  { range: [1000, 2000], rise: 100 },
  { range: [2000, 4000], rise: 200 },
  { range: [4000, 10000], rise: 500 },
  { range: [10000, 20000], rise: 1000 },
  { range: [20000, 50000], rise: 2000 },
  { range: [50000, 500000], rise: 5000 },
  { range: [500000], rise: 10000 },
])
export const dateTimeFormat = 'DD.MM.YYYY HH:mm'
export const DEFAULT_CLIENT_COMMISSION = 29
export const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID
export const ALGOLIA_APP_KEY = process.env.REACT_APP_ALGOLIA_APP_KEY

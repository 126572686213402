export const Colors = Object.freeze({
  MAROON: '#80032D',
  MAROON_LIGHT: '#B26781',
  DIM_GRAY: '#5D5050',
  LIGHT_GRAY: '#e0e0e0',
  LIME_GREEN: '#1CD847',
  GOLD: '#FFC700',
  CRIMSON: '#F0142F',
  TRANSPARENT_CRIMSON: 'rgba(240, 20, 47, 0.2)',
  DARK_GRAY: '#AAAAAA',
  WhiteSmoke: '#F2F2F2',
  WHITE: '#FFFFFF',
  THAMAR_BLACK: '#191919',
  DEEP_FOREST_BROWN: '#333031',
  MATT_BLACK: '#151515',
  BLACK: '#000000',
  BG_GRAY: '#d5d5d5',
  TRANSPARENT: 'transparent',
})
